import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private httpClient: HttpClient) { }

  getHistory(tournamentId: string, entityType: number, since: Date): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.api}/tournaments/${tournamentId}/histories/${entityType}?since=${since.toISOString()}`);
  }
}
