<app-container>
    <app-header-delete [isDeleteButtonHidden]="!this.personId" (confirm)="this.onDelete()">
        {{this.personId ? person?.firstname + ' ' + person?.lastname : 'Create Person'}}
    </app-header-delete>

    <app-tab-navigation>
        <app-tab-item #detailsTab isActive=true>
            Person details
        </app-tab-item>
        <app-tab-item #teamsTab>
            Disciplines and Partners
        </app-tab-item>
        <app-tab-item #badgesTab>
            Badges
        </app-tab-item>
        <app-tab-item #hotelsTab>
            Hotel Rooms
        </app-tab-item>
        <app-tab-item #transportsTab>
            Transports
        </app-tab-item>
        <app-tab-item #visasTab>
            Visas
        </app-tab-item>
    </app-tab-navigation>

    <ng-container *ngIf="visasTab.isActive">
        <app-person-visa-list></app-person-visa-list>
    </ng-container>
    <ng-container #personEdit *ngIf="detailsTab.isActive">
        <app-person-edit></app-person-edit>
    </ng-container>
    <ng-container *ngIf="badgesTab.isActive">
        <app-person-badge-list></app-person-badge-list>
    </ng-container>
    <ng-container *ngIf="hotelsTab.isActive">
        <app-person-hotel-assignment-list></app-person-hotel-assignment-list>
    </ng-container>
    <ng-container *ngIf="transportsTab.isActive">
        <app-person-transport-list></app-person-transport-list>
    </ng-container>
    <ng-container *ngIf="teamsTab.isActive">
        <app-person-team-list></app-person-team-list>
    </ng-container>
</app-container>