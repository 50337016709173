import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonService } from '@person/services/person.service';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';
import { NotificationService } from '@shared/services/notification.service';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit {

  personId = null;
  person: any;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private personService: PersonService,
    private notificationService: NotificationService,
  ) {
    this.personId = this.route.snapshot.params.personId;
  }

  async ngOnInit(): Promise<void> {
    this.person = await this.personService.getOne(this.personId).toPromise();
  }

  onDelete(): void {
    this.personService.delete(this.personId).subscribe(
      () => {
        this.location.back();
      }, (error) => {
        this.notificationService.error(ErrorObjectHelper.getMessageFromResponseObject(error));
      }
    );
  }
}
