import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelRoomAssignmentService } from '@hotel/services/hotel-room-assignment.service';
import { GenderHelper } from '@person/components/helpers/gender.helper';
import { RoomTypeHelper } from '@hotel/helper/room-type.helper';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { PersonTournamentFieldsService } from '@person/services/person-tournament-fields.service';
import { NotificationService } from '@shared/services/notification.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { RoleIdentifier } from '@person/enums/role.identifier.enum';

@Component({
  selector: 'app-hotel-room-assignment-list',
  templateUrl: './hotel-room-assignment-list.component.html',
  styleUrls: ['./hotel-room-assignment-list.component.scss']
})

export class HotelRoomAssignmentListComponent implements OnInit {

  @ViewChild("grid") grid: GridComponent;

  data = [];
  formatSettings = { type: 'date', format: 'dd.MM.yyyy' };
  tournamentId: string;
  selectedUsersForPaidHotel: Array<string> = [];
  selectedUsersForNotPaidHotel: Array<string> = [];

  constructor(
    private hotelRoomAssignmentService: HotelRoomAssignmentService,
    private personTournamentFieldsService: PersonTournamentFieldsService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService) { }

  async ngOnInit(): Promise<void> {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;

    let res = await this.hotelRoomAssignmentService.get({
      join: ['person', 'person.organization', 'person.tournamentFields', 'person.tournamentRoles'],
      filter: [`tournamentId||$eq||${this.tournamentId}`],
      sort: 'person.lastname,ASC'
    }).toPromise();

    res = res.map(roomBooking => {
      roomBooking.person['genderAbbreviation'] = GenderHelper.getGenderAbbreviation(roomBooking.person.gender);
      roomBooking.person['birthdate'] = new Date(roomBooking.person['birthdate']);
      roomBooking.person['isUnregistered'] = roomBooking.person.tournamentRoles.some(role => role.roleId === RoleIdentifier.UNREGISTERED);
      roomBooking.hotelRoomBooking['bedroomSize'] = RoomTypeHelper.getRoomSizeByType(roomBooking.hotelRoomBooking['hotelRoomType']);
      roomBooking.startDate = new Date(roomBooking.startDate);
      roomBooking.endDate = new Date(roomBooking.endDate);
      roomBooking.tournamentField = roomBooking.person['tournamentFields']?.filter(field => field.tournamentId === roomBooking.tournamentId)[0];
      if (roomBooking.hasPaidHotel) {
        this.selectedUsersForPaidHotel.push(roomBooking.person['id']);
      }
      return roomBooking;
    })
    this.data = res;
  }

  ngAfterViewInit(): void {
    this.grid.toolbar.unshift({
      id: 'saveHasPaid',
      text: 'Save has paid hotels',
      tooltipText: 'Saves all the selected "has paid" hotel rooms for each person',
      prefixIcon: 'e-save'
    });
  }

  async onToolbarClick(args: ClickEventArgs): Promise<void> {
    if (args.item.id === 'saveHasPaid') {

      this.selectedUsersForPaidHotel.forEach(async personId => {
        const booking = this.data.find(booking => booking.person.id === personId);
        if (!booking.tournamentField) {
          booking.person.tournamentField = await this.personTournamentFieldsService.create({ tournamentId: booking.tournamentId, personId: booking.personId, isHotelInvoicePaid: true }).toPromise();

        } else if (!booking.tournamentField.isHotelInvoicePaid) {
          let updateTournamentField = booking.person.tournamentFields.find(tournamentField => tournamentField.id === booking.tournamentField.id)
          updateTournamentField.isHotelInvoicePaid = true;
          await this.personTournamentFieldsService.update(updateTournamentField, updateTournamentField.id).toPromise();
        }
      });

      this.selectedUsersForNotPaidHotel.forEach(async personId => {
        const booking = this.data.find(booking => booking.person.id === personId);

        if (booking.tournamentField && booking.tournamentField.isHotelInvoicePaid) {
          booking.tournamentField.isHotelInvoicePaid = false;
          await this.personTournamentFieldsService.update(booking.tournamentField, booking.tournamentField.id).toPromise();
        }
      });

      this.notificationService.success('Saved paid booking(s)');
    }
  }

  onRowSelected(row): void {
    this.router.navigate(['hotel_room_bookings/edit', row.data.hotelRoomBooking.id], { relativeTo: this.route.parent });
  }

  onRowDeleted(data: any): void {
    this.hotelRoomAssignmentService.delete(data.id)
      .subscribe((res) => {
        this.data = this.data.filter(x => x.id !== data.id);
      });
  }

  addToSelectedUsersPaidHotelList($event, personId) {
    $event.stopPropagation();
    if (!this.selectedUsersForPaidHotel.includes(personId)) {
      this.selectedUsersForPaidHotel.push(personId);
      this.selectedUsersForNotPaidHotel.splice(this.selectedUsersForNotPaidHotel.indexOf(personId), 1)
    } else {
      this.selectedUsersForPaidHotel.splice(this.selectedUsersForPaidHotel.indexOf(personId), 1)
      this.selectedUsersForNotPaidHotel.push(personId);
    }
  }

}
