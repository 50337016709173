<app-container [hasFixedWidth]="false">
  <div class="mt-2 md:flex md:items-center md:justify-between">
    <div class="flex-1 min-w-0">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
        Hotel room reservations
      </h2>
    </div>
  </div>

  <br>
  <app-alert [type]="'info'">
    You can create reservations by clicking on an existing hotel booking in the hotel booking overview.
  </app-alert>

  <ng-container *ngIf="this.data">
    <ejs-grid #grid defaultGridSettings [dataSource]='data' id="hotelAssigmentGrid"
      (rowSelected)="onRowSelected($event)" (toolbarClick)='onToolbarClick($event)'>
      <e-columns>
        <e-column headerText='Photo' width="100">
          <ng-template #template let-data>
            <div class="flex items-center">
              <img class="h-10 w-10 rounded-full" [src]="data.person.avatarUrl" alt="Photo">
            </div>
          </ng-template>
        </e-column>
        <e-column field='person.humanFriendlyId' headerText="ID" type="number" width="100"></e-column>
        <e-column headerText='Has Paid' [showInColumnChooser]='false' textAlign='Center' width="100">
          <ng-template #template let-data>
            <a class="btn mr-3" 
            [class.btn-primary-outline]="!this.selectedUsersForPaidHotel.includes(data.person.id)"
            [class.btn-primary]="this.selectedUsersForPaidHotel.includes(data.person.id)"
              (click)="this.addToSelectedUsersPaidHotelList($event, data.person.id)">Has Paid</a>
          </ng-template>
        </e-column>
        <e-column field='person.bwfId' headerText="BWF ID" type="string" width="100"></e-column>
        <e-column field='person.firstname' headerText="Firstname" type="string" width="110"></e-column>
        <e-column field='person.lastname' headerText="Lastname" type="string" width="110"></e-column>
        <e-column field='person.birthdate' headerText="Birthdate" type="date" [format]="formatSettings" width="110"></e-column>
        <e-column field='person.genderAbbreviation' headerText="Gender" type="string" width="110" width="110"></e-column>
        <e-column field='person.organization.name' headerText="Organization" type="string" width="110"></e-column>
        <e-column field='person.countryCode' headerText="Country" type="string" width="110"></e-column>
        <e-column field='person.isUnregistered' [visible]="false" headerText="Unregistered" type="boolean" width="110"></e-column>
        <e-column field='startDate' headerText='Check In' type="date" [format]="formatSettings" width="110"></e-column>
        <e-column field='endDate' headerText="Check Out" type="date" [format]="formatSettings" width="110"></e-column>
        <e-column field='hotelRoomBooking.bedroomSize' headerText="Room size" type="number" width="110"></e-column>
        <e-column field='hotelRoomBooking.hotelRoomType' headerText="Room type" type="string" width="110"></e-column>
        <e-column field='hotelRoomBooking.roomIdentifier' headerText="Booking" type="string" width="110"></e-column>
        <e-column headerText='deleted' [showInColumnChooser]='false' textAlign='Right' width="100">
          <ng-template #template let-data>
            <app-button-delete (confirm)="this.onRowDeleted(data)"></app-button-delete>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </ng-container>
  <app-container>