<app-container>
    <div class="mt-2 md:flex md:items-center md:justify-between">
      <div class="flex-1 min-w-0">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
          Badge Scanner
        </h2>
      </div>
  </div>
  <br/>

  <form [formGroup]="form">
    <span class="text-gray-500 font-bold">Select the AccessTypes you want to check for</span>

    <ul class="mt-3 grid grid-cols-4">
      <li *ngFor="let accessType of this.accessTypes" class="mt-3 mb-3 col-span-1">
        <div class="flex flex-inline mt-2">
          <p class="">
            <input id="access-type-{{accessType.id}}" type="checkbox" [value]="accessType.id" (change)="onCheckboxChange($event)" class="mr-4 form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
          </p>
          <div class="">
            <p class="text-sm text-gray-900">
              <label for="access-type-{{accessType.id}}" class="font-bold text-gray-700">{{ accessType.name }}</label>
            </p>
            <p class="mt-1 mb-2 flex items-center text-sm text-gray-500">
              {{accessType.code}}
            </p>
          </div>
        </div>
      </li>
    </ul>
  </form>
  <br/><br/>

  <button (click)="enableScanner()" *ngIf="!scannerEnabled" type="button" class="btn btn-primary p-5 mb-4">
    <span class="text-xl px-5 py-2"><i class="fa fa-camera"></i> Scan Badge</span>
  </button>

  <zxing-scanner *ngIf="scannerEnabled" #scanner (scanSuccess)="scanSuccessHandler($event)"></zxing-scanner>

  <ng-container *ngIf="this.badge">
    <div class="p-10 lg:w-12/12 mx-auto bg-white border-1 rounded-xl shadow">
      <div class="w-2/12 mb-4">
        <button class="btn hover:bg-gray-200" (click)="close()"><i class="fa fa-times"></i></button>
      </div>

      <div class="mb-10">
        <div class="rounded-md bg-{{this.allowedColor()}}-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <i class="fa fa-{{ this.allowed() ? 'check' : 'times' }}-circle text-{{this.allowedColor()}}-400"></i>
            </div>
            <div class="ml-3">
              <h3 class="text-l font-medium text-{{this.allowedColor()}}-800">
                <b>{{ this.allowed() ? 'Allowed!' : 'Not allowed!' }}</b>
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div class="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
        <div style="max-width: 200px" class="aspect-w-3 aspect-h-2">
          <img  class="object-cover shadow-lg rounded-lg" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80" alt="">
        </div>
        <div class="sm:col-span-2">
          <div class="space-y-4">
            <div class="text-lg leading-6 font-medium space-y-1">
              <h3 class="flex">
                <img class="mr-3 h-6" src="https://flagcdn.com/40x30/{{badge.person.countryCode.toLowerCase()}}.png" alt="{{badge.person.countryCode}}">
                <span>{{ badge.person.firstname }} {{ badge.person.lastname }}</span>
              </h3>
              <p class="text-indigo-600">Player</p>
            </div>
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
              </div>
              <div class="ml-3 text-base text-gray-500">
                <p>{{badge.person.phone}}</p>
              </div>
            </div>
            <div class="mt-6 flex">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
              </div>
              <div class="ml-3 text-base text-gray-500">
                <p>{{badge.person.email}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-10 w-full">
        <h4 class="font-bold mt-5">Access</h4>

        <ul class="mt-3 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <li *ngFor="let accessType of this.accessTypes" class="mt-3 mb-3 col-span-1">
            <div class="flex flex-inline mt-2">
              <p class="">
                <span *ngIf="checkAccessTypeInBadge(accessType)">
                  <i class="mr-1.5 fa fa-check-circle text-green-400 h-5 w-5"></i>
                </span>

                <span *ngIf="!checkAccessTypeInBadge(accessType)">
                  <i class="mr-1.5 fa fa-times-circle text-red-400 h-5 w-5"></i>
                </span>
              </p>
              <div class="">
                <p class="text-sm text-gray-900">
                  <b>{{accessType.name}}</b>
                </p>
                <p class="mt-1 mb-2 flex items-center text-sm text-gray-500">
                  {{accessType.code}}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>
  </ng-container>




</app-container>
