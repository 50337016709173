import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '@auth/services/auth.service';
import { environment } from 'environments/environment';
import { catchError, finalize, switchMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    // Current refresh token request for all requests to wait for
    inflightAuthRequest: Observable<HttpEvent<any>> = null;
    authWhiteList = [];
    skipAuthRedirect = [];

    constructor(private authService: AuthService) {
        this.authWhiteList = environment.authWhiteList.map(x => `${environment.authApi}${x}`);
        this.skipAuthRedirect = environment.skipAuthRedirect.map(x => `${environment.authApi}${x}`);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Do nothing, if it is not our API
        if (!request.url.includes(environment.api)) {
            return next.handle(request);
        }

        // Exempt some paths from authentication
        if (this.authWhiteList.includes(request.url)) {
            return next.handle(request);
        }

        // Do nothing, if the user is not logged in
        if (!this.authService.isLoggedIn) {
            return this.handleRequest(request, next);
        }

        // Check if access token has not yet expired
        const now = (new Date()).getTime();
        if (this.authService.accessTokenExpiresAt > now + 30) {
            const requestWithToken = this.addTokenToRequest(request, this.authService.accessToken);
            return this.handleRequest(requestWithToken, next);
        }

        // check if refresh token has expired
        if (now > this.authService.refreshTokenExpiresAt) {
            this.authService.logout();
            return this.handleRequest(request, next);
        }

        // refresh tokens using the refreshToken
        return this.refreshTokenAndThenExecuteRequest(request, next);
    }

    private handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (err.status === 401 && !this.skipAuthRedirect.includes(request.url)) {
                    this.authService.logout();
                }

                return throwError(err);
            })
        );
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    private refreshTokenAndThenExecuteRequest(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // If there is no currently active refresh request, create a new one
        if (!this.inflightAuthRequest) {
            this.inflightAuthRequest = this.authService.executeTokenRefresh();
        }

        // pipe to this refresh request
        return this.inflightAuthRequest.pipe(
            // switchMap to use the response and to try it again with the new token
            switchMap(() => {
                // resend the request
                return next.handle(this.addTokenToRequest(request, this.authService.accessToken));
            }),
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    // if it fails again with 401 -> logout
                    if ((err.status === 401 || err.status === 400) && !this.skipAuthRedirect.includes(request.url)) {
                        return of(this.authService.logout());
                    }
                }
                return throwError(err);
            }),
            finalize(() => {
                // unset inflight request
                this.inflightAuthRequest = null;
            })
        );
    }
}
