import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationService } from '@shared/services/notification.service';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';

@Injectable()
export class APIRequestErrorInterceptor implements HttpInterceptor {

    private get errorsToFetch(): number[] {
        return [400, 500];
    }

    constructor(private notificationService: NotificationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                tap(
                    () => { },
                    err => {
                        if (err instanceof HttpErrorResponse && this.errorsToFetch.includes(err.status)) {
                            const errorMessage = ErrorObjectHelper.getMessageFromResponseObject(err);
                            this.notificationService.error({
                                title: 'Error',
                                message: errorMessage,
                                closeButton: true,
                                lifeTime: 8000
                            });
                        }
                    }
                )
            );
    }
}
