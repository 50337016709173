import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationService } from '@shared/services/notification.service';
import { UserService } from '@user/services/user.service';

export function compareValidator(functionToGetControl: () => AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const matchesValue = functionToGetControl()?.value === control.value;
    return matchesValue ? null : { match: { value: false } };
  };
}

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  twoFactorError: string;
  user: any;
  twoFactorImage: any;

  showPasswordFormGroup: boolean = true;
  passwordFormGroup = this.fb.group({
    password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)]],
    passwordConfirmation: ['', [Validators.required, compareValidator(() => this.passwordFormGroup?.get('password'))]],
  });

  get password(): AbstractControl {
    return this.passwordFormGroup.get('password');
  }

  get passwordConfirmation(): AbstractControl {
    return this.passwordFormGroup.get('passwordConfirmation');
  }

  constructor(private userService: UserService,
    private notificationService: NotificationService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.userService.getCurrent().subscribe((user) => {
      this.user = user;
    });
  }

  changePassword() {
    this.userService.changePassword(this.password.value).subscribe(_ => {
      this.notificationService.success('Passwords changed successfully');
      this.showPasswordFormGroup = false;
    })
  }

  enableTwoFactor(): void {
    this.userService.enableTwoFactor().subscribe((res) => {
      this.twoFactorImage = res;
    });
  }

  resetGridStorage(): void {
    for (let item in localStorage) {
      if (item.startsWith('grid') || item.startsWith('toolbargrid')) {
        localStorage.removeItem(item);
      }
    }
    this.notificationService.success('Grid Settings have been reset');
  }

  confirmTwoFactor(token): void {
    this.userService.confirmTwoFactor(token).subscribe(_ => {
      this.user.isTwoFactorEnabled = true;
     },
      (err) => {
        this.twoFactorError = err?.error?.message;
      });
    }
}
