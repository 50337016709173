import { Gender } from "@person/enums/gender.enum";
import { Person } from "@person/models/person.model";
import { DisciplineHelper } from "@team/components/helpers/discipline.helper";
import { Discipline } from "@team/enums/discipline.enum";

export class Team {
    id?: string;
    createdAt?: Date;
    updatedAt?: Date;
    deletedAt?: Date;
    discipline?: Discipline;
    persons?: Person[];
    tournamentId?: string;
    worldRanking?: number;

    get nameOfFirstPerson(): string {
        return this.persons != null && this.persons[0] != null ? this.persons[0].nameWithBWFId : '';
    }

    get nameOfSecondPerson(): string {
        return this.persons != null && this.persons[1] != null ? this.persons[1].nameWithBWFId : '';
    }

    get disciplineName(): string {
        return DisciplineHelper.disciplines.filter(x => x.id === this.discipline)[0]?.name;
    }

    public constructor(init?: Partial<Team>) {
        Object.assign(this, init);
        this.persons = [];
        if (Array.isArray(init.persons) && init.persons.length > 0) {
            if (init.persons.length > 1) {
                if (init.discipline === Discipline.MIXED_DOUBLE) {
                    this.persons = this.getPersonsOrderedByGender(new Person(init.persons[0]), new Person(init.persons[1]));
                } else {
                    this.persons = this.getPersonsOrderedByName(new Person(init.persons[0]), new Person(init.persons[1]));
                }
            } else {
                this.persons.push(new Person(init.persons[0]));
            }
        }
    }

    private getPersonsOrderedByName(person1: Person, person2: Person): Person[] {
        return [person1, person2].sort((a, b) => a.lastname.localeCompare(b.lastname));
    }

    private getPersonsOrderedByGender(person1: Person, person2: Person): Person[] {
        return person1.gender === Gender.MALE ? [person1, person2] : [person2, person1];
    }
}
