import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { HotelRoomAssignmentService } from '@hotel/services/hotel-room-assignment.service';
import { HotelRoomBookingService } from '@hotel/services/hotel-room-booking.service';

type Assignment = {
  startDate: string
  endDate: string
};

type Booking = {
  id: string
  amount: number
};

@Component({
  selector: 'app-hotel-conflicts',
  templateUrl: './hotel-conflicts.component.html',
  styleUrls: ['./hotel-conflicts.component.scss']
})
export class HotelConflictsComponent implements OnInit {
  private assignments = [];
  private bookings = [];
  private assignmentsByBookingId = {};
  private tournamentId: string;

  constructor(
    private bookingService: HotelRoomBookingService ,
    private assignmentService: HotelRoomAssignmentService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;

    this.assignmentService.get({
      join: ['person', 'hotelRoomBooking'],
      filter: [`tournamentId||$eq||${this.tournamentId}`]
    }).subscribe((res) => {
      this.assignments = res;

      this.bookingService.get({
        join: ['hotel', 'assignments'],
        filter: [`tournamentId||$eq||${this.tournamentId}`]
      }).subscribe((bookings) => {
        this.bookings = bookings;

        this.assignments.map((assignment) => {
          if (!this.assignmentsByBookingId[assignment.hotelRoomBookingId]) {
            this.assignmentsByBookingId[assignment.hotelRoomBookingId] = [];
          }

          this.assignmentsByBookingId[assignment.hotelRoomBookingId].push(assignment);
        });
      });
    });
  }

  amountConflicts(): Array<object> {
    const conflicts = [];

    this.bookings.forEach((booking: Booking) => {
      const assignments = this.assignmentsByBookingId[booking.id];

      if (booking.amount < assignments.length) {
        conflicts.push({
          booking,
          assignments,
          message: 'Too many assignments for this booking.'
        });
      }
    });

    return conflicts;
  }

  dateConflicts(): Array<object> {
    const conflicts = [];

    this.bookings.forEach((booking) => {
      const assignments = this.assignmentsByBookingId[booking.id];

      assignments.forEach((assignment: Assignment) => {
        const aStart = Date.parse(assignment.startDate);
        const aEnd = Date.parse(assignment.endDate);
        const bStart = Date.parse(booking.startDate);
        const bEnd = Date.parse(booking.endDate);

        if (aStart < bStart || aEnd > bEnd || aEnd < aStart || bEnd < bStart) {
          conflicts.push({
            booking,
            assignment,
            message: 'Room assignment does not match booking date'
          });
        }
      });
    });

    return conflicts;
  }
}
