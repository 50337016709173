<div *ngFor="let tournament of this.tournaments">
    <div class="grid grid-cols-2 gap-4 mb-3">
        <div>
            <h2 class="text-xl font-semibold text-gray-900 mt-5 mb-1">{{tournament.name}}</h2>
        </div>
    </div>
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul>
            <li *ngFor="let badge of this.getBadgesByTournament(tournament.id)">
                <a routerLink="/tournaments/{{tournament.id}}/badges/edit/{{badge.id}}"
                    class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                            <div class="text-sm leading-5 font-medium text-indigo-600 truncate">
                                Badge #{{badge.id}}
                            </div>
                            <div class="ml-2 flex-shrink-0 flex text-gray-500">
                                <i class="fas fa-calendar mr-2"></i>
                                Created at {{badge.createdAt | date}}
                            </div>
                        </div>
                        <div class="mt-2 sm:flex sm:justify-between">
                            <div class="sm:flex">
                                <div class="mr-6 flex items-center text-sm leading-5 text-gray-500">
                                    <i class="fas fa-lock mr-2"></i>
                                    Access to:
                                    <div *ngFor="let accessType of badge.accessTypes; let i = index"
                                        class="badge badge-success ml-2">
                                        {{ accessType.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
    <p *ngIf="this.getBadgesByTournament(tournament.id).length == 0">
        No badge found for this person.
    </p>
</div>