<app-header>Account Settings</app-header>

<app-container *ngIf="this.user">
    <div class="mt-6 sm:mt-5 sm:border-t sm:border-gray-300 sm:pt-5">
        <div class="sm:pb-5">
            <h2 class="text-xl leading-6 font-medium text-gray-900">
                Two-Factor Authentication
            </h2>
        </div>
        <app-alert *ngIf="this.user.isTwoFactorEnabled" type="success" class="w-100">
            Two-Factor authentication is enabled
        </app-alert>
        <div *ngIf="!this.user.isTwoFactorEnabled && !this.twoFactorImage"
            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
            <p class="block text-sm font-medium leading-5 text-gray-700 sm:col-span-1 sm:mt-px sm:pt-2">
                Increase your account's security by enabling Two-Factor Authentication (2FA)
            </p>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
                <button type="submit" (click)="enableTwoFactor()"
                    class="bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 active:bg-indigo-700 inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white focus:outline-none focus:ring-indigo-500 transition ease-in-out duration-150">
                    Enable two-factor authentication
                </button>
            </div>
        </div>

        <div *ngIf="this.twoFactorImage && !this.user.isTwoFactorEnabled"
            class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
            <div class="sm:col-span-1 sm:mt-px sm:pt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Download an authentication app
                </h3>
                <p class="block text-sm font-medium leading-5 text-gray-700">
                    Download and install Authy or Google Authenticator for your phone or
                    tablet.
                </p>
            </div>
            <div class="mt-1 sm:mt-0 sm:col-span-2"></div>
            <div class="sm:col-span-1 sm:mt-px sm:pt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Scan the QR Code
                </h3>
                <p class="block text-sm font-medium leading-5 text-gray-700">
                    Open the authentication app and scan the image to the right, using your phone's camera.
                </p>
            </div>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
                <img src="{{this.twoFactorImage}}" />
            </div>
            <div class="sm:col-span-1 sm:mt-3 sm:pt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Enter your token
                </h3>
                <p class="block text-sm font-medium leading-5 text-gray-700">
                    Enter the 6-digit verification code generated by the app.
                </p>
            </div>
            <div class="sm:mt-3 sm:col-span-1" [class.error]="this.twoFactorError">
                <input #twoFactorConfirmation id="two-factor-confirmation" class="form-control mt-1 block w-full"
                    type="text" placeholder="123456" required (keyup.enter)="btnTwoFactorConfirmation.click()" />
                <p class="error-message" id="two-factor-error">{{this.twoFactorError}}</p>
            </div>
            <div class="sm:mt-3 sm:col-span-1">
                <button #btnTwoFactorConfirmation type="submit"
                    class="bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 active:bg-indigo-700' }} inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white focus:outline-none focus:ring-indigo-500 transition ease-in-out duration-150"
                    (click)="this.confirmTwoFactor(twoFactorConfirmation.value)">
                    Enable Two-Factor
                </button>
            </div>
        </div>

        <br />
        <hr />

        <div>
            <div class="sm:pb-5">
                <h2 class="text-xl leading-6 font-medium text-gray-900 mt-6">
                    Grid Settings
                </h2>
            </div>
        </div>
        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
            <p class="block text-sm font-medium leading-5 text-gray-700 sm:col-span-1 sm:mt-px sm:pt-2">
                Reset Grid Settings to default state
            </p>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
                <button type="submit" (click)="resetGridStorage()"
                    class="bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 active:bg-indigo-700 inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white focus:outline-none focus:ring-indigo-500 transition ease-in-out duration-150">
                    Reset Grid Settings
                </button>
            </div>
        </div>

        <br />
        <hr />

        <div [formGroup]="passwordFormGroup" [hidden]="!this.showPasswordFormGroup">
            <div>
                <div class="sm:pb-5">
                    <h2 class="text-xl leading-6 font-medium text-gray-900 mt-6">
                        Change Password
                    </h2>
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                <div class="block text-sm font-medium leading-5 text-gray-700 sm:col-span-1 sm:mt-px sm:pt-2">
                    <span class="text-gray-500 font-bold">New Password</span>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <input id="password" class="form-control mt-1 block w-full" type="password"
                            formControlName="password" name="password" />
                    </div>
                    <p *ngIf="(password.touched || passwordConfirmation.touched) && password.hasError('pattern')" 
                    class="mt-2 text-sm text-red-600" id="password-error">
                        Your password must contain at least one letter and one digit, and must be at least 8 characters long.
                    </p>
                </div>
                <div class="block text-sm font-medium leading-5 text-gray-700 sm:col-span-1 sm:mt-px sm:pt-2">
                    <span class="text-gray-500 font-bold">Confirm new Password</span>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <input id="passwordConfirmation" class="form-control mt-1 block w-full" type="password"
                            formControlName="passwordConfirmation" name="passwordConfirmation" />
                    </div>
                    <p *ngIf="(passwordConfirmation.touched && passwordConfirmation.hasError('match'))" 
                    class="mt-2 text-sm text-red-600" id="password-error">
                        Passwords do not match
                    </p>
                </div>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <button type="submit" (click)="this.changePassword()" [disabled]="!this.password.valid || !this.passwordConfirmation.valid"
                        class="bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 active:bg-indigo-700 inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white focus:outline-none focus:ring-indigo-500 transition ease-in-out duration-150">
                        Save new password
                    </button>
                </div>

            </div>
        </div>
    </div>
</app-container>