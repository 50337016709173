<app-container>
    <app-header-delete [isDeleteButtonHidden]="!this.organizationId" (confirm)="this.onDelete()">Organization
    </app-header-delete>

    <app-alert *ngIf="this.error" type="error">
        {{error}}
    </app-alert>

    <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div class="d-block">
            <span class="text-gray-500 font-bold">Organization name *</span>
            <div class="mt-1 relative rounded-md shadow-sm">
                <input id="name" class="form-control mt-1 block w-full" type="text" formControlName="name" name="name"
                    placeholder="Swiss Open 2020" required />
            </div>

            <p *ngIf="name.touched && name.hasError('required')" class="mt-2 text-sm text-red-600"
                id="organization-name-error">
                The organization name is required.</p>
        </div>
        <br />

        <div class="d-block">
            <span class="text-gray-500 font-bold">Organization type</span>
            <div class="mt-1 relative rounded-md shadow-sm">
                <input id="organizationType" class="form-control mt-1 block w-full" type="text" formControlName="organizationType" name="organizationType"
                    placeholder="Team" />
            </div>
        </div>
        <br />

        <div class="d-block">
          <span class="text-gray-500 font-bold">Address</span>
          <div class="rounded-md shadow-sm mt-1">
            <span class="text-gray-500">Street</span>
            <input id="street" class="form-control mt-1 block w-full" type="text" formControlName="street" name="street"
            placeholder="St. Jakobs-Strasse 390" />
          </div>
          <div class="grid grid-cols-2 gap-2 mt-2">
            <div class="rounded-md shadow-sm">
              <span class="text-gray-500">Zip</span>
              <input id="zip" class="form-control mt-1 block w-full" type="text" formControlName="zip" name="zip"
              placeholder="4052" />
            </div>
            <div class="rounded-md shadow-sm">
              <span class="text-gray-500">City</span>
              <input id="city" class="form-control mt-1 block w-full" type="text" formControlName="city" name="city"
              placeholder="Basel" />
            </div>
          </div>
        </div>
        <br />

        <div class="d-block">
            <span class="text-gray-500 font-bold">Contact Person</span>
            <div class="mt-1 relative rounded-md shadow-sm">
                <app-person-select [formGroup]="this.formGroup" formControlName="person"
                    placeholder="Select a contact person (optional)"></app-person-select>
            </div>

            <p *ngIf="person.touched && person.hasError('required')" class="mt-2 text-sm text-red-600"
                id="person-error">
                The contact person is required.</p>
        </div>
        <br />

        <div class="d-block">
            <span class="inline-flex rounded-md shadow-sm">
                <button backButton type="button" class="btn btn-white">
                    Cancel
                </button>
                <button type="submit" [disabled]="!this.formGroup.valid" class="btn btn-primary ml-2">
                    {{ this.organizationId ? 'Update' : 'Create' }}
                </button>
            </span>
        </div>
    </form>

</app-container>
