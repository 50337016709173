import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HotelRoomType } from '@hotel/enums/hotel-room-type.enum';
import { RoomTypeHelper } from '@hotel/helper/room-type.helper';
import { HotelRoomAssignmentService } from '@hotel/services/hotel-room-assignment.service';
import { HotelRoomBookingService } from '@hotel/services/hotel-room-booking.service';
import { HotelService } from '@hotel/services/hotel.service';
import { PersonTournamentFieldsService } from '@person/services/person-tournament-fields.service';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';
import { TournamentCacheService } from '@tournament/services/tournament-cache.service';
import { PersonService } from '@person/services/person.service';
import { Person } from '@person/models/person.model';

@Component({
  selector: 'app-hotel-room-booking-edit',
  templateUrl: './hotel-room-booking-edit.component.html',
  styleUrls: ['./hotel-room-booking-edit.component.scss']
})
export class HotelRoomBookingEditComponent implements OnInit {

  error = null;
  tournamentId: string = null;
  hotelRoomBookingId: string = null;
  hotelRoomBooking = null;
  formGroup = this.fb.group({
    amount: ['1', [Validators.required]],
    hotel: [null, [Validators.required]],
    startDate: [null, [Validators.required]],
    endDate: [null, [Validators.required]],
    hotelRoomType: [HotelRoomType.SINGLE, []],
    assignments: this.fb.array([]),
  });
  hotels = [];
  persons = [];
  selectedDateDefault = new Date();
  hotelRoomTypeEnum: typeof HotelRoomType = HotelRoomType;

  get hotelRoomSize(): number {
    if (!this.hotelRoomType.value) {
      return 0;
    }
    return RoomTypeHelper.getRoomSizeByType(this.hotelRoomType.value);
  }

  get amount(): AbstractControl {
    return this.formGroup.get('amount');
  }

  get startDate(): AbstractControl {
    return this.formGroup.get('startDate');
  }

  get endDate(): AbstractControl {
    return this.formGroup.get('endDate');
  }

  get hotelRoomType(): AbstractControl {
    return this.formGroup.get('hotelRoomType');
  }

  get hotel(): AbstractControl {
    return this.formGroup.get('hotel');
  }

  get hotelId(): AbstractControl {
    return this.formGroup.get('hotelId');
  }

  get assignments(): FormArray {
    return this.formGroup.get('assignments') as FormArray;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private tournamentCacheService: TournamentCacheService,
    private hotelRoomBookingService: HotelRoomBookingService,
    private hotelRoomAssignmentService: HotelRoomAssignmentService,
    private hotelService: HotelService,
    private personService: PersonService,
    private personTournamentFieldsService: PersonTournamentFieldsService) {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
    this.hotelRoomBookingId = this.route.snapshot.params.bookingId;

    if (!!this.hotelRoomBookingId) {
      this.amount.disable();
      this.hotelRoomType.disable();
      this.hotel.disable();
    }

    this.hotelService.get().subscribe((res) => {
      this.hotels = res;
    });
  }

  async ngOnInit(): Promise<void> {
    const currentTournament = await this.tournamentCacheService.getTournament(this.tournamentId);
    this.selectedDateDefault = currentTournament.start;
    this.startDate.setValue(currentTournament.start);
    this.endDate.setValue(currentTournament.end);

    this.persons = await this.personService.get({filter: ['tournamentRoles.tournamentId||$eq||' + this.tournamentId]}).toPromise();

    if (this.hotelRoomBookingId) {
      this.hotelRoomBooking = await this.hotelRoomBookingService.getOne(this.hotelRoomBookingId, { join: ['assignments'] }).toPromise();
      for (let item of this.hotelRoomBooking.assignments) { 
        const tournamentField = await this.personTournamentFieldsService.get({filter: [`tournamentId||$eq||${this.tournamentId}`, `personId||$eq||${item.personId}`]}).toPromise();
        item.hasPaid = tournamentField[0]?.isHotelInvoicePaid;
      }
      this.startDate.setValue(this.hotelRoomBooking.startDate);
      this.endDate.setValue(this.hotelRoomBooking.endDate);
      this.hotelRoomType.setValue(this.hotelRoomBooking.hotelRoomType);
      this.hotel.setValue(this.hotelRoomBooking.hotelId);
      for (let i = 0; i < this.hotelRoomBooking.assignments.length; i++) {
        this.addAssignmentFormControls(this.hotelRoomBooking.assignments[i]);
      }
    }
  }

  areAllAssignmentFormsValid(): boolean {
    for (let i = 0; i < this.assignments.controls.length; i++) {
      const formGroup = this.assignments.controls[i] as UntypedFormGroup;
      if (!formGroup?.valid) {
        return false;
      }
    }

    return true;
  }

  getAssignmentsPerson(index: number): AbstractControl {
    return (this.assignments.controls[index] as UntypedFormGroup).get('person');
  }

  getAssignmentsStartDate(index: number): AbstractControl {
    return (this.assignments.controls[index] as UntypedFormGroup).get('assignmentStartDate');
  }

  getAssignmentsEndDate(index: number): AbstractControl {
    return (this.assignments.controls[index] as UntypedFormGroup).get('assignmentEndDate');
  }

  async addAssignmentFormControls(assignment?: any): Promise<void> {
    this.assignments.controls.push(this.fb.group({
      assignmentId: [assignment?.id, []],
      person: [assignment?.personId, [Validators.required]],
      assignmentStartDate: [assignment?.startDate || this.startDate.value, [Validators.required]],
      assignmentEndDate: [assignment?.endDate || this.endDate.value, [Validators.required]],
    }));
  }

  onDelete(): void {
    this.hotelRoomBookingService.delete(this.hotelRoomBookingId).subscribe(
      () => {
        this.location.back();
      }, (error) => {
        this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
      }
    );
  }

  onPersonSearch(term, item: Person): boolean {
    const valuesToCompareAgainst = [item.firstname, item.lastname, item.bwfId, item.humanFriendlyId];
    return valuesToCompareAgainst.some(x => String(x).toLowerCase().includes(term.toLowerCase()));
  }

  onAddRoomAssignment(): void {
    this.addAssignmentFormControls();
  }

  onDeleteRoomAssignment(index: number): void {
    this.assignments.removeAt(index);
  }

  async saveRoomAssignments(): Promise<void> {
    const updatedItems = [];

    for (let i = 0; i < this.assignments.controls.length; i++) {
      const assignmentItem = this.assignments.controls[i] as UntypedFormGroup;
      const id = assignmentItem.get('assignmentId').value;

      const assignment = {
        tournamentId: this.tournamentId,
        hotelRoomBookingId: this.hotelRoomBookingId,
        personId: this.getAssignmentsPerson(i).value,
        startDate: this.getAssignmentsStartDate(i).value,
        endDate: this.getAssignmentsEndDate(i).value,
      };

      if (id) {
        await this.hotelRoomAssignmentService.update(assignment, id).toPromise();
        updatedItems.push(id);
      }
      else {
        await this.hotelRoomAssignmentService.create(assignment).toPromise();
      }
    }

    for (const assignmentItem of this.hotelRoomBooking.assignments) {
      if (!updatedItems.includes(assignmentItem.id)) {
        await this.hotelRoomAssignmentService.delete(assignmentItem.id).toPromise();
      }
    }
  }

  submit(): void {
    const HotelRoomBooking = {
      amount: this.amount.value,
      startDate: this.startDate.value,
      endDate: this.endDate.value,
      hotelRoomType: this.hotelRoomType.value,
      hotelId: this.hotel.value,
      tournamentId: this.tournamentId
    };

    if (this.hotelRoomBookingId) {
      this.hotelRoomBookingService.update(HotelRoomBooking, this.hotelRoomBookingId)
        .subscribe(async _ => {
          await this.saveRoomAssignments();
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        });
    } else {
      this.hotelRoomBookingService.create(HotelRoomBooking)
        .subscribe(_ => {
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        });
    }
  }

}
