import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { HistoryService } from '@tournament/services/history.service';

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss']
})
export class HistoryListComponent implements OnInit {

  @ViewChild('grid') public grid: GridComponent;
  
  tournamentId: string = null;
  data: any[];

  formGroup: FormGroup = this.fb.group({
    historyEntityType: [1, []],
    historyChangesSince: [new Date('2022-01-01'), []]
  });

  get historyEntityType(): AbstractControl {
    return this.formGroup.get('historyEntityType');
  }

  get historyChangesSince(): AbstractControl {
    return this.formGroup.get('historyChangesSince');
  }

  constructor(
    private fb: FormBuilder,
    private historyService: HistoryService,
    private route: ActivatedRoute) {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
  }

  async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  async onSearch(): Promise<void> {
    await this.loadData();
  }

  async loadData(): Promise<void> {
    const bookingData = await this.historyService.getHistory(this.tournamentId, this.historyEntityType.value, this.historyChangesSince.value).toPromise();
    this.data = bookingData.map(x => {
      // remove properties that we do not want to show as columns in the grid
      delete x.id;
      delete x.originalID;
      delete x.deletedAt;
      delete x.tournamentId;
      delete x.hotelId;
      delete x.hotelRoomBookingId;
      delete x.personId;
      delete x.modifiedBy;
      return x;
    });

    this.grid.refreshHeader();
  }
}
