import { Pipe, PipeTransform } from '@angular/core';
import { TournamentCacheService } from '@tournament/services/tournament-cache.service';

@Pipe({
    name: 'tournament',
    pure: false
})
export class TournamentPipe implements PipeTransform {
    private tournaments = {};

    constructor(private tournamentService: TournamentCacheService) {
        this.tournamentService.getTournaments().then((tournaments) => {
            tournaments.forEach(t => {
                this.tournaments[t.id.toString()] = t.name;
            });
        });
    }

    transform(tournamentId): string {
        return this.tournaments[tournamentId] || 'Tournament';
    }
}
