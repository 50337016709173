<app-container>
    <div class="mt-2 grid grid-cols-2 mb-5">
        <div class="col-span-1">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
                Volunteers time planning
            </h2>
        </div>
    </div>

    <app-date-slider [startDate]="this.startDate" [endDate]="this.endDate" [selectedDate]="this.selectedDate"
        [disabled]="this.isLoading" (change)="this.onSelectedDateChanged($event)"></app-date-slider>

    <br>

    <div style="max-width: 250px;" class="mt-5">
        <p class="mb-2"><b>Filter role</b></p>
        <ng-select [(ngModel)]="this.selectedRoleId" [items]="roles" [bindLabel]="'name'" [bindValue]="'id'"
            [clearable]="false" (change)="this.onSelectedRoleChanged()"></ng-select>
    </div>

    <div class="grid grid-cols-2 gap-3 mt-8">

        <div class="col-span-1">
            <p class="mb-2"><b>Person</b></p>
        </div>

        <div class="col-span-1">
            <p class="mb-2"><b>Present at</b></p>
            <br />
            <p>Morning ({{this.getAmountOfVolunteersByTimeOfDay(this.TimeOfDay.MORNING)}})</p>
            <p>Afternoon ({{this.getAmountOfVolunteersByTimeOfDay(this.TimeOfDay.AFTERNOON)}})</p>
            <p>Evening ({{this.getAmountOfVolunteersByTimeOfDay(this.TimeOfDay.EVENING)}})</p>
        </div>
    </div>

    <ng-container *ngIf="!this.isLoading">
        <p *ngIf="!this.volunteers || this.volunteers.length === 0" class="mt-5 text-red-700">
            No volunteers found for this tournament.
        </p>

        <div class="grid grid-cols-2 gap-3 mt-5" *ngFor="let volunteer of this.volunteers">

            <div class="col-span-1">
                <div class="flex items-center">
                    <img class="h-10 w-10 rounded-full" [src]="volunteer.person.avatarUrl" alt="Photo">
                    <p class="ml-4">{{volunteer.person.firstname}} {{volunteer.person.lastname}}</p>
                </div>
            </div>

            <div class="col-span-1">
                <div class="flex flex-wrap content-center h-full">
                    <div class="flex items-center">
                        <input type="checkbox" id="morning_{{volunteer.person.id}}"
                            [checked]="this.isVolunteerPresentAt(this.TimeOfDay.MORNING, volunteer)"
                            (change)="this.onCheckboxChanged(this.TimeOfDay.MORNING, volunteer)"
                            class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                        <label for="morning_{{volunteer.person.id}}" class="ml-3">
                            Morning
                        </label>
                    </div>

                    <div class="flex items-center ml-5">
                        <input type="checkbox" id="afternoon_{{volunteer.person.id}}"
                            [checked]="this.isVolunteerPresentAt(this.TimeOfDay.AFTERNOON, volunteer)"
                            (change)="this.onCheckboxChanged(this.TimeOfDay.AFTERNOON, volunteer)"
                            class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                        <label for="afternoon_{{volunteer.person.id}}" class="ml-3">
                            Afternoon
                        </label>
                    </div>

                    <div class="flex items-center ml-5">
                        <input type="checkbox" id="evening_{{volunteer.person.id}}"
                            [checked]="this.isVolunteerPresentAt(this.TimeOfDay.EVENING, volunteer)"
                            (change)="this.onCheckboxChanged(this.TimeOfDay.EVENING, volunteer)"
                            class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                        <label for="evening_{{volunteer.person.id}}" class="ml-3">
                            Evening
                        </label>
                    </div>
                </div>
            </div>

        </div>
    </ng-container>
</app-container>