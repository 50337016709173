<app-container>
  <div class="mt-2">
    <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9">
      Edit Badge access for {{this.person?.nameWithBWFId}}
    </h2>
  </div>

  <app-alert *ngIf="this.error" type="error">
    {{error}}
  </app-alert>

  <form>
    <div class="from-group mt-5" *ngIf="badgeId">
      <span class="label">AccessTypes</span><br />

      <div class="w-100 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <ng-container *ngFor="let accessType of accessTypes">
          <div
            class="bg-white col-span-1 my-2 p-2 border rounded font-bold {{ isActive(accessType) ? 'bg-green-50' : ''}}">
            <div class="pt-3 pl-3 h-full align-baseline">
              <span *ngIf="isActive(accessType)">
                <i class="mr-1 fa fa-check-circle text-green-400 h-5 w-5"></i>
              </span>

              <span *ngIf="!isActive(accessType)">
                <i class="mr-1 fa fa-times-circle text-red-400 h-5 w-5"></i>
              </span>
              {{accessType.name}}<br />
              <span class="text-gray-600 font-normal">{{accessType.code}}</span>
            </div>
            <div class="h-full">
              <button type="button" *ngIf="!isActive(accessType)" (click)="assignAccessType(accessType)"
                class="btn btn-white float-right">Add</button>
              <button type="button" *ngIf="isActive(accessType)" (click)="removeAccessType(accessType)"
                class="btn btn-white float-right">Remove</button>
            </div>
          </div>
        </ng-container>
      </div>
    </div><br />

    <div class="form-group">
      <span class="inline-flex rounded-md shadow-sm">
        <button backButton type="button" class="btn btn-white">
          Go Back
        </button>
      </span>
    </div>
  </form>

</app-container>