import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessTypeService } from '@badge/services/accessType.service';
import { PersonService } from '@person/services/person.service';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';
import { I18nHelper } from '@shared/helpers/i18n.helper';
import { Country } from '@shared/interfaces/country.interface';

@Component({
  selector: 'app-access-type-edit',
  templateUrl: './accessType-edit.component.html',
  styleUrls: ['./accessType-edit.component.scss']
})
export class AccessTypeEditComponent implements OnInit {

  error = null;
  accessTypeId = null;
  formGroup = this.fb.group({
    name: ['', [Validators.required]],
    code: ['', [Validators.required]],
    color: ['', [Validators.required, Validators.pattern('^#(?:[0-9a-fA-F]{3}){1,2}$')]],
  });
  countries: Country[] = I18nHelper.getAllCountries();
  persons = [];

  get name(): AbstractControl {
    return this.formGroup.get('name');
  }

  get code(): AbstractControl {
    return this.formGroup.get('code');
  }

  get color(): AbstractControl {
    return this.formGroup.get('color');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private accessTypeService: AccessTypeService,
    private personService: PersonService) {
      this.personService.get().subscribe((res) => {
        this.persons = res;
      });

      this.accessTypeId = this.route.snapshot.params.accessTypeId;
      if (this.accessTypeId) {
        this.accessTypeService.getOne(this.accessTypeId)
          .subscribe((res) => {
            this.name.setValue(res.name);
            this.code.setValue(res.code);
            this.color.setValue(res.color);
          });
    }
  }

  ngOnInit(): void { }

  onDelete(): void {
    this.accessTypeService.delete(this.accessTypeId).subscribe(
      () => {
        this.location.back();
      }, (error) => {
        this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
      }
    );
  }

  submit(): void {
    const accessType = {
      name: this.name.value,
      code: this.code.value,
      color: this.color.value,
    };

    if (this.accessTypeId) {
      this.accessTypeService.update(accessType, this.accessTypeId).subscribe(
        () => {
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    } else {
      this.accessTypeService.create(accessType).subscribe(
        () => {
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    }
  }

}
