import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { TournamentService } from '@tournament/services/tournament.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() isLogoVisible = true;
  latestTournament = null;
  userAvatarUrl = `${environment.api}/persons/default/avatar`;

  constructor(
    private authService: AuthService,
    private tournamentService: TournamentService, ) { }

    ngOnInit(): void {
      this.tournamentService.get({ sort: 'start,DESC'}).subscribe((res => {
        this.latestTournament = res[0];
      }));
    }

  isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  logout(): void {
    this.authService.logout();
  }

}
