<div [formGroup]="this.formGroup">
    <ng-select [(ngModel)]="this.selectedPersonId" [items]="this.persons" [virtualScroll]="true" [formControlName]="this.formControlName" [placeholder]="this.placeholder"
        bindValue="id" bindLabel="nameWithBWFId" (change)="this.onChange($event)" [searchFn]="this.onSearch">
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
            <div class="flex">
                <img *ngIf="this.areAvatarsVisible" class="h-5 w-auto rounded-full mr-2" [src]="item.avatarUrl">
                <span>{{ item.nameWithBWFId }}</span>
            </div>
        </ng-template>
    </ng-select>
</div>