import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';

import { FilterService, GridModule, GroupService, PageService, SortService, ColumnChooserService, ResizeService } from '@syncfusion/ej2-angular-grids';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgSelectModule } from '@ng-select/ng-select';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { PersonRolePipe } from '@person/pipes/personRole.pipe';

import { BadgeListComponent } from './components/badge-list/badge-list.component';
import { BadgeEditComponent } from './components/badge-edit/badge-edit.component';
import { AccessTypeListComponent } from './components/accessType-list/accessType-list.component';
import { AccessTypeEditComponent } from './components/accessType-edit/accessType-edit.component';
import { ScannerComponent } from './components/scanner/scanner.component';

@NgModule({
  declarations: [
    BadgeListComponent,
    BadgeEditComponent,
    AccessTypeListComponent,
    AccessTypeEditComponent,
    ScannerComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    GridModule,
    DatePickerModule,
    NgSelectModule,
    ZXingScannerModule,
  ],
  providers: [PageService, SortService, FilterService, GroupService, ColumnChooserService, ResizeService, PersonRolePipe],
})
export class BadgeModule { }
