import { Pipe, PipeTransform } from '@angular/core';
import { GenderHelper } from '@person/components/helpers/gender.helper';
import { Gender } from '@person/enums/gender.enum';

@Pipe({
    name: 'gender',
    pure: false
})
export class GenderPipe implements PipeTransform {

    constructor() { }

    transform(gender: Gender): string {
        return GenderHelper.getGenderName(gender);
    }
}
