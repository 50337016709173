import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrganizationService } from '@organization/services/organization.service';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent implements OnInit {

  data = [];

  constructor(
    private organizationService: OrganizationService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.organizationService.get({join: ['contactPerson'], sort: 'name,ASC'}).subscribe((res) => {
      this.data = res.map(x => {
        x.zipAndCity = x.zip && x.city ? `${x.zip}, ${x.city}` : x.zip || x.city || '';
        return x;
      });
    });
  }

  onRowSelected(row): void {
    this.router.navigate(['edit', row.data.id], { relativeTo: this.route });
  }

  onRowDeleted(data: any): void {
    this.organizationService.delete(data.id)
      .subscribe((res) => {
        this.data = this.data.filter(x => x.id !== data.id);
      });
  }

}
