import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoleIdentifier } from '@person/enums/role.identifier.enum';
import { Person } from '@person/models/person.model';
import { PersonRoleHelperService } from '@person/services/person-role-helper.service';
import { PersonService } from '@person/services/person.service';
import { TournamentRoleService } from '@person/services/tournament-role.service';
import { NotificationService } from '@shared/services/notification.service';

@Component({
  selector: 'app-person-role-create',
  templateUrl: './person-role-create.component.html',
  styleUrls: ['./person-role-create.component.scss']
})
export class PersonRoleCreateComponent implements OnInit {

  tournamentId = null;
  personRoleId: RoleIdentifier = null;
  persons: Person[] = [];
  personList: Person[] = [];
  amountOfResultsToShow = 15;
  matchCount = 0;

  constructor(
    private personService: PersonService,
    private roleService: TournamentRoleService,
    private personRoleHelper: PersonRoleHelperService,
    private notificationService: NotificationService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
    this.personRoleId = this.personRoleHelper.getRoleByRoute();
    this.loadPersons();
  }

  loadPersons(): void {
    // TODO: workaround, replace this with a better solution
    const filter = [`tournamentRoles.roleId||$eq||${this.personRoleId}`, `tournamentRoles.tournamentId||$eq||${this.tournamentId}`];
    this.personService.get({ sort: 'lastname,ASC' }).subscribe((allPersons) => {
      this.personService.get({ filter }).subscribe((personsToExclude: any) => {
        personsToExclude = personsToExclude.map(person => person.id);
        this.persons = allPersons.filter(person => !personsToExclude.includes(person.id));
        this.personList = this.persons.slice(0, this.amountOfResultsToShow);
        this.matchCount = this.persons.length;
      });
    });
  }

  onAddPerson(person): void {
    this.roleService.create({
      personId: person.id,
      tournamentId: this.tournamentId,
      roleId: this.personRoleId
    }).subscribe(_ => {
      this.persons = this.persons.filter(x => x.id !== person.id);
      this.personList = this.personList.filter(x => x.id !== person.id);
      this.notificationService.success(`${person.firstname} ${person.lastname} was added to the tournament`);
    });
  }

  onSearch(searchTerm: string): void {
    const checkOccurence = (value: string) => {
      return searchTerm.split(' ')
        .some(s => {
          return value?.toUpperCase()
            .includes(s.toUpperCase());
        });
    };
    const newList = this.persons.filter((person: Person) => checkOccurence(person.bwfId) ||
                                                            checkOccurence(person.firstname) ||
                                                            checkOccurence(person.lastname) ||
                                                            checkOccurence(person.email) ||
                                                            checkOccurence(person.phone));
    this.matchCount = newList.length;
    this.personList = newList.slice(0, this.amountOfResultsToShow);
  }

}
