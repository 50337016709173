import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudServiceOptions } from '@shared/interfaces/crud-service-options.interface';
import { RestCrudService } from '@shared/services/rest-crud.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BadgeService extends RestCrudService<any> {
  http: HttpClient;

  get client(): HttpClient {
    return this.http;
  }

  constructor(httpClient: HttpClient) {
    const options: CrudServiceOptions = {
      baseUrl: environment.api,
      resourceName: 'badges'
    };
    super(options, httpClient);
    this.http = httpClient;
  }

  forPerson(personId: string): RestCrudService<any> {
    const options: CrudServiceOptions = {
      baseUrl: `${environment.api}/persons/${personId}/badges`,
      resourceName: 'badges'
    };

    return new RestCrudService<any>(options, this.http);
  }

  downloadSinglePDF(badgeId: string): Observable<any> {
    return this.http.get(`${environment.api}/badges/export/single/${badgeId}`, {responseType: 'blob'});
  }

  downloadMultiplePDFs(badgeIds: Array<string>): Observable<any> {
    return this.http.post(`${environment.api}/badges/export/multi`, badgeIds, { responseType: 'blob' });
  }

  addAccessType(badgeId: string, accessTypeId: string): Observable<any> {
    return this.http.post(`${environment.api}/badges/${badgeId}/access_types/${accessTypeId}`, '');
  }

  removeAccessType(badgeId: string, accessTypeId: string): Observable<any> {
    return this.http.delete(`${environment.api}/badges/${badgeId}/access_types/${accessTypeId}`);
  }
}
