<div class="inset-0 flex items-end justify-center pointer-events-none sm:items-start sm:justify-end mb-3 animate__animated animate__zoomIn">
    <div x-data="{ show: true }" x-show="show" x-transition:enter="transform ease-out duration-300 transition"
        x-transition:enter-start="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        x-transition:enter-end="translate-y-0 opacity-100 sm:translate-x-0"
        x-transition:leave="transition ease-in duration-100" x-transition:leave-start="opacity-100"
        x-transition:leave-end="opacity-0" class="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto">
        <div class="rounded-lg ring-1 ring-black ring-opacity-5">

            <div class="flex rounded-lg">
                <div *ngIf="type != null" class="flex-shrink-0 pl-4 pt-4">
                    <ng-container [ngSwitch]="type">
                        <svg *ngSwitchCase="TYPE.SUCCESS" class="h-6 w-6 text-green-400"
                            x-description="Heroicon name: check-circle" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                        </svg>
                        <svg *ngSwitchCase="TYPE.INFO" class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                clip-rule="evenodd" />
                        </svg>
                        <svg *ngSwitchCase="TYPE.WARNING" class="h-5 w-5 text-yellow-400"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd" />
                        </svg>
                        <svg *ngSwitchCase="TYPE.DANGER" class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                clip-rule="evenodd" />
                        </svg>
                    </ng-container>
                </div>
                <div class="w-0 ml-3 flex-1 flex items-center p-4">
                    <div class="w-full">
                        <p class="text-sm leading-5 font-medium text-gray-900">
                            {{title || message}}
                        </p>
                        <p *ngIf="title" class="mt-1 text-sm leading-5 text-gray-500">
                            {{message}}
                        </p>
                    </div>
                </div>

                <div *ngIf="primaryAction || secondaryAction" class="flex border-l border-gray-200">
                    <div class="-ml-px flex flex-col">
                        <div *ngIf="primaryAction" class="h-0 flex-1 flex border-b border-gray-200">
                            <button (click)="this.onPrimaryActionClick()"
                                class="-mb-px flex items-center justify-center w-full rounded-tr-lg border border-transparent px-4 py-3 text-sm leading-5 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-blue-500 focus:border-blue-300 active:text-indigo-700 active:bg-gray-50 transition ease-in-out duration-150">
                                {{primaryAction.name}}
                            </button>
                        </div>
                        <div *ngIf="secondaryAction" class="-mt-px h-0 flex-1 flex">
                            <button (click)="this.onSecondaryActionClick()"
                                class="flex items-center justify-center w-full rounded-br-lg border border-transparent px-4 py-3 text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                {{secondaryAction.name}}
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="!primaryAction && !secondaryAction" class="ml-4 mr-4 mt-4 flex-shrink-0 flex">
                    <button (click)="this.onCloseClick()"
                        class="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                        <svg class="h-5 w-5" x-description="Heroicon name: x" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>