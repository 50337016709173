<app-container [hasFixedWidth]="false">
    <div class="mt-2 md:flex md:items-center md:justify-between">
      <div class="flex-1 min-w-0">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
          Online Registrations ({{data.length}})
        </h2>
      </div>
    </div>
  
    <br>
  
    <ng-container *ngIf="this.data">
      <ejs-grid defaultGridSettings [dataSource]='data' (rowSelected)="onRowSelected($event)" (rowDataBound)="onRowDataBound($event)">
        <e-columns>
          <e-column headerText='Photo' [showInColumnChooser]='false' width="100">
            <ng-template #template let-data>
              <div class="flex items-center">
                <img class="h-10 w-10 rounded-full" [src]="data.imageUrl" alt="Photo">
              </div>
            </ng-template>
          </e-column>
          <e-column field='data.BWFID' headerText="BWF ID" type="string" width="100"></e-column>
          <e-column field='data.Firstname' headerText="Firstname" type="string"></e-column>
          <e-column field='data.Lastname' headerText="Lastname" type="string"></e-column>
          <e-column field='data.Nationality' headerText="Nationality" type="string"></e-column>
          <e-column field='data.Role' headerText="Role" type="string"></e-column>
          <e-column field='data.IsHotelNeeded' headerText="Hotel needed" type="boolean" [visible]="false"></e-column>
          <e-column field='data.IsTransportNeeded' headerText="Transport needed" type="boolean" [visible]="false"></e-column>
          <e-column field='data.TShirtSize' headerText="Shirt size" type="string" [visible]="false"></e-column>
          <e-column field='createdAt' headerText="Created At" type="date" [format]="this.formatSettings"></e-column>
          <e-column field='approvedAt' headerText="Approved At" type="date" [format]="this.formatSettings"></e-column>
        </e-columns>
      </ejs-grid>
    </ng-container>
  </app-container>