import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudServiceOptions } from '@shared/interfaces/crud-service-options.interface';
import { RestCrudService } from '@shared/services/rest-crud.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HotelRoomAssignmentService extends RestCrudService<any> {
  constructor(httpClient: HttpClient) {
    const options: CrudServiceOptions = {
      baseUrl: environment.api,
      resourceName: 'hotel_room_assignments'
    };
    super(options, httpClient);
  }
}
