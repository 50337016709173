<app-container>
    <div class="mt-2 grid grid-cols-2">
        <div class="col-span-1">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
                {{ this.tournamentId ? 'Edit' : 'Create new' }} Tournament
            </h2>
        </div>
    </div>
    <br>

    <app-alert *ngIf="this.error" type="error">
        {{error}}
    </app-alert>

    <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div class="form-group" [class.error]="name.touched && name.hasError('required')">
            <span class="label">Tournament name</span>
            <div class="mt-1 relative rounded-md shadow-sm">
                <input id="name" class="form-control mt-1 block w-full" type="text" formControlName="name" name="name"
                    placeholder="Swiss Open 2020" required />
            </div>

            <p class="error-message" id="tournament-name-error">
                The tournament name is required.</p>
        </div>
        <br />

        <div class="sm:grid sm:grid-cols-2 sm:gap-4">
            <div class="sm:col-span-1 form-group" [class.error]="start.touched && start.hasError('required')">
                <span class="label">Start date</span>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <ejs-datepicker class="mt-1 block w-full" [openOnFocus]="true" formControlName="start" name="start"
                        format="dd.MM.yyyy" [placeholder]="'start date'" floatLabelType='Never'></ejs-datepicker>
                </div>

                <p class="error-message" id="start-date-error">
                    The start date is required.</p>
            </div>

            <div class="sm:col-span-1 form-group" [class.error]="end.touched && end.hasError('required')">
                <span class="label">End date</span>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <ejs-datepicker class="mt-1 block w-full" [openOnFocus]="true" formControlName="end" name="end"
                        format="dd.MM.yyyy" [placeholder]="'end date'" floatLabelType='Never'></ejs-datepicker>
                </div>

                <p class="error-message" id="end-date-error">
                    The end date is required.</p>
            </div>
        </div>

        <br />

        <div class="d-block">
            <span class="inline-flex rounded-md shadow-sm">
                <button type="submit" [disabled]="!this.formGroup.valid" class="btn btn-primary">
                    {{ this.tournamentId ? 'Update' : 'Create' }}
                </button>
            </span>
        </div>
    </form>

</app-container>