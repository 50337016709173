import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlineRegistrationListComponent } from './components/online-registration-list/online-registration-list.component';
import { OnlineRegistrationEditComponent } from './components/online-registration-edit/online-registration-edit.component';
import { SharedModule } from '@shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { FilterService, GridModule, GroupService, PageService, SearchService, SortService, ToolbarService, ColumnChooserService, ResizeService  } from '@syncfusion/ej2-angular-grids';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PersonModule } from '@person/person.module';
import { GenderPipe } from '@person/pipes/gender.pipe';



@NgModule({
  declarations: [
    OnlineRegistrationListComponent, 
    OnlineRegistrationEditComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    GridModule,
    DatePickerModule,
    NgSelectModule,
    ImageCropperModule,
    NgxDropzoneModule,    
    PersonModule,
  ],
  providers: [
    PageService,
    SortService,
    FilterService,
    GroupService,
    SearchService,
    ToolbarService,
    ColumnChooserService,
    ResizeService,
    GenderPipe
  ]
})
export class OnlineRegistrationModule { }
