<app-container>
  <app-header-delete [isDeleteButtonHidden]="!this.transportBookingId" (confirm)="this.onDelete()">Transport booking</app-header-delete>

  <app-alert *ngIf="this.error" type="error">
    {{error}}
  </app-alert>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <div class="form-group">
      <span class="label">Origin</span>
      <input id="origin" class="form-control" type="text" formControlName="origin" name="origin" placeholder="Basel"/>
    </div>

    <div class="form-group">
      <span class="label">Destination</span>
      <input id="destination" class="form-control" type="text" formControlName="destination" name="destination"
        placeholder="Zurich" />
    </div>

    <div class="form-group">
      <span class="label">Transport date</span>
      <ejs-datetimepicker formControlName="transportDate" name="transportDate" format="dd.MM.yyyy HH:mm"
        [placeholder]="'Transport date'"></ejs-datetimepicker>
    </div>

    <div class="form-group">
      <span class="label">Connection Number</span>
      <input id="transportNumber" class="form-control" type="text" formControlName="transportNumber"
        name="transportNumber" placeholder="LX41" />
    </div>


    <div class="from-group">
      <span class="label">Person(s) *</span><br />

      <app-person-multi-select (change)="this.fetchTransportDate()" [areAvatarsVisible]="true" [filter]="['tournamentRoles.tournamentId||$eq||' + this.tournamentId]"
        [formGroup]="this.formGroup" formControlName="persons"></app-person-multi-select>

      <p *ngIf="persons.touched && persons.hasError('required')" class="mt-2 text-sm text-red-600" id="persons-error">
        You need to select at least one person.</p>
    </div>
    <br />

    <div class="from-group">
      <span class="label">Type *</span><br />

      <ng-select [items]="this.types" bindValue="id" formControlName="type" placeholder="Select a transportion type">
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="flex">
            <span>{{ item.name }}</span>
          </div>
        </ng-template>
      </ng-select>

      <p *ngIf="type.touched && type.hasError('required')" class="mt-2 text-sm text-red-600" id="type-error">
        The type is required.</p>
    </div>

    <div class="d-block mt-5">
      <span class="text-gray-500 font-bold">Homebound (direction of travel)</span>
      <div class="mt-4 relative flex items-start">
        <div class="flex items-center h-5">
          <input id="homebound" type="checkbox" formControlName="homebound" name="homebound"
            (change)="this.fetchTransportDate()" class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
        </div>
        <div class="ml-3 text-sm leading-5">
          <label for="homebound" class="font-medium text-gray-700">This connection is homebound</label>
          <p class="text-gray-500">Check if this is a connection transporting a person back home. Do not check if this is a transport to the tournament.</p>
        </div>
      </div>
    </div><br />

    <div class="form-group">
      <span class="inline-flex rounded-md shadow-sm">
        <button backButton type="button" class="btn btn-white">
          Cancel
        </button>
        <button type="submit" [disabled]="!this.formGroup.valid" class="btn btn-primary-outline ml-2">
          {{ this.transportBookingId ? 'Update' : 'Create' }}
        </button>
      </span>
    </div>
  </form>

</app-container>