import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationType } from '@shared/enums/notification-type.enum';
import { NotificationButton } from '@shared/interfaces/notification-button.interface';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  TYPE = NotificationType;

  @Input() title: string;
  @Input() message: string;
  @Input() type: NotificationType;
  @Input() closeButton: boolean;
  @Input() primaryAction: NotificationButton;
  @Input() secondaryAction: NotificationButton;

  @Output() dismiss: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onPrimaryActionClick(): void {
    this.primaryAction.action();
    if (this.primaryAction.close) {
      this.dismiss.emit();
    }
  }

  onSecondaryActionClick(): void {
    this.secondaryAction.action();
    if (this.secondaryAction.close) {
      this.dismiss.emit();
    }
  }

  onCloseClick(): void {
    this.dismiss.emit();
  }

}
