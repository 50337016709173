import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnlineRegistrationService {

  constructor(private httpClient: HttpClient) { }

  get(tournamentId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.api}/tournaments/${tournamentId}/tournament_registrations`);
  }

  getOne(registrationId: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.api}/tournament_registrations/${registrationId}`);
  }

  getImage(registrationId: string): Observable<Blob> {
    return this.httpClient.get(`${environment.api}/tournament_registrations/${registrationId}/image`, { responseType: 'blob' });
  }

  update(registrationId: string, registration): Observable<any> {
    return this.httpClient.put<any>(`${environment.api}/tournament_registrations/${registrationId}`, registration);
  }

  delete(registrationId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.api}/tournament_registrations/${registrationId}`, {});
  }
}
