<div class="border-l-4 p-4 mb-5 rounded-sm" 
    [ngClass]="{'bg-green-50 border-green-400' : type === 'success',
    'bg-blue-50 border-blue-400' : type === 'info',
    'bg-yellow-50 border-yellow-400' : type === 'warning',
    'bg-red-50 border-red-400' : type === 'error'}">
    <div class="flex">
        <div class="flex-shrink-0">
            <!-- Heroicon name: exclamation -->
            <ng-container [ngSwitch]="type">
                <svg *ngSwitchCase="'success'" class="h-6 w-6 text-green-400"
                    x-description="Heroicon name: check-circle" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <svg *ngSwitchCase="'info'" class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clip-rule="evenodd" />
                </svg>
                <svg *ngSwitchCase="'warning'" class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd" />
                </svg>
                <svg *ngSwitchCase="'error'" class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd" />
                </svg>
            </ng-container>
        </div>
        <div class="ml-3">
            <p class="text-sm leading-5"
                [ngClass]="{'text-green-700' : type === 'success',
                'text-blue-700' : type === 'info',
                'text-yellow-700' : type === 'warning',
                'text-red-800' : type === 'error'}">
                <ng-content></ng-content>
            </p>
        </div>
    </div>
</div>