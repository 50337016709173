import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HotelRoomAssignmentService } from '@hotel/services/hotel-room-assignment.service';
import { HotelService } from '@hotel/services/hotel.service';
import { TournamentService } from '@tournament/services/tournament.service';

@Component({
  selector: 'app-person-hotel-assignment-list',
  templateUrl: './person-hotel-assignment-list.component.html',
  styleUrls: ['./person-hotel-assignment-list.component.scss']
})
export class PersonHotelAssignmentListComponent implements OnInit {

  personId = null;
  tournaments = [];
  hotelRoomAssignments = [];

  constructor(
    private route: ActivatedRoute,
    private tournamentService: TournamentService,
    private hotelAssignmentService: HotelRoomAssignmentService,
    private hotelService: HotelService
  ) {
    this.personId = this.route.snapshot.params.personId;

    this.tournamentService.get({ sort: 'start,DESC' }).subscribe((res) => {
      this.tournaments = res;
    });

    this.hotelAssignmentService.get(
      {
        sort: 'startDate,DESC',
        join: ['hotelRoomBooking', 'hotelRoomBooking.hotel'],
        filter: `personId||eq||${this.personId}`
      })
      .subscribe(async (res) => {
        for (let i = 0; i < res.length; i++) {
          res[i].hotelRoomBooking.hotel = await this.hotelService.getOne(res[i].hotelRoomBooking.hotelId).toPromise();
        }
        this.hotelRoomAssignments = res;
      });
  }

  ngOnInit(): void { }

  getNights(roomAssignment): number {
    const date1 = new Date(roomAssignment.endDate);
    const date2 = new Date(roomAssignment.startDate);
    const diffTime = Math.abs(date1.getTime() - date2.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  getBookingsByTournament(tournamentId: string): any[] {
    return this.hotelRoomAssignments.filter((assignment) => assignment.hotelRoomBooking.tournamentId === tournamentId);
  }

}
