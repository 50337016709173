<app-container [hasFixedWidth]="false">
  <div class="mt-2 md:flex md:items-center md:justify-between">
    <div class="flex-1 min-w-0">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
        Hotel room bookings
      </h2>
    </div>
    <div class="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
      <span class="ml-3 rounded-md">
        <a class="btn btn-primary" routerLink="./create">Create new booking</a>
      </span>
    </div>
  </div>
  <br />

  <ng-container *ngIf="this.data">
    <ejs-grid defaultGridSettings [dataSource]='data' (rowSelected)="onRowSelected($event)">
      <e-columns>
        <e-column field='roomIdentifier' headerText='Room identifier' type="string"></e-column>
        <e-column field='startDate' headerText='Start Date' type="date" [format]="formatSettings" width="110"></e-column>
        <e-column field='endDate' headerText='End Date' type="date" [format]="formatSettings" width="110"></e-column>
        <e-column field='hotelRoomType' headerText='Room type' type="string" width="110"></e-column>
        <e-column field='bedroomSize' headerText='Bedroom size' type="number" width="110"></e-column>
        <e-column field='availableBeds' headerText='Available beds' type="number" width="110"></e-column>
        <e-column field='hotel.name' headerText="Hotel" type="string"></e-column>
        <e-column headerText='deleted' [showInColumnChooser]='false' textAlign='Right' width="100">
          <ng-template #template let-data>
            <app-button-delete (confirm)="this.onRowDeleted(data)"></app-button-delete>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </ng-container>
  <app-container>