import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonService } from '@person/services/person.service';
import { Discipline } from '@team/enums/discipline.enum';
import { TournamentService } from '@tournament/services/tournament.service';

@Component({
  selector: 'app-person-team-list',
  templateUrl: './person-team-list.component.html',
  styleUrls: ['./person-team-list.component.scss']
})
export class PersonTeamListComponent implements OnInit {

  DISCIPLINE = Discipline;
  personId = null;
  tournaments = [];
  teams: any = [];

  constructor(
    private route: ActivatedRoute,
    private tournamentService: TournamentService,
    private personService: PersonService
  ) {
    this.personId = this.route.snapshot.params.personId;

    this.tournamentService.get({ sort: 'start,DESC' })
    .subscribe((res) => {
      this.tournaments = res;
    });

    this.personService.getOne(this.personId, { join: ['teams', 'teams.persons'] })
    .subscribe((res) => {
      this.teams = res.teams;
    });
   }

  ngOnInit(): void {
  }

  getTeamsByTournament(tournamentId: string): any[] {
    return this.teams.filter((team) => team.tournamentId === tournamentId);
  }

  getAvatarOfPartnerFromPersons(team): string {
    const partner = team.persons.filter(x => x.id !== this.personId)[0];
    return `${partner.avatarUrl}`;
  }

  getNameOfPartnerFromPersons(team): string {
    const partner = team.persons.filter(x => x.id !== this.personId)[0];
    return `${partner.firstname} ${partner.lastname}`;
  }

}
