import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss']
})
export class TabItemComponent implements OnInit {

  @Input() isActive: boolean;

  @Output() active = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(): void {
    this.active.emit();
  }

}
