import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '@core/services/dashboard.service';
import { Organization } from '@organization/models/organization.model';
import { Person } from '@person/models/person.model';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

export enum DashboardTask {
  PARKING_CARDS,
  HOTEL_ROOMS,
  TRANSPORTS_ARRIVAL,
  TRANSPORTS_DEPARTURE,
  BADGES,
  VISAS,
  ENTRIES,
  PHOTO,
  // COVID_TESTS
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild('grid') public grid: GridComponent;

  DashboardTask = DashboardTask;
  data: any = [];
  gridData: any = [];
  tasksSchownInGrid: DashboardTask[] = [];
  formatSettings = { type: 'date', format: 'dd.MM.yyyy' };

  incompleteParkingCardsCounter = 0;
  incompleteEntriesCounter = 0;
  incompleteBadgesCounter = 0;
  incompleteHotelBookingsCounter = 0;
  incompleteTransportBookingsArrivalCounter = 0;
  incompleteTransportBookingsDepartureCounter = 0;
  incompleteVisasCounter = 0;
  incompletePhotosCounter = 0;
  // incompleteCovidTestsCounter = 0;

  isLoading = true;

  constructor(private route: ActivatedRoute, private router: Router, private dashboardService: DashboardService) { }

  async ngOnInit(): Promise<void> {
    const tournamentId = this.route.parent.snapshot.params.tournamentId;
    this.data = await this.dashboardService.getDataForTournament(tournamentId).toPromise();
    this.data = this.data.map(entry => {
      entry.organization = new Organization({ name: entry.organizationName, type: entry.organizationType });
      entry.role = entry.tournamentRoles?.map(x => x.role.name).join(', ');
      entry.birthdate = entry.birthdate ? new Date(entry.birthdate) : null;
      return entry;
    });
    this.initCounters();
    this.isLoading = false;
  }

  initCounters() {
    this.incompleteParkingCardsCounter = this.data.filter(row => this.isParkingCardTaskIncomplete(row)).length;
    this.incompleteEntriesCounter = this.data.filter(row => this.isEntryTaskIncomplete(row)).length;
    this.incompleteBadgesCounter = this.data.filter(row => this.isBadgeTaskIncomplete(row)).length;
    this.incompleteVisasCounter = this.data.filter(row => this.isVisaTaskIncomplete(row)).length;
    this.incompleteTransportBookingsArrivalCounter = this.data.filter(row => this.isTransportOnArrivalTaskIncomplete(row)).length;
    this.incompleteTransportBookingsDepartureCounter = this.data.filter(row => this.isTransportOnDepartureTaskIncomplete(row)).length;
    this.incompleteHotelBookingsCounter = this.data.filter(row => this.isHotelTaskIncomplete(row)).length;
    this.incompletePhotosCounter = this.data.filter(row => !row.hasImage).length;
    // this.incompleteCovidTestsCounter = this.data.filter(row => this.isCovidTestTaskIncomplete(row)).length;
  }

  toggleTask(show: boolean, task: DashboardTask): void {
    if (show) {
      this.showTask(task);
    } else {
      this.hideTask(task);
    }
  }

  showTask(task: DashboardTask): void {
    this.tasksSchownInGrid.push(task);
    this.filterGridData();
  }

  hideTask(task: DashboardTask): void {
    this.tasksSchownInGrid = this.tasksSchownInGrid.filter(t => t !== task);
    this.filterGridData();
  }

  filterGridData() {
    this.gridData = this.data.filter(row =>
      (this.tasksSchownInGrid.includes(DashboardTask.PARKING_CARDS) && this.isParkingCardTaskIncomplete(row))
        || (this.tasksSchownInGrid.includes(DashboardTask.HOTEL_ROOMS) && this.isHotelTaskIncomplete(row))
        || (this.tasksSchownInGrid.includes(DashboardTask.TRANSPORTS_ARRIVAL) && this.isTransportOnArrivalTaskIncomplete(row))
        || (this.tasksSchownInGrid.includes(DashboardTask.TRANSPORTS_DEPARTURE) && this.isTransportOnDepartureTaskIncomplete(row))
        || (this.tasksSchownInGrid.includes(DashboardTask.BADGES) && this.isBadgeTaskIncomplete(row))
        || (this.tasksSchownInGrid.includes(DashboardTask.VISAS) && this.isVisaTaskIncomplete(row))
        || (this.tasksSchownInGrid.includes(DashboardTask.ENTRIES) && this.isEntryTaskIncomplete(row))
        || (this.tasksSchownInGrid.includes(DashboardTask.PHOTO) && !row.hasImage)
        // || (this.tasksSchownInGrid.includes(DashboardTask.COVID_TESTS) && this.isCovidTestTaskIncomplete(row))
    )
  }

  onRowSelected(event) {
    const selectedPerson = event.data as Person;
    this.router.navigate(['persons', selectedPerson.id])
  }

  isParkingCardTaskIncomplete(datarow): boolean {
    return datarow.isParkingCardNeeded && !datarow.isParkingCardIssued;
  }

  isParkingCardTaskComplete(datarow): boolean {
    return datarow.isParkingCardNeeded && datarow.isParkingCardIssued;
  }

  isEntryTaskIncomplete(datarow): boolean {
    return datarow.isPlayer && !datarow.isInATeam;
  }

  isEntryTaskComplete(datarow): boolean {
    return datarow.isPlayer && datarow.isInATeam
  }

  isBadgeTaskIncomplete(datarow): boolean {
    return datarow.isBadgeNeeded && !datarow.isBadgePrinted;
  }

  isBadgeTaskComplete(datarow): boolean {
    return datarow.isBadgeNeeded && datarow.isBadgePrinted;
  }

  isVisaTaskIncomplete(datarow): boolean {
    return datarow.isVisaNeeded && (!datarow.isVisaIssued || !datarow.isVisaLetterSent);
  }

  isVisaTaskComplete(datarow): boolean {
    return datarow.isVisaNeeded && datarow.isVisaIssued && datarow.isVisaLetterSent;
  }

  isHotelTaskIncomplete(datarow): boolean {
    return datarow.isHotelNeeded && !datarow.hasHotelBookings;
  }

  isHotelTaskComplete(datarow): boolean {
    return datarow.isHotelNeeded && datarow.hasHotelBookings;
  }

  isTransportOnArrivalTaskIncomplete(datarow): boolean {
    return datarow.isTransportNeededOnArrival && !datarow.hasTransportBookingsOnArrival;
  }

  isTransportOnDepartureTaskIncomplete(datarow): boolean {
    return datarow.isTransportNeededOnDeparture && !datarow.hasTransportBookingsOnDeparture;
  }

  isTransportOnArrivalTaskComplete(datarow): boolean {
    return datarow.isTransportNeededOnArrival && datarow.hasTransportBookingsOnArrival;
  }

  isTransportOnDepartureTaskComplete(datarow): boolean {
    return datarow.isTransportNeededOnDeparture && datarow.hasTransportBookingsOnDeparture;
  }

  // isCovidTestTaskIncomplete(datarow): boolean {
  //   return !this.isCovidTestTaskComplete(datarow);
  // }

  // isCovidTestTaskComplete(datarow): boolean {
  //   return (datarow.isPcrTestReceived && datarow.pcrTestReceivedAt != null) 
  //   || (datarow.isAntigenTestReceived && datarow.antigenTestReceivedAt != null);
  // }
}
