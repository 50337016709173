import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterSettingsModel, ToolbarItems, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { TeamService } from '@team/services/team.service';

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss']
})
export class TeamListComponent implements OnInit {

  data = [];
  filterSettings: FilterSettingsModel = { type: 'Menu' };
  pageSettings: PageSettingsModel = { pageSizes: [12, 25, 50, 100, 'All'], pageSize: 12 };
  toolbarOptions: ToolbarItems[] = ['PdfExport', 'ExcelExport', 'CsvExport', 'ColumnChooser', 'Search'];

  constructor(
    private teamService: TeamService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    const tournamentId = this.route.parent.snapshot.params.tournamentId;
    this.teamService.get({ join: 'persons', filter: `tournamentId||$eq||${tournamentId}` }).subscribe((res) => {
      this.data = res;
    });
  }

  onRowSelected(row): void {
    this.router.navigate(['edit', row.data.id], { relativeTo: this.route });
  }

  onRowDeleted(data: any): void {
    this.teamService.delete(data.id)
      .subscribe((res) => {
        this.data = this.data.filter(x => x.id !== data.id);
      });
  }
}
