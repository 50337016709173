import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TournamentService } from '@tournament/services/tournament.service';
import { TransportType } from '@transport/enums/transport-type.enum';
import { TransportAssignmentService } from '@transport/services/transport-assignment.service';

@Component({
  selector: 'app-person-transport-list',
  templateUrl: './person-transport-list.component.html',
  styleUrls: ['./person-transport-list.component.scss']
})
export class PersonTransportListComponent implements OnInit {

  TRANSPORT_TYPE = TransportType;
  personId = null;
  tournaments = [];
  transports = [];

  constructor(
    private route: ActivatedRoute,
    private tournamentService: TournamentService,
    private transportService: TransportAssignmentService
  ) {
    this.personId = this.route.snapshot.params.personId;

    this.tournamentService.get({ sort: 'start,DESC' }).subscribe((res) => {
      this.tournaments = res;
    });

    this.transportService.get(
      { 
        sort: 'transportBooking.transportDate,DESC', 
        filter: `personId||eq||${this.personId}`, 
        join: `transportBooking`
      }
    )
    .subscribe((res) => {
      this.transports = res;
    });
   }

  ngOnInit(): void {
  }

  getTransportsByTournament(tournamentId: string): any[] {
    return this.transports
      .map(x => x.transportBooking)
      .filter((transport) => transport.tournamentId === tournamentId);
  }

}
