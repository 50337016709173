import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OrganizationService } from '@organization/services/organization.service';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';

@Component({
  selector: 'app-organization-edit',
  templateUrl: './organization-edit.component.html',
  styleUrls: ['./organization-edit.component.scss']
})
export class OrganizationEditComponent {

  error = null;
  organizationId = null;
  formGroup = this.fb.group({
    name: ['', [Validators.required]],
    organizationType: [null, []],
    street: [null, []],
    zip: [null, []],
    city: [null, []],
    person: [null, []],
  });

  get name(): AbstractControl {
    return this.formGroup.get('name');
  }

  get organizationType(): AbstractControl {
    return this.formGroup.get('organizationType');
  }

  get street(): AbstractControl {
    return this.formGroup.get('street');
  }

  get zip(): AbstractControl {
    return this.formGroup.get('zip');
  }

  get city(): AbstractControl {
    return this.formGroup.get('city');
  }

  get person(): AbstractControl {
    return this.formGroup.get('person');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private organizationService: OrganizationService) {
    this.organizationId = this.route.snapshot.params.organizationId;
    if (this.organizationId) {
      this.organizationService.getOne(this.organizationId, { join: ['contactPerson'] })
        .subscribe((res) => {
          this.name.setValue(res.name);
          this.organizationType.setValue(res.type);
          this.street.setValue(res.street);
          this.zip.setValue(res.zip);
          this.city.setValue(res.city);
          this.person.setValue(res.contactPersonId);
        });
    }
  }

  onDelete(): void {
    this.organizationService.delete(this.organizationId).subscribe(
      () => {
        this.location.back();
      }, (error) => {
        this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
      }
    );
  }

  submit(): void {
    const organization = {
      name: this.name.value,
      type: this.organizationType.value,
      street: this.street.value,
      zip: this.zip.value,
      city: this.city.value,
      contactPersonId: this.person.value,
    };

    if (this.organizationId) {
      this.organizationService.update(organization, this.organizationId).subscribe(
        () => {
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    } else {
      this.organizationService.create(organization).subscribe(
        () => {
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    }
  }

  compareWith(item, selected): boolean {
    return item.id === selected.id;
  }

}
