<div *ngFor="let tournament of this.tournaments">
    <div class="grid grid-cols-2 gap-4 mb-3">
        <div>
            <h2 class="text-xl font-semibold text-gray-900 mb-2">{{tournament.name}}</h2>
        </div>
        <div *ngIf="tournament === this.tournaments[0]">
            <a class="btn btn-primary float-right" routerLink="/tournaments/{{tournament.id}}/teams/create" [queryParams]="{ personId: this.personId }">Add Discipline/Partner</a>
        </div>
    </div>
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul>
            <li *ngFor="let team of this.getTeamsByTournament(tournament.id)">
                <a routerLink="/tournaments/{{tournament.id}}/teams/edit/{{team.id}}"
                    class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                            <div class="text-sm leading-5 font-medium text-indigo-600 truncate">
                                {{ team.discipline | discipline }}
                            </div>
                            <div class="ml-2 flex-shrink-0 flex">

                            </div>
                        </div>
                        <div class="mt-2 sm:flex sm:justify-between">
                            <div class="sm:flex">
                                <div class="mr-6 flex items-center text-sm leading-5 text-gray-500"
                                    *ngIf="team.persons.length > 1">
                                    <img class="h-6 w-6 rounded-full mr-2" [src]="this.getAvatarOfPartnerFromPersons(team)" alt="Photo">
                                    Partner: {{ this.getNameOfPartnerFromPersons(team) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
    <p *ngIf="this.getTeamsByTournament(tournament.id).length == 0">
        No disciplines or partners found for this tournament.
    </p>
</div>