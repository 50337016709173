import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelRoomBookingService } from '@hotel/services/hotel-room-booking.service';
import { RoomTypeHelper } from '@hotel/helper/room-type.helper';

@Component({
  selector: 'app-hotel-room-booking-list',
  templateUrl: './hotel-room-booking-list.component.html',
  styleUrls: ['./hotel-room-booking-list.component.scss']
})
export class HotelRoomBookingListComponent implements OnInit {

  data = [];
  formatSettings = { type: 'date', format: 'dd.MM.yyyy' };
  tournamentId: string = null;

  constructor(
    private hotelRoomBookingService: HotelRoomBookingService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;

    this.hotelRoomBookingService.get(
      {
        join: ['hotel', 'tournament', 'assignments'],
        filter: [`tournamentId||$eq||${this.tournamentId}`]
      })
      .subscribe((res) => {
      this.data = res.map(booking => {
        const roomSize = RoomTypeHelper.getRoomSizeByType(booking.hotelRoomType);
        booking.startDate = new Date(booking.startDate);
        booking.endDate = new Date(booking.endDate);
        booking.bedroomSize = roomSize;
        booking.availableBeds = roomSize - booking.assignments?.length;
        return booking;
      }).sort((a, b) => {
        let roomIdentifierPartsA = a.roomIdentifier.split('_');
        let roomIdentifierPartsB = b.roomIdentifier.split('_');
        const partA = roomIdentifierPartsA.pop();
        const partB = roomIdentifierPartsB.pop();
        roomIdentifierPartsA = roomIdentifierPartsA.join('_');
        roomIdentifierPartsB = roomIdentifierPartsB.join('_');
        if (roomIdentifierPartsA < roomIdentifierPartsB) return -1;
        if (roomIdentifierPartsA > roomIdentifierPartsB) return 1;
        return Number(partA) - Number(partB);
      });
    });
  }

  onRowSelected(row): void {
    this.router.navigate(['edit', row.data.id], { relativeTo: this.route });
  }

  onRowDeleted(data: any): void {
    this.hotelRoomBookingService.delete(data.id)
      .subscribe((res) => {
        this.data = this.data.filter(x => x.id !== data.id);
      });
  }

}
