import { Pipe, PipeTransform } from '@angular/core';
import { DisciplineHelper } from '@team/components/helpers/discipline.helper';

@Pipe({
    name: 'discipline',
    pure: false
})
export class DisciplinePipe implements PipeTransform {

    constructor() { }

    transform(discipline: number): string {
        return DisciplineHelper.disciplines.filter(x => x.id === discipline)[0]?.name;
    }
}
