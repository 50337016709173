import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TournamentService } from '@tournament/services/tournament.service';

@Component({
  selector: 'app-tournament-list',
  templateUrl: './tournament-list.component.html',
  styleUrls: ['./tournament-list.component.scss']
})
export class TournamentListComponent implements OnInit {

  tournaments = [];

  constructor(private tournamentService: TournamentService,
              private router: Router,
              private route: ActivatedRoute, ) { }

  ngOnInit(): void {
    const isRedirectToLatest = this.route.snapshot.pathFromRoot.toString().includes('tournaments/latest');

    this.tournamentService.get({ sort: 'start,DESC' }).subscribe((res) => {

      if (isRedirectToLatest && res && res[0]) {
        this.router.navigate([`tournaments/${res[0].id}/dashboard`]);
      }

      this.tournaments = res;
    });
  }

}
