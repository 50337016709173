import { Component, OnInit } from '@angular/core';
import { Notification } from '@shared/interfaces/notification.interface';
import { NotificationService } from '@shared/services/notification.service';

@Component({
  selector: 'app-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss']
})
export class NotificationContainerComponent implements OnInit {

  notifications: Notification[] = [];

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.notificationService.getNotifications().subscribe((notification: Notification) => {
      // add notification to array
      this.notifications.push(notification);

      if (!notification.noAutoClose) {
        setTimeout(() => {
          const i = this.notifications.indexOf(notification);
          this.removeNotification(i);
        }, notification.lifeTime || 5000); // Close animation after 5 seconds
      }
    });

    this.notificationService.getClearNotificationsObservable().subscribe(() => {
        this.notifications = [];
    });
  }

  removeNotification(index: number): void {
    this.notifications.splice(index, 1);
  }

}
