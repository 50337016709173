import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BadgeService } from '@badge/services/badge.service';
import { TournamentService } from '@tournament/services/tournament.service';

@Component({
  selector: 'app-person-badge-list',
  templateUrl: './person-badge-list.component.html',
  styleUrls: ['./person-badge-list.component.scss']
})
export class PersonBadgeListComponent implements OnInit {

  personId = null;
  tournaments = [];
  badges = [];

  constructor(
    private route: ActivatedRoute,
    private tournamentService: TournamentService,
    private badgeService: BadgeService
  ) {
    this.personId = this.route.snapshot.params.personId;

    this.tournamentService.get({ sort: 'start,DESC' }).subscribe((res) => {
      this.tournaments = res;
    });

    this.badgeService.get({ join: ['accessTypes'], filter: `personId||eq||${this.personId}` }).subscribe((res) => {
      this.badges = res;
    });
  }

  ngOnInit(): void {
  }

  getBadgesByTournament(tournamentId: string): any[] {
    return this.badges.filter((badge) => badge.tournamentId === tournamentId);
  }

}
