import { HotelRoomType } from "@hotel/enums/hotel-room-type.enum";

export class RoomTypeHelper {
    public static getRoomSizeByType(roomType: HotelRoomType): number {
        switch (roomType) {
            case HotelRoomType.SINGLE:
                return 1;
            case HotelRoomType.TWIN:
                return 2;
            case HotelRoomType.TWOBEDS:
                return 2;
            case HotelRoomType.TRIPLEBEDS:
                return 3;
            default:
                throw new Error(`Unknown room type ${roomType}`);
        }
    }
}