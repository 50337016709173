<div class="grid grid-cols-2 gap-4 mb-3">
    <div>
        <h2 class="text-xl font-semibold text-gray-900 mb-2">Visa</h2>
    </div>
    <div>
        <a class="btn btn-primary float-right w-auto" [queryParams]="{ personId: this.personId }"
            routerLink="/visa/create">Add Visa</a>
    </div>
</div>

<div class="bg-white shadow overflow-hidden sm:rounded-md">
    <ul>
        <li *ngFor="let visa of this.visa">
            <a routerLink="/visa/edit/{{visa.id}}"
                class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                <div class="px-4 py-4 sm:px-6">
                    <div class="flex items-center justify-between">
                        <div class="text-sm leading-5 font-medium text-indigo-600 truncate">
                            Visa issued on {{visa.issueDate | date }}
                        </div>
                        <div class="ml-2 flex-shrink-0 flex">
                            <span *ngIf="!this.isExpired(visa.expirationDate)" class="badge badge-success">Valid</span>
                            <span *ngIf="this.isExpired(visa.expirationDate)" class="badge badge-danger">Expired</span>
                        </div>
                    </div>
                    <div class="mt-2 sm:flex sm:justify-between">
                        <div class="sm:flex">
                            <div class="mr-6 flex items-center text-sm leading-5 text-gray-500">
                                <i class="fas fa-passport mr-2"></i>
                                Passport Number: {{visa.passportNumber}}
                            </div>
                        </div>
                        <div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                            <i class="fas fa-calendar mr-2"></i>
                            <span>
                                Expiring on
                                {{visa.expirationDate | date}}
                            </span>
                        </div>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>

<p *ngIf="this.visa?.length == 0">
    No visas found for this person.
</p>