import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransportType } from '@transport/enums/transport-type.enum';
import { HotelRoomAssignmentService } from '@hotel/services/hotel-room-assignment.service';
import { TransportAssignmentService } from '@transport/services/transport-assignment.service';

enum listType {
  ARRIVAL,
  DEPARTURE
}

@Component({
  selector: 'app-transport-booking-arrival-list',
  templateUrl: './transport-booking-arrival-list.component.html',
  styleUrls: ['./transport-booking-arrival-list.component.scss']
})
export class TransportBookingArrivalListComponent implements OnInit {

  LIST_TYPE: listType = listType.ARRIVAL;
  listDate = new Date();
  tournamentId: string = null;
  TRANSPORT_TYPE = TransportType;
  data = [];
  timeFormatSettings = { type: 'date', format: 'HH:mm' };
  dateFormatSettings = { type: 'date', format: 'dd.MM.yyyy' };

  constructor(
    private transportAssignmentService: TransportAssignmentService,
    private hotelRoomAssignmentService: HotelRoomAssignmentService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
    this.fillList();
  }

  onRowSelected(event): void {
    this.router.navigate([`../transport_bookings/edit`, event.data.transportBooking.id], {relativeTo: this.route});
  }


  switchList(newListType: listType): void {
    this.LIST_TYPE = newListType;
    this.fillList();
  }

  fillList(newDate?: Date): void {
    if (newDate) {
      this.listDate = newDate;
    }

    const startDate = new Date(this.listDate.getFullYear(), this.listDate.getMonth(), this.listDate.getDate(), 24, 0, 0);
    const endDate = new Date(this.listDate.getFullYear(), this.listDate.getMonth(), this.listDate.getDate(), 24, 0, 0);
    startDate.setDate(startDate.getDate() - 1);

    this.populateData(startDate.toISOString(), endDate.toISOString());
  }

  async populateData(startDate, endDate): Promise<void> {
    this.data = [];

    const transports = await this.transportAssignmentService.get(
      {
        join: ['person', 'person.organization', 'transportBooking'],
        filter: [`transportBooking.tournamentId||$eq||${this.tournamentId}`,
        `transportBooking.homebound||$eq||${this.LIST_TYPE === 1 ? true : false}`,
        `transportBooking.transportDate||$gte||${startDate}`,
        `transportBooking.transportDate||$lt||${endDate}`],
        sort: [`transportBooking.transportDate,ASC`]
      })
      .toPromise();

    const hotelRoomReservations = await this.hotelRoomAssignmentService.get(
      {
        filter: [`tournamentId||$eq||${this.tournamentId}`],
      })
      .toPromise();

    this.data = transports.map(transport => {
      const hotels = hotelRoomReservations.filter(reservation => reservation.personId === transport.personId);
      transport.hotel = this.getHotelForTransport(hotels);
      transport.transportDate = new Date(transport.transportBooking.transportDate);
      transport.organizationName = transport.person.organization ? `${transport.person.organization.type} - ${transport.person.organization.name}` : '';
      return transport;
    });
    console.log(this.data)
  }

  private getHotelForTransport(hotels) {
    if (Array.isArray(hotels) && hotels.length > 0) {
      if (hotels.length > 1) {
        // This case should never happen, but in case it does we show a custom text
        return 'Multiple hotels';
      }
      return hotels[0].hotelRoomBooking.hotel.name;
      }
  }

}
