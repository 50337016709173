import { RoleIdentifier } from '@person/enums/role.identifier.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'personRole',
    pure: false
})
export class PersonRolePipe implements PipeTransform {
    personStrings = ['person', 'Person', 'persons', 'Persons'];
    roleStrings = [
        ['unregistered', 'Unregistered', 'unrigestered', 'Unregistered'],
        ['player', 'Player', 'players', 'Players'],
        ['coach', 'Coach', 'coaches', 'Coaches'],
        ['volunteer', 'Volunteer', 'volunteers', 'Volunteers'],
        ['line judge', 'Line judge', 'line judges', 'Line judges'],
        ['sponsor', 'Sponsor', 'sponsors', 'Sponsors'],
    ];

    constructor() { }

    transform(roleId: string, format?: 'capitalized' | 'lowerCase', plural?: boolean): string {
        const stringArray = roleId == null ? this.personStrings : this.roleStrings[this.getIndexOfRoleInEnum(roleId)];

        if (format !== 'capitalized' && !plural) {
            return stringArray[0];
        } else if (format === 'capitalized' && !plural) {
            return stringArray[1];
        } else if (format !== 'capitalized' && plural) {
            return stringArray[2];
        } else if (format === 'capitalized' && plural) {
            return stringArray[3];
        }
    }

    private getIndexOfRoleInEnum(roleId: string): number {
        return Object.values<string>(RoleIdentifier).indexOf(roleId);
    }
}
