<app-container>
  <app-header-delete [isDeleteButtonHidden]="!this.hotelRoomBookingId" (confirm)="this.onDelete()">Hotel room booking
  </app-header-delete>

  <app-alert *ngIf="this.error" type="error">
    {{error}}
  </app-alert>

  <h3 *ngIf="this.hotelRoomBookingId && this.hotelRoomBooking" class="text-lg font-semibold text-gray-900 my-5">Room
    identifier: {{hotelRoomBooking.roomIdentifier}}</h3>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">

    <div class="form-group" [class.d-none]="!!this.hotelRoomBookingId"
      [class.error]="amount.touched && amount.hasError('required')">
      <span class="label">Amount of rooms *</span>
      <p class="text-gray-500">Automatically generates and enumerates the amount of rooms using the data you enter
        below.</p>
      <input id="amount" class="form-control" type="text" formControlName="amount" name="amount" placeholder="1"
        required />

      <p class="error-message" id="HotelRoomBooking-amount-number-error">The amount is required.</p>
    </div>

    <div class="from-group" [class.error]="hotel.touched && hotel.hasError('required')">
      <span class="label">Hotel *</span>

      <ng-select [items]="this.hotels" [disabled]="true" bindValue="id" bindLabel="name" formControlName="hotel"
        placeholder="Select hotel">
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <span class="ng-value-label">{{ item.name }} {{ item.city }}</span>
        </ng-template>
      </ng-select>

      <p class="error-message" id="hotel-error">
        The hotel is required.</p>
    </div>

    <br>

    <div class="form-group" [class.error]="startDate.touched && startDate.hasError('required')">
      <span class="label">Start date *</span>
      <ejs-datetimepicker [firstDayOfWeek]="1" formControlName="startDate" name="startDate" format="dd.MM.yyyy HH:mm"
        [placeholder]="'dd.MM.yyyy HH:mm'"></ejs-datetimepicker>

      <p class="error-message" id="start-date-error">The start date is required.</p>
    </div>

    <div class="form-group" [class.error]="endDate.touched && endDate.hasError('required')">
      <span class="label">End date *</span>
      <ejs-datetimepicker [firstDayOfWeek]="1" formControlName="endDate" name="endDate" format="dd.MM.yyyy HH:mm"
        [placeholder]="'dd.MM.yyyy HH:mm'" [start]="this.selectedDateDefault"></ejs-datetimepicker>

      <p class="error-message" id="end-date-error">
        The end date is required.</p>
    </div>

    <div class="form-group">
      <span class="label">Bedroom type *</span>

      <div class="mt-1 relative">
        <div class="mt-4 flex items-center">
          <input id="bedroom-size-single" type="radio" name="hotelRoomType" [value]="hotelRoomTypeEnum.SINGLE"
            formControlName="hotelRoomType"
            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
          <label class="ml-3" for="bedroom-size-single">
            <span class="block text-sm leading-5 font-medium text-gray-700">{{hotelRoomTypeEnum.SINGLE}}</span>
          </label>
        </div>
        <div class="mt-4 flex items-center">
          <input id="bedroom-size-twin" type="radio" name="hotelRoomType" [value]="hotelRoomTypeEnum.TWIN"
            formControlName="hotelRoomType"
            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
          <label class="ml-3" for="bedroom-size-twin">
            <span class="block text-sm leading-5 font-medium text-gray-700">{{hotelRoomTypeEnum.TWIN}}</span>
          </label>
        </div>
        <div class="mt-4 flex items-center">
          <input id="bedroom-size-two" type="radio" name="hotelRoomType" [value]="hotelRoomTypeEnum.TWOBEDS"
            formControlName="hotelRoomType"
            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
          <label class="ml-3" for="bedroom-size-two">
            <span class="block text-sm leading-5 font-medium text-gray-700">{{hotelRoomTypeEnum.TWOBEDS}}</span>
          </label>
        </div>
        <div class="mt-4 flex items-center">
          <input id="bedroom-size-three" type="radio" name="hotelRoomType" [value]="hotelRoomTypeEnum.TRIPLEBEDS"
            formControlName="hotelRoomType"
            class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
          <label class="ml-3" for="bedroom-size-three">
            <span class="block text-sm leading-5 font-medium text-gray-700">{{hotelRoomTypeEnum.TRIPLEBEDS}}</span>
          </label>
        </div>
      </div>
    </div>

    <br />

    <div *ngIf="this.formGroup" formArrayName="assignments">
      <ng-container *ngFor="let assignment of this.assignments.controls; let i = index;">
        <hr>

        <div class="mt-2 mb-3 grid grid-cols-2 gap-4">
          <div>
            <h3 class="text-lg text-gray-900">Reservation {{i + 1}} {{this.hotelRoomBooking?.assignments[i]?.hasPaid ?
              '(Invoice paid)' : ''}}</h3>
          </div>
          <div>
            <app-button-delete (confirm)="this.onDeleteRoomAssignment(i)"
              [customClass]="'btn btn-danger-outline float-right'" [text]="'Remove'"></app-button-delete>
          </div>
        </div>

        <div class="grid grid-cols-3 gap-4" [formGroupName]="i">
          <div class="form-group"
            [class.error]="getAssignmentsPerson(i).touched && getAssignmentsPerson(i).hasError('required')">
            <span class="label">Person *</span>

            <ng-select [items]="this.persons" [virtualScroll]="true" formControlName="person" placeholder="Select a person" bindValue="id"
              bindLabel="nameWithBWFId" [searchFn]="this.onPersonSearch">
              <ng-template ng-option-tmp ng-label-tmp let-item="item">
                <div class="flex">
                  <img class="h-5 w-auto rounded-full mr-2" [src]="item.avatarUrl">
                  <span>{{ item.nameWithBWFId }}</span>
                </div>
              </ng-template>
            </ng-select>

            <p class="error-message" id="person-error">
              The person is required.
            </p>
          </div>

          <div class="form-group"
            [class.error]="getAssignmentsStartDate(i).touched && getAssignmentsStartDate(i).hasError('required')">
            <span class="label">Check In date *</span>
            <ejs-datepicker [firstDayOfWeek]="1" [openOnFocus]="true" formControlName="assignmentStartDate"
              format="dd.MM.yyyy" [placeholder]="'dd.MM.yyyy'" floatLabelType="Never" [min]="this.startDate.value"
              [max]="this.endDate.value">
            </ejs-datepicker>

            <p class="error-message" id="start-date-error">
              The start date is required.
            </p>
          </div>

          <div class="form-group"
            [class.error]="getAssignmentsEndDate(i).touched && getAssignmentsEndDate(i).hasError('required')">
            <span class="label">Check Out date *</span>
            <ejs-datepicker [firstDayOfWeek]="1" [openOnFocus]="true" formControlName="assignmentEndDate"
              format="dd.MM.yyyy" [placeholder]="'dd.MM.yyyy'" floatLabelType="Never" [min]="this.startDate.value"
              [max]="this.endDate.value">
            </ejs-datepicker>

            <p class="error-message" id="end-date-error">
              The end date is required.
            </p>
          </div>
        </div>
      </ng-container>

      <div *ngIf="this.hotelRoomBookingId && this.assignments.length < this.hotelRoomSize" class="mt-5">
        <button (click)="this.onAddRoomAssignment()" type="button" class="btn btn-primary-outline mb-2">
          Add Reservation
        </button>
        <hr class="mb-5">
      </div>

    </div>

    <div class="form-group">
      <span class="inline-flex rounded-md shadow-sm">
        <button backButton type="button" class="btn btn-white">
          Cancel
        </button>
        <button type="submit" [disabled]="!this.formGroup.valid" class="btn btn-primary-outline ml-2">
          {{ this.hotelRoomBookingId ? 'Update' : 'Create' }}
        </button>
      </span>
    </div>
  </form>

</app-container>