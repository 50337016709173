import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Person } from '@person/models/person.model';
import { CrudServiceOptions } from '@shared/interfaces/crud-service-options.interface';
import { RestCrudService } from '@shared/services/rest-crud.service';
import { environment } from 'environments/environment';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PersonService extends RestCrudService<Person> {
  private httpC: HttpClient;
  private endpoint;

  constructor(httpClient: HttpClient) {
    const options: CrudServiceOptions = {
      baseUrl: environment.api,
      resourceName: 'persons',
      getPipe: pipe(map((persons: Person[]) => {
        return persons.map((person: Person) => new Person(person));
      })),
      getOnePipe: pipe(map((person: Person) => {
        return new Person(person);
      })),
    };
    super(options, httpClient);
    this.httpC = httpClient;
  }

  uploadImage(avatarImage: File, resourceId: string): Promise<any> {
    this.endpoint = super.baseUrl + '/persons/upload';
    const formData = new FormData();
    formData.append('image', avatarImage);
    formData.append('personId', resourceId);
    return this.httpC.put<any>(this.endpoint, formData).toPromise();
  }
}
