import { Component, OnInit } from '@angular/core';
import { BadgeService } from '@badge/services/badge.service';
import { AccessTypeService } from '@badge/services/accessType.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements OnInit {
  scannerEnabled = false;
  accessTypes = [];
  badge = null;

  form = this.fb.group({
    checkArray: this.fb.array([])
  });

  selectedAccessTypeIds = [];


  constructor(
    private fb: UntypedFormBuilder,
    private badgeService: BadgeService,
    private accessTypeService: AccessTypeService,
  ) { }

  ngOnInit(): void {
    this.accessTypeService.get({ join: 'person' }).subscribe((res) => {
      this.accessTypes = res.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
    });
  }

  onCheckboxChange(e): void {
    const checkArray: UntypedFormArray = this.form.get('checkArray') as UntypedFormArray;

    if (e.target.checked) {
      checkArray.push(new UntypedFormControl(e.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: UntypedFormControl) => {
        if (item.value === e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }

    this.selectedAccessTypeIds = checkArray.value;
  }

  public selectedAccessTypes(): any {
    return this.selectedAccessTypeIds.map((a) => {
      return (this.accessTypes.find((at) => at.id === a) );
    });
  }

  public scanSuccessHandler($event: any): void {
    this.scannerEnabled = false;

    this.badgeService.getOne($event, { join: ['person', 'tournament', 'accessTypes'] }).subscribe((res) => {
      this.badge = res;
    });
  }

  public allowed(): boolean {
    const results = this.selectedAccessTypes().map((a) => {
      return this.checkAccessTypeInBadge(a);
    });

    return (!results.includes(false) && results.includes(true));
  }

  public allowedColor(): string {
    return this.allowed() ? 'green' : 'red';
  }

  public checkAccessTypeInBadge(accessType: any): any {
    return this.badge.accessTypes.map((a) => a.id).includes(accessType.id);
  }

  public close(): void {
    this.badge = null;
  }

  public enableScanner(): void {
    this.scannerEnabled = !this.scannerEnabled;
    this.close();
  }
}
