<div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
  <button
    class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden"
    aria-label="Open sidebar">
    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
    </svg>
  </button>

  <div class="flex-1 px-4 flex justify-between">
    <div class="flex px-2 lg:px-0">
      <div *ngIf="this.isLogoVisible" class="flex-shrink-0">
        <img class="block lg:hidden h-8 w-auto"
          src="https://www.swisstopsport.ch/pictures/86/leiy94sfotdao5dis1a0mm3b09a55e/swissopen_logo-01.svg"
          alt="logo">
        <img class="hidden lg:block h-8 w-auto mt-3"
          src="https://www.swisstopsport.ch/pictures/86/leiy94sfotdao5dis1a0mm3b09a55e/swissopen_logo-01.svg"
          alt="logo">
      </div>
      <div class="hidden lg:ml-6 lg:flex">
        <a *ngIf="this.latestTournament" routerLink="/tournaments/{{this.latestTournament.id}}/dashboard"
          class="ml-8 nav-item">
          {{this.latestTournament.name }}
        </a>
        <a routerLink="/tournaments" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
          class="ml-8 nav-item">
          Tournaments
        </a>
        <a routerLink="/persons" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
          class="ml-8 nav-item">
          Persons
        </a>
        <a routerLink="/hotels" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
          class="ml-8 nav-item">
          Hotels
        </a>
        <a routerLink="/organizations" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
          class="ml-8 nav-item">
          Organizations
        </a>
        <a routerLink="/visa" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
          class="ml-8 nav-item">
          Visas
        </a>
        <a routerLink="/access_types" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
          class="ml-8 nav-item">
          Access Types
        </a>
        <!-- <a routerLink="/scanner" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
          class="ml-8 nav-item">
          Scanner
        </a> -->
      </div>
    </div>
    <!-- <div class="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
      <div class="max-w-lg w-full lg:max-w-xs">
        <label for="search" class="sr-only">Search</label>
        <div class="relative">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
            </svg>
          </div>
          <input id="search" [disabled]="true" class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-blue-300 focus:ring-blue sm:text-sm transition duration-150 ease-in-out" placeholder="Search" type="search">
        </div>
      </div>
    </div> -->

    <div *ngIf="this.isLoggedIn()" class="ml-4 flex items-center md:ml-6">

      <div class="ml-3 relative" (click)="navbarUserPopup.hidden = !navbarUserPopup.hidden"
        (clickOutside)="navbarUserPopup.hidden = true">
        <div>
          <button
            class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
            id="user-menu" aria-label="User menu" aria-haspopup="true">
            <img class="h-8 w-8 rounded-full" [src]="this.userAvatarUrl" alt="logo">
          </button>
        </div>

        <div #navbarUserPopup class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg" [hidden]="true">
          <div class="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5">
            <a class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer"
              [routerLink]="['/account']">Settings</a>
            <a id="logout"
              class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer"
              (click)="this.logout()">Sign out</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>