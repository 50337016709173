import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ExcelExportService, PdfExportService } from '@syncfusion/ej2-angular-grids';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { PersonService } from '@person/services/person.service';

import { ContainerComponent } from './components/container/container.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { HeaderComponent } from './components/header/header.component';
import { CardComponent } from './components/card/card.component';
import { AlertComponent } from './components/alert/alert.component';
import { ButtonDeleteComponent } from './components/button-delete/button-delete.component';
import { TabNavigationComponent } from './components/tab-navigation/tab-navigation.component';
import { TabItemComponent } from './components/tab-item/tab-item.component';
import { HeaderDeleteComponent } from './components/header-delete/header-delete.component';
import { DateSliderComponent } from './components/date-slider/date-slider.component';
import { PersonSelectComponent } from './components/person-select/person-select.component';
import { PersonMultiSelectComponent } from './components/person-multi-select/person-multi-select.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { AvatarEditComponent } from './components/avatar-edit/avatar-edit.component';

import { BackButtonDirective } from './directives/back-button.directive';
import { DefaultGridSettingsDirective } from './directives/default-grid-settings.directive';

@NgModule({
  declarations: [
    ContainerComponent,
    DropdownComponent,
    AvatarComponent,
    AvatarEditComponent,
    HeaderComponent,
    CardComponent,
    AlertComponent,
    ButtonDeleteComponent,
    TabNavigationComponent,
    TabItemComponent,
    DefaultGridSettingsDirective,
    BackButtonDirective,
    HeaderDeleteComponent,
    DateSliderComponent,
    PersonSelectComponent,
    PersonMultiSelectComponent,
    LoadingSpinnerComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    NgSelectModule,
    ImageCropperModule,
    NgxDropzoneModule,
  ],
  exports: [
    ContainerComponent,
    DropdownComponent,
    AvatarComponent,
    AvatarEditComponent,
    HeaderComponent,
    CardComponent,
    AlertComponent,
    ButtonDeleteComponent,
    TabNavigationComponent,
    TabItemComponent,
    DefaultGridSettingsDirective,
    BackButtonDirective,
    HeaderDeleteComponent,
    DateSliderComponent,
    PersonSelectComponent,
    PersonMultiSelectComponent,
    LoadingSpinnerComponent,
  ],
  providers: [PdfExportService, ExcelExportService, PersonService]
})
export class SharedModule { }
