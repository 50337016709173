<app-header>Add {{personRoleId | personRole:null:true}}</app-header>

<app-container>
    <div class="p-3 bg-white shadow overflow-hidden sm:rounded-md">
        <input #searchInput class="form-control mt-1 block w-full" type="text" placeholder="Search a person by BWF ID or name"
            (keyup)="this.onSearch(searchInput.value)">
        <p class="mt-3 text-sm">
            Showing {{this.matchCount > this.amountOfResultsToShow ? this.amountOfResultsToShow : this.matchCount}} 
            of {{this.matchCount}} matching persons from master data
        </p>
    </div>

    <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul>
            <li *ngFor="let person of this.personList">
                <div class="block">
                    <div class="flex items-center px-4 py-4 sm:px-6">
                        <div class="min-w-0 flex-1 flex items-center">
                            <div class="flex-shrink-0">
                                <img class="h-10 w-10 rounded-full" [src]="person.avatarUrl" alt="Photo">
                            </div>
                            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                <div>
                                    <a class="text-sm leading-5 font-medium text-indigo-600 truncate" [routerLink]="['/persons', person.id]">
                                        {{person.nameWithBWFId}}
                                    </a>
                                    <div class="mt-2 flex items-center text-sm leading-5 text-gray-500">
                                        <!-- Heroicon name: mail -->
                                        <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path
                                                d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                        </svg>
                                        <span class="truncate">{{person.email}}</span>
                                    </div>
                                </div>
                                <div class="hidden md:block">
                                    <div>
                                        <div class="text-sm leading-5 text-gray-900">
                                            Country: {{person.countryCode}}, Language: {{person.languageCode}}
                                        </div>
                                        <div *ngIf="this.person.tournamentRoles && this.person.tournamentRoles[0]"
                                            class="mt-2 flex items-center text-sm leading-5 text-gray-500">
                                            Last Tournament: {{person.tournamentRoles[0].tournamentId | tournament }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button type="button" class="btn btn-primary-outline" (click)="this.onAddPerson(person)">
                                Add {{personRoleId | personRole}} to {{tournamentId | tournament}}
                            </button>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</app-container>