import { Person } from "@person/models/person.model";

export class Organization {
    id?: string;
    createdAt?: Date;
    updatedAt?: Date;
    deletedAt?: Date;
    name?: string;
    type?: string;
    contactPerson?: Person;

    get nameWithType(): string {
        return this.type ? `${this.type} - ${this.name}` : this.name;
    }

    public constructor(init?:Partial<Organization>) {
        Object.assign(this, init);
        if (init.contactPerson) {
            this.contactPerson = new Person(init.contactPerson);
        }
    }
}