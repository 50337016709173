import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  ngOnInit(): void {
    const localStorageVersion = localStorage.getItem(environment.localStorageKeys.appVersion);
    if (localStorageVersion != environment.ejsGridVersion.toString()) {
      localStorage.setItem(environment.localStorageKeys.appVersion, environment.ejsGridVersion.toString());
      this.resetEjsGridCache();
    }
  }

  resetEjsGridCache(): void {
    for (let item in localStorage) {
      if (item.startsWith('gridgrid') || item.startsWith('toolbargrid')) {
        localStorage.removeItem(item);
      }
    }
  }

}

