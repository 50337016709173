<app-container>
  <app-header-delete [isDeleteButtonHidden]="!this.teamId" (confirm)="this.onDelete()">Entry</app-header-delete>

  <app-alert *ngIf="this.error" type="error">
    {{error}}
  </app-alert>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">

    <div class="from-group">
      <span class="label">Discipline *</span>

      <ng-select [items]="this.disciplines" bindValue="id" formControlName="discipline"
        (change)="this.onDisciplineChanged($event)">
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="flex">
            <span>{{ item.name }}</span>
          </div>
        </ng-template>
      </ng-select>

      <p *ngIf="discipline.touched && discipline.hasError('required')" class="mt-2 text-sm text-red-600"
        id="discipline-error">
        The discipline is required.</p>
    </div>
    <br />

    <div class="from-group">
      <span class="label">First Player *</span>

      <ng-select [items]="this.playersFiltered" bindValue="id" bindLabel="nameWithBWFId" formControlName="player1"
        (change)="this.onPlayerChanged()">
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="flex">
            <img class="h-5 w-auto rounded-full" [src]="item.avatarUrl">
            <span class="ml-2">{{ item.nameWithBWFId }}</span>
          </div>
        </ng-template>
      </ng-select>

      <p *ngIf="player1.touched && player1.hasError('required')" class="mt-2 text-sm text-red-600" id="player1-error">
        The first player is required.</p>
    </div>
    <br />

    <div class="from-group" *ngIf="this.player2">
      <span class="label">Second Player *</span>

      <ng-select [items]="this.playersFiltered" bindValue="id" bindLabel="nameWithBWFId" formControlName="player2"
        (change)="this.onPlayerChanged()">
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="flex">
            <img class="h-5 w-auto rounded-full" [src]="item.avatarUrl">
            <span class="ml-2">{{ item.nameWithBWFId }}</span>
          </div>
        </ng-template>
      </ng-select>

      <p *ngIf="player2.touched && player2.hasError('required')" class="mt-2 text-sm text-red-600" id="player2-error">
        The second player is required.</p>
      <br />
    </div>

    <div class="form-group">
      <span class="text-gray-500 font-bold">World ranking</span>
      <div class="relative rounded-md shadow-sm">
        <input id="worldRanking" class="form-control block w-full" type="number" formControlName="worldRanking"
          name="worldRanking" placeholder="46.5" />
      </div>
    </div>
    <br />

    <div class="form-group">
      <span class="inline-flex rounded-md shadow-sm">
        <button backButton type="button" class="btn btn-white">
          Cancel
        </button>
        <button type="submit" [disabled]="!this.formGroup.valid" class="btn btn-primary-outline ml-2">
          {{ this.teamId ? 'Update' : 'Create' }}
        </button>
      </span>
    </div>
  </form>

</app-container>