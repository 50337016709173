<!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
<div class="hidden"> <!-- was md:hidden -->
  <div class="fixed inset-0 flex z-40">
    <div class="fixed inset-0">
      <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
    </div>

    <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
      <div class="absolute top-0 right-0 -mr-14 p-1">
        <button class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600" aria-label="Close sidebar">
          <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div class="flex-shrink-0 flex items-center px-4">
        <img class="h-8 w-auto" src="https://www.swisstopsport.ch/pictures/86/leiy94sfotdao5dis1a0mm3b09a55e/swissopen_logo-01.svg" alt="Workflow">
        <div class="h-8 w-auto pt-2 pl-4 font-bold">Web</div>
      </div>

      <div class="mt-5 flex-1 h-0 overflow-y-auto">
        <nav class="px-2 space-y-1">
          <a [routerLink]="['/']" routerLinkActive="active" class="group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-900 rounded-md bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150">
            <svg class="mr-4 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
            Dashboard
          </a>
        </nav>
      </div>
    </div>
    <div class="flex-shrink-0 w-14">
      <!-- Dummy element to force sidebar to shrink to fit close icon -->
    </div>
  </div>
</div>

<!-- Static sidebar for desktop -->
<div class="hidden md:flex md:flex-shrink-0">
  <div class="flex flex-col w-64">

    <div class="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto">
      <div class="flex items-center flex-shrink-0 px-4">
        <img class="h-8 w-auto" src="https://www.swisstopsport.ch/pictures/86/leiy94sfotdao5dis1a0mm3b09a55e/swissopen_logo-01.svg" alt="Workflow">
        <div class="h-8 w-auto pt-2 pl-4 font-bold">Web</div>
      </div>

      <div class="mt-5 flex-grow flex flex-col">
        <nav class="flex-1 px-2 bg-white space-y-1">
          <h2 class="px-3 pt-7 text-sm leading-4 text-gray-600 uppercase font-bold">{{tournamentName}}</h2>

          <a [routerLink]="['./dashboard']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-home"></i>
            Dashboard
          </a>

          <a [routerLink]="['./registrations']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-user-plus"></i>
            Registrations
          </a>

          <h3 class="nav-title">Tournament participants</h3>

          <a [routerLink]="['./participants']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-street-view"></i>
            All participants
          </a>

          <a [routerLink]="['./players']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-street-view"></i>
            Players
          </a>

          <a [routerLink]="['./teams']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-user-friends"></i>
            Entries
          </a>

          <a [routerLink]="['./coaches']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-user"></i>
            Coaches
          </a>

          <a [routerLink]="['./volunteers']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-hands-helping"></i>
            Volunteers
          </a>

          <h3 class="nav-title">Travel</h3>

          <a [routerLink]="['./hotel_room_bookings']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-door-open"></i>
            Hotel booking overview
          </a>

          <a [routerLink]="['./hotel_room_assignments']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-door-open"></i>
            Hotel room reservations
          </a>

          <a [routerLink]="['./transport_bookings']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-plane"></i>
            Transports
          </a>

          <a [routerLink]="['./arrival_list']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-address-book"></i>
            Arrivals and departures
          </a>

          <h3 class="nav-title">Organization</h3>

          <a [routerLink]="['./badges']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-id-card"></i>
            Badges
          </a>


          <a [routerLink]="['./volunteer_time_planning']" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-clock"></i>
            Volunteers time planning
          </a>

          <h3 class="nav-title">Tournament</h3>

          <!-- <a hidden [routerLink]="['./history']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-clock"></i>
            History
          </a> -->

          <a [routerLink]="['./roles']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-user-tag"></i>
            Roles
          </a>

          <a [routerLink]="['./']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" class="nav-item">
            <i class="icon fas fa-cog"></i>
            Settings
          </a>

        </nav>
      </div>
    </div>
  </div>
</div>
