import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';

import { FilterService, GridModule, GroupService, PageService, SearchService, SortService, ToolbarService, ColumnChooserService, ResizeService } from '@syncfusion/ej2-angular-grids';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgSelectModule } from '@ng-select/ng-select';

import { HotelListComponent } from './components/hotel-list/hotel-list.component';
import { HotelEditComponent } from './components/hotel-edit/hotel-edit.component';

import { HotelRoomAssignmentListComponent } from './components/hotel-room-assignment-list/hotel-room-assignment-list.component';
import { HotelRoomAssignmentEditComponent } from './components/hotel-room-assignment-edit/hotel-room-assignment-edit.component';

import { HotelRoomBookingListComponent } from './components/hotel-room-booking-list/hotel-room-booking-list.component';
import { HotelRoomBookingEditComponent } from './components/hotel-room-booking-edit/hotel-room-booking-edit.component';
import { HotelConflictsComponent } from './components/hotel-conflicts/hotel-conflicts.component';

@NgModule({
  declarations: [
    HotelListComponent,
    HotelEditComponent,
    HotelRoomAssignmentListComponent,
    HotelRoomAssignmentEditComponent,
    HotelRoomBookingEditComponent,
    HotelRoomBookingListComponent,
    HotelConflictsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    GridModule,
    DatePickerModule,
    DateTimePickerModule,
    NgSelectModule,
  ],
  providers: [
    PageService,
    SortService,
    FilterService,
    GroupService,
    SearchService,
    ToolbarService,
    ColumnChooserService,
    ResizeService,
  ],
})
export class HotelModule { }
