import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';

import { FilterService, GridModule, GroupService, PageService, SearchService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgSelectModule } from '@ng-select/ng-select';

import { TournamentListComponent } from './components/tournament-list/tournament-list.component';
import { TournamentEditComponent } from './components/tournament-edit/tournament-edit.component';
import { TournamentPipe } from './pipes/tournament.pipe';
import { RoleEditComponent } from './components/role-edit/role-edit.component';
import { HistoryListComponent } from './components/history-list/history-list.component';

@NgModule({
  declarations: [TournamentListComponent, TournamentEditComponent, TournamentPipe, RoleEditComponent, HistoryListComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    GridModule,
    DatePickerModule,    
    SweetAlert2Module,
    NgSelectModule,
  ],
  providers: [PageService, SortService, FilterService, GroupService, SearchService, ToolbarService],
  exports: [TournamentPipe]
})
export class TournamentModule { }
