import { Gender } from '@person/enums/gender.enum';
import { Discipline } from '@team/enums/discipline.enum';

export class DisciplineHelper {

    public static get disciplines(): DisciplineType[] {
        return [
            { id: Discipline.MEN_SINGLE, name: 'men single', abbreviation: 'MS', teamSize: 1, gender: Gender.MALE },
            { id: Discipline.WOMEN_SINGLE, name: 'women single', abbreviation: 'WS', teamSize: 1, gender: Gender.FEMALE },
            { id: Discipline.MEN_DOUBLE, name: 'men double', abbreviation: 'MD', teamSize: 2, gender: Gender.MALE },
            { id: Discipline.WOMEN_DOUBLE, name: 'women double', abbreviation: 'WD', teamSize: 2, gender: Gender.FEMALE },
            { id: Discipline.MIXED_DOUBLE, name: 'mixed double', abbreviation: 'XD', teamSize: 2 },
          ];
    }

    public static getDisciplineAbbreviation(discipline: Discipline): string {
        return DisciplineHelper.disciplines.filter(x => x.id === discipline)[0]?.abbreviation;
    }
}

export interface DisciplineType {
    id: number;
    name: string;
    abbreviation: string,
    teamSize: number;
    gender?: Gender;
    disabled?: boolean;
}
