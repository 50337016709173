export const environment = {
    production: false,
  api: 'https://test.api.swissopen.alpalix.com',
    authApi: 'https://test.api.swissopen.alpalix.com',
    authWhiteList: [
      '/refresh',
      '/login',
      '/login/verify'
    ],
    skipAuthRedirect: [
      '/users/me/two_factor/confirmation'
    ],
    // property to force a cache reset of the ejs grid settings saved in the local storage
    // (must be deleted after a structural change of a grid (e.g. new column) to prevent visual bugs)
    ejsGridVersion: 7,
    localStorageKeys: {
      accessToken: 'accessToken',
      refreshToken: 'refreshToken',
      accessTokenExpiresAt: 'accessTokenExpiresAt',
      refreshTokenExpiresAt: 'refreshTokenExpiresAt',
      twoFactorNonce: 'twoFactorNonce',
      appVersion: 'appVersion',
    }
  };
