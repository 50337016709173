<app-container>
  <app-header-delete [isDeleteButtonHidden]="!this.visaId" (confirm)="this.onDelete()">Visa</app-header-delete>

    <app-alert *ngIf="this.error" type="error">
        {{error}}
    </app-alert>

    <form [formGroup]="formGroup" (ngSubmit)="submit()">

        <div class="form-group">
          <span class="label">Passport number</span>
          <input id="passportNumber" class="form-control" type="text" formControlName="passportNumber" name="passportNumber" placeholder="X123123123" />
        </div>

        <div class="form-group">
          <span class="label">Issue date</span>
          <ejs-datepicker class="" [openOnFocus]="true" formControlName="issueDate" name="issueDate" format="dd.MM.yyyy" [placeholder]="'Issue date'" floatLabelType='Never'></ejs-datepicker>
        </div>

        <div class="form-group">
          <span class="label">Expiration date</span>
          <ejs-datepicker class="" [openOnFocus]="true" formControlName="expirationDate" name="expirationDate" format="dd.MM.yyyy" [placeholder]="'Expiration date'" floatLabelType='Never'></ejs-datepicker>

        </div>

        <div class="form-group">
          <span class="label">Letter send-date</span>
          <ejs-datepicker class="" [openOnFocus]="true" formControlName="letterSentAt" name="letterSentAt" format="dd.MM.yyyy" [placeholder]="'Letter send-date'" floatLabelType='Never'></ejs-datepicker>
        </div>

        <div class="from-group">
            <span class="label">Person *</span>

            <app-person-select [formGroup]="this.formGroup" formControlName="person"></app-person-select>

            <p *ngIf="person.touched && person.hasError('required')" class="mt-2 text-sm text-red-600"
                id="person-error">
                The person is required.</p>
        </div>
        <br/>

        <div class="form-group">
            <span class="inline-flex rounded-md shadow-sm">
                <button type="button" [routerLink]="this.visaId ? ['../../'] : '..'"
                    class="btn btn-white">
                    Cancel
                </button>
                <button type="submit" [disabled]="!this.formGroup.valid" class="btn btn-primary-outline ml-2">
                    {{ this.visaId ? 'Update' : 'Create' }}
                </button>
            </span>
        </div>
    </form>

</app-container>
