import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { VisaService } from '@visa/services/visa.service';
import { I18nHelper } from '@shared/helpers/i18n.helper';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';
import { Country } from '@shared/interfaces/country.interface';

@Component({
  selector: 'app-visa-edit',
  templateUrl: './visa-edit.component.html',
  styleUrls: ['./visa-edit.component.scss']
})
export class VisaEditComponent implements OnInit {

  error = null;
  visaId = null;
  formGroup = this.fb.group({
    passportNumber: ['', []],
    issueDate: ['', []],
    expirationDate: ['', []],
    person: [null, [Validators.required]],
    letterSentAt: [''],
  });
  countries: Country[] = I18nHelper.getAllCountries();

  get passportNumber(): AbstractControl {
    return this.formGroup.get('passportNumber');
  }

  get issueDate(): AbstractControl {
    return this.formGroup.get('issueDate');
  }

  get expirationDate(): AbstractControl {
    return this.formGroup.get('expirationDate');
  }

  get letterSentAt(): AbstractControl {
    return this.formGroup.get('letterSentAt');
  }

  get person(): AbstractControl {
    return this.formGroup.get('person');
  }

  get personId(): AbstractControl {
    return this.formGroup.get('personId');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private visaService: VisaService) {
      this.visaId = this.route.snapshot.params.visaId;
      if (this.visaId) {
        this.visaService.getOne(this.visaId)
          .subscribe((res) => {
            this.passportNumber.setValue(res.passportNumber);
            this.issueDate.setValue(res.issueDate);
            this.expirationDate.setValue(res.expirationDate);
            this.person.setValue(res.personId);
            this.letterSentAt.setValue(res.letterSentAt);
          });
    }

      const personId = this.route.snapshot.queryParams.personId;
      if (personId) {
      this.person.setValue(personId);
    }
  }

  ngOnInit(): void { }

  onDelete(): void {
    this.visaService.delete(this.visaId).subscribe(
      () => {
        this.location.back();
      }, (error) => {
        this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
      }
    );
  }

  submit(): void {
    const visa = {
      passportNumber: this.passportNumber.value,
      issueDate: this.issueDate.value,
      expirationDate: this.expirationDate.value,
      personId: this.person.value,
      letterSentAt: this.letterSentAt.value,
    };

    if (this.visaId) {
      this.visaService.update(visa, this.visaId).subscribe(
        () => {
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    } else {
      this.visaService.create(visa).subscribe(
        () => {
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    }
  }

}
