import { Injectable } from '@angular/core';
import { TournamentService } from './tournament.service';

@Injectable({
  providedIn: 'root'
})
export class TournamentCacheService {
  private tournaments = [];

  constructor(private tournamentService: TournamentService) { }

  async loadTournaments(): Promise<void> {
    this.tournaments = await this.tournamentService.get().toPromise();
  }

  async getTournaments(): Promise<any[]> {
    if (this.tournaments.length === 0) {
      await this.loadTournaments();
    }

    return this.tournaments;
  }

  async getTournament(tournamentId: string): Promise<any> {
    const tournaments = await this.getTournaments();
    return tournaments.filter(tournament => tournament.id === tournamentId)[0];
  }
}
