import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';
import { TournamentService } from '@tournament/services/tournament.service';

@Component({
  selector: 'app-tournament-edit',
  templateUrl: './tournament-edit.component.html',
  styleUrls: ['./tournament-edit.component.scss']
})
export class TournamentEditComponent implements OnInit {

  error = null;
  tournamentId = null;
  formGroup = this.fb.group({
    name: ['', [Validators.required]],
    start: ['', [Validators.required]],
    end: ['', [Validators.required]],
  });

  get name(): AbstractControl {
    return this.formGroup.get('name');
  }

  get start(): AbstractControl {
    return this.formGroup.get('start');
  }

  get end(): AbstractControl {
    return this.formGroup.get('end');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private tournamentService: TournamentService) {
    this.tournamentId = this.route.snapshot.params.tournamentId;
    if (this.tournamentId) {
      this.tournamentService.getOne(this.tournamentId)
        .subscribe((res) => {
          this.name.setValue(res.name);
          this.start.setValue(res.start);
          this.end.setValue(res.end);
        });
    }
  }

  ngOnInit(): void { }

  submit(): void {
    const tournament = {
      name: this.name.value,
      start: this.start.value,
      end: this.end.value
    };

    if (this.tournamentId) {
      this.tournamentService.update(tournament, this.tournamentId)
        .subscribe(() => {
          this.router.navigate(['/tournaments']);
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        });
    } else {
      this.tournamentService.create(tournament)
        .subscribe(() => {
          this.router.navigate(['/tournaments']);
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        });
    }
  }

}
