import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organization } from '@organization/models/organization.model';
import { CrudServiceOptions } from '@shared/interfaces/crud-service-options.interface';
import { RestCrudService } from '@shared/services/rest-crud.service';
import { environment } from 'environments/environment';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends RestCrudService<any> {
  constructor(httpClient: HttpClient) {
    const options: CrudServiceOptions = {
      baseUrl: environment.api,
      resourceName: 'organizations',
      getPipe: pipe(map((organizations: Organization[]) => {
        return organizations.map((organization: Organization) => new Organization(organization));
      })),
      getOnePipe: pipe(map((organization: Organization) => {
        return new Organization(organization);
      })),
    };
    super(options, httpClient);
  }
}
