import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { NavigationComponent} from './components/navigation/navigation.component';
import { NavbarComponent } from './components/navbar/navbar.component';

import { AuthModule } from '@auth/auth.module';

import { NgClickOutsideModule } from 'ng-click-outside2';
import { SidebarComponent } from './components/sidebar/sidebar.component';

@NgModule({
  declarations: [
    NavbarComponent,
    NavigationComponent,
    SidebarComponent,
  ],
  imports: [
    AuthModule,
    SharedModule,
    CommonModule,
    BrowserModule,
    RouterModule,
    NgClickOutsideModule
  ],
  exports: [
    NavbarComponent,
    NavigationComponent,
    SidebarComponent
  ],
})
export class NavigationModule {}
