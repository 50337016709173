import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-button-delete',
  templateUrl: './button-delete.component.html',
  styleUrls: ['./button-delete.component.scss']
})
export class ButtonDeleteComponent implements OnInit {

  @Input() customClass = 'btn btn-danger-outline';
  @Input() text = 'Delete';

  @Output() confirm: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  swalOptions: SweetAlertOptions = {
    title: 'Are you sure?',
    text: 'Do you really want to delete this entry?',
    icon: 'warning',
    showCancelButton: true,
    buttonsStyling: false,
    reverseButtons: true,
    confirmButtonText: 'Delete',
    customClass: {
      confirmButton: 'btn btn-primary ml-3',
      cancelButton: 'btn btn-secondary',
    },
  };

  constructor() { }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.confirm.emit();
  }

  onCancel(): void {
    this.cancel.emit();
  }

}
