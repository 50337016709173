<app-container>
    <app-header-delete [isDeleteButtonHidden]="!this.hotelId" (confirm)="this.onDelete()">Hotel</app-header-delete>

    <app-alert *ngIf="this.error" type="error">
        {{error}}
    </app-alert>

    <form [formGroup]="formGroup" (ngSubmit)="submit()">
        <div class="d-block">
            <span class="text-gray-500 font-bold">Hotel name *</span>
            <div class="mt-1 relative rounded-md shadow-sm">
                <input id="name" class="form-control mt-1 block w-full" type="text" formControlName="name" name="name"
                    placeholder="Balade Hotel" required />
            </div>

            <p *ngIf="name.touched && name.hasError('required')" class="mt-2 text-sm text-red-600"
                id="hotel-name-error">
                The hotel name is required.</p>
        </div>
        <br />

        <div class="d-block">
            <span class="text-gray-500 font-bold">Address *</span>
            <div class="mt-1 relative rounded-md shadow-sm">
                <textarea id="address" class="form-control mt-1 block w-full" type="text" formControlName="address"
                    name="address" placeholder="" required></textarea>
            </div>

            <p *ngIf="address.touched && address.hasError('required')" class="mt-2 text-sm text-red-600"
                id="hotel-address-error">
                The hotel address is required.</p>
        </div>
        <br />

        <div class="d-block">
            <span class="text-gray-500 font-bold">Postalcode *</span>
            <div class="mt-1 relative rounded-md shadow-sm">
                <input id="postalcode" class="form-control mt-1 block w-full" type="text" formControlName="postalcode"
                    name="postalcode" placeholder="" required />
            </div>

            <p *ngIf="postalcode.touched && postalcode.hasError('required')" class="mt-2 text-sm text-red-600"
                id="hotel-postalcode-error">
                The postalcode is required.</p>
        </div>
        <br />

        <div class="d-block">
            <span class="text-gray-500 font-bold">City *</span>
            <div class="mt-1 relative rounded-md shadow-sm">
                <input id="city" class="form-control mt-1 block w-full" type="text" formControlName="city" name="city"
                    placeholder="" required />
            </div>

            <p *ngIf="city.touched && city.hasError('required')" class="mt-2 text-sm text-red-600"
                id="hotel-city-error">
                The city is required.</p>
        </div>
        <br />

        <div class="d-block">
            <span class="text-gray-500 font-bold">Room booking prefix *</span>
            <p class="text-gray-500">Prefix that will be used to create identifiers (room numbers) for room bookings.</p>
            <div class="mt-1 relative rounded-md shadow-sm">
                <input id="roomBookingPrefix" class="form-control mt-1 block w-full" type="text" formControlName="roomBookingPrefix" name="roomBookingPrefix"
                    placeholder="Balade" required />
            </div>

            <p *ngIf="roomBookingPrefix.touched && roomBookingPrefix.hasError('required')" class="mt-2 text-sm text-red-600"
                id="room-booking-prefix-error">
                The room booking prefix is required.</p>
        </div>
        <br />

        <input id="countryCode" class="form-control mt-1 block w-full" type="hidden" formControlName="countryCode"
            name="countryCode" placeholder="" required />



        <div class="d-block">
            <span class="inline-flex rounded-md shadow-sm">
                <button backButton type="button" class="btn btn-white">
                    Cancel
                </button>
                <button type="submit" [disabled]="!this.formGroup.valid" class="btn btn-primary ml-2">
                    {{ this.hotelId ? 'Update' : 'Create' }}
                </button>
            </span>
        </div>
    </form>

</app-container>