<app-container [hasFixedWidth]="false">
  <form [formGroup]="formGroup" class="inline-flex"> 
    <label for="historyEntityType" class="mr-2 flex-none self-center">Changes for</label>
    <ng-select #historyEntityType formControlName="historyEntityType" [searchable]="false" [clearable]="false">
      <ng-option [value]="1">Hotel Room Bookings</ng-option>
      <ng-option [value]="2">Hotel Room Reservations</ng-option>
    </ng-select>
    <label for="historyChangesSince" class="ml-4 mr-2 flex-none self-center">Changes since</label>
    <ejs-datepicker #historyChangesSince class="mt-1 block w-full" [openOnFocus]="true" formControlName="historyChangesSince"
      [firstDayOfWeek]="1" [start]="'Day'" name="historyChangesSince" format="dd.MM.yyyy" [placeholder]="'dd.MM.yyyy'"
      floatLabelType='Never'>
    </ejs-datepicker>
    <button type="button" class="btn btn-primary ml-4" (click)="this.onSearch()">Search</button>
  </form>
  <br>
  <br>
  <hr>
  <br>
  <ng-container *ngIf="this.data">
    <ejs-grid #grid defaultGridSettings [dataSource]='data'>
    </ejs-grid>
  </ng-container>
</app-container>