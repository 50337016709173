import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoleIdentifier } from '@person/enums/role.identifier.enum';

@Injectable({
  providedIn: 'root'
})
export class PersonRoleHelperService {

  constructor(private router: Router) { }

  getRoleByRoute(): RoleIdentifier {
    if (this.router.url.includes('players')) {
      return RoleIdentifier.PLAYER;
    } else if (this.router.url.includes('coaches')) {
      return RoleIdentifier.COACH;
    } else if (this.router.url.includes('volunteers')) {
      return RoleIdentifier.VOLUNTEER;
    } else if (this.router.url.includes('line_judges')) {
      return RoleIdentifier.LINE_JUDGE;
    } else if (this.router.url.includes('sponsors')) {
      return RoleIdentifier.SPONSOR;
    }

    return null;
  }
}
