<app-container>
  <app-header-delete [isDeleteButtonHidden]="!this.accessTypeId" (confirm)="this.onDelete()">Access Type
  </app-header-delete>

  <app-alert *ngIf="this.error" type="error">
    {{error}}
  </app-alert>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <div class="form-group" [class.error]="name.touched && name.hasError('required')">
      <span class="label">Name *</span>
      <input id="name" class="form-control" type="text" formControlName="name" name="name" placeholder="Green" required />

      <p class="error-message" id="accessType-name-error">
        The name is required.</p>
    </div>


    <div class="form-group" [class.error]="code.touched && code.hasError('required')">
      <span class="label">Code *</span>
      <input id="code" class="form-control" type="text" formControlName="code" name="code" placeholder="G" required />

      <p class="error-message" id="accessType-code-error">
        The code is required.</p>
    </div>

    <div class="form-group" [class.error]="color.touched && (color.hasError('required') || color.hasError('pattern'))">
      <span class="label">Color (Hex Code Format) *</span>
      <input id="color" class="form-control" type="text" formControlName="color" name="color" placeholder="#0f0f0f" required />

      <p class="error-message" id="accessType-color-error">
        The color must be a valid hex color code.</p>
    </div>

    <div class="form-group">
      <span class="inline-flex rounded-md shadow-sm">
        <button backButton type="button" class="btn btn-white">
          Cancel
        </button>
        <button type="submit" [disabled]="!this.formGroup.valid" class="btn btn-primary-outline ml-2">
          {{ this.accessTypeId ? 'Update' : 'Create' }}
        </button>
      </span>
    </div>
  </form>

</app-container>