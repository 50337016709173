import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TournamentService } from '@tournament/services/tournament.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  tournamentName = '';

  constructor(
    private tournamentService: TournamentService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    const tournamentId = this.route.snapshot.params.tournamentId;
    this.tournamentService.getOne(tournamentId).subscribe((res => {
      this.tournamentName = res.name;
    }));
  }

}
