import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoleIdentifier } from '@person/enums/role.identifier.enum';
import { NotificationService } from '@shared/services/notification.service';
import { RoleService } from '@tournament/services/role.service';
import { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditComponent implements OnInit {

  roles: any = [];
  isCreateInputVisible = false;
  tournamentId: string = null;

  swalOptions: SweetAlertOptions = {
    title: 'Are you sure?',
    text: 'Do you really want to delete this role? All persons assigned to this role will lose it.',
    icon: 'warning',
    showCancelButton: true,
    buttonsStyling: false,
    reverseButtons: true,
    confirmButtonText: 'Delete',
    customClass: {
      confirmButton: 'btn btn-primary ml-3',
      cancelButton: 'btn btn-secondary',
    },
  };

  constructor(
    private roleService: RoleService,
    private notificationService: NotificationService,
    private route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.tournamentId = this.route.snapshot.parent.params.tournamentId;
    await this.loadRoles();
  }

  async loadRoles(): Promise<any> {
    this.roles = await this.roleService.get(
      {
        filter: [`tournamentId||$eq||${this.tournamentId}`],
        or: `tournamentId||$isnull`,
        sort: 'createdAt,ASC'
      })
      .toPromise();
  }

  isAStandardRole(roleId: string): boolean {
    return Object.values<string>(RoleIdentifier).includes(roleId);
  }

  addRole(): void {
    this.roles.push({ name: '', tournamentId: this.tournamentId });
  }

  async saveRoles(): Promise<any> {
    const promises = [];
    this.roles.forEach(role => {
      if (this.isAStandardRole(role.id)) {
        return;
      }

      if (role.id == null) {
        promises.push(this.createRole(role));
      } else {
        promises.push(this.updateRole(role));
      }
    });

    Promise.all(promises)
      .then(async _ => {
        this.notificationService.success('Roles have been updated');
        await this.loadRoles();
      })
      .catch(async _ => {
        this.notificationService.error('An error occurred. Please try again.');
        await this.loadRoles();
      });
  }

  async updateRole(role: any): Promise<any> {
    return await this.roleService.update(role, role.id).toPromise();
  }

  async createRole(role: any): Promise<any> {
    return await this.roleService.create(role).toPromise();
  }

  async removeRole(role: any, index: number): Promise<any> {
    if (role.id) {
      this.roleService.delete(role.id).subscribe((res) => {
        this.notificationService.success('Role has been removed');
      },
        (err) => {
          this.notificationService.error('An error occurred. Please try again.');
        });
    }
    this.roles.splice(index, 1);
  }

}
