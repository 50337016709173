import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { TransportBookingService } from '@transport/services/transport-booking.service';
import { TournamentCacheService } from '@tournament/services/tournament-cache.service';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';
import { TransportTypeHelper } from '@transport/helpers/transport-type.helper';
import { Person } from '@person/models/person.model';
import { PersonService } from '@person/services/person.service';
import { TransportAssignmentService } from '@transport/services/transport-assignment.service';

@Component({
  selector: 'app-transport-booking-edit',
  templateUrl: './transport-booking-edit.component.html',
  styleUrls: ['./transport-booking-edit.component.scss']
})
export class TransportBookingEditComponent implements OnInit {

  error = null;
  tournamentId: string = null;
  transportBookingId: string = null;
  transportBookingAssignments = [];
  formGroup = this.fb.group({
    origin: ['', []],
    destination: ['', []],
    transportDate: ['', []],
    homebound: ['', []],
    transportNumber: [''],
    persons: [[], [Validators.required]],
    type: [null, [Validators.required]],
  });
  types = TransportTypeHelper.getTypes();
  selectedTransportDate = new Date();

  get origin(): AbstractControl {
    return this.formGroup.get('origin');
  }

  get destination(): AbstractControl {
    return this.formGroup.get('destination');
  }

  get transportDate(): AbstractControl {
    return this.formGroup.get('transportDate');
  }

  get homebound(): AbstractControl {
    return this.formGroup.get('homebound');
  }

  get transportNumber(): AbstractControl {
    return this.formGroup.get('transportNumber');
  }

  get persons(): AbstractControl {
    return this.formGroup.get('persons');
  }

  get type(): AbstractControl {
    return this.formGroup.get('type');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private transportAssignmentService: TransportAssignmentService,
    private transportBookingService: TransportBookingService,
    private tournamentCacheService: TournamentCacheService,
    private personService: PersonService) {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
    this.transportBookingId = this.route.snapshot.params.bookingId;
  }

  async ngOnInit(): Promise<void> {
    const currentTournament = await this.tournamentCacheService.getTournament(this.tournamentId);
    this.selectedTransportDate = currentTournament ? currentTournament.start : new Date();

    if (this.transportBookingId) {
      this.transportBookingService.getOne(this.transportBookingId, { join: 'assignments' })
        .subscribe((res) => {
          this.origin.setValue(res.origin);
          this.destination.setValue(res.destination);
          this.transportDate.setValue(res.transportDate);
          this.homebound.setValue(res.homebound);
          this.transportNumber.setValue(res.transportNumber);
          this.type.setValue(res.transportType);
          this.persons.setValue(res.assignments.map(x => x.personId));
          this.transportBookingAssignments = res.assignments;
        });
    }

    const personId = this.route.snapshot.queryParams.personId;
    if (personId) {
      this.persons.setValue([personId]);
    }
  }

  fetchTransportDate() {
    // Only on create
    if (!this.transportBookingId) {
      if (this.persons.getRawValue()) {
        this.personService.getOne(this.persons.getRawValue()[0])
          .subscribe((selectedPerson: Person) => {
            if (selectedPerson) {
              // Filter Tournament Fields to only active tournament
              selectedPerson.tournamentFields = selectedPerson.tournamentFields.filter(tournamentRole => this.tournamentId === tournamentRole.tournamentId);
              if (selectedPerson.tournamentFields.length === 1) {
                if (this.homebound.getRawValue()) {
                  // Only set homebound date if user didn't change transport date beforehand
                  if (selectedPerson.tournamentFields[0].departsAt
                    && (!this.transportDate.getRawValue()
                      || this.transportDate.getRawValue() == selectedPerson.tournamentFields[0].arrivesAt)) {
                    this.transportDate.setValue(selectedPerson.tournamentFields[0].departsAt);
                  }
                } else {
                  if (selectedPerson.tournamentFields[0].arrivesAt) {
                    this.transportDate.setValue(selectedPerson.tournamentFields[0].arrivesAt);
                  }
                }
              }
            }
          });
      }
    }
  }

  onDelete(): void {
    this.transportBookingService.delete(this.transportBookingId).subscribe(
      () => {
        this.location.back();
      }, (error) => {
        this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
      }
    );
  }

  submit(): void {
    const transportBooking = {
      origin: this.origin.value,
      destination: this.destination.value,
      transportDate: this.transportDate.value,
      homebound: this.homebound.value,
      transportNumber: this.transportNumber.value,
      transportType: this.type.value,
      tournamentId: this.tournamentId,
    };

    if (this.transportBookingId) {
      this.transportBookingService.update(transportBooking, this.transportBookingId).subscribe(
        async () => {
          await this.updateAssignments();
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    } else {
      this.transportBookingService.create(transportBooking).subscribe(
        async (transportBooking) => {
          for (const personId of this.persons.value) {
            await this.transportAssignmentService.create({
              personId,
              transportBookingId: transportBooking.id,
            }).toPromise();
          };
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    }
  }

  async updateAssignments(): Promise<void> {
    for (const personId of this.persons.value) {
      const exists = this.transportBookingAssignments.some(x => x.personId === personId);
      console.log(this.transportBookingAssignments)
      if (!exists) {
        await this.transportAssignmentService.create({
          personId,
          transportBookingId: this.transportBookingId,
        }).toPromise();
      }
    };

    for (const assignment of this.transportBookingAssignments) {
      if (!this.persons.value.includes(assignment.personId)) {
        await this.transportAssignmentService.delete(assignment.id).toPromise();
      }
    }
  }

}
