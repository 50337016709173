import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { TournamentModule } from '@tournament/tournament.module';

import { FilterService, GridModule, GroupService, PageService, SearchService, SortService, ToolbarService, ColumnChooserService, ResizeService } from '@syncfusion/ej2-angular-grids';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgSelectModule } from '@ng-select/ng-select';

import { PersonListComponent } from './components/person-list/person-list.component';
import { PersonEditComponent } from './components/person-edit/person-edit.component';
import { PersonRoleCreateComponent } from './components/person-role-create/person-role-create.component';
import { PersonComponent } from './components/person/person.component';
import { PersonVisaListComponent } from './components/person-visa-list/person-visa-list.component';
import { PersonTransportListComponent } from './components/person-transport-list/person-transport-list.component';
import { PersonHotelAssignmentListComponent } from './components/person-hotel-assignment-list/person-hotel-assignment-list.component';
import { PersonBadgeListComponent } from './components/person-badge-list/person-badge-list.component';
import { PersonTeamListComponent } from './components/person-team-list/person-team-list.component';
import { PersonRolePipe } from './pipes/personRole.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { TeamModule } from '@team/team.module';

@NgModule({
  declarations: [
    PersonListComponent,
    PersonEditComponent,
    PersonRolePipe,
    GenderPipe,
    PersonRoleCreateComponent,
    PersonComponent,
    PersonVisaListComponent,
    PersonTransportListComponent,
    PersonHotelAssignmentListComponent,
    PersonBadgeListComponent,
    PersonTeamListComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    GridModule,
    DatePickerModule,
    NgSelectModule,
    TournamentModule,
    TeamModule,
  ],
  exports: [
    PersonEditComponent,
    PersonRolePipe,
  ],
  providers: [
    PageService,
    SortService,
    FilterService,
    GroupService,
    SearchService,
    ToolbarService,
    ColumnChooserService,
    ResizeService,
  ]
})
export class PersonModule { }
