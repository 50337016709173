<app-container>
  <app-header-delete [isDeleteButtonHidden]="!this.hotelRoomAssignmentId" (confirm)="this.onDelete()">Hotel room
    reservation</app-header-delete>

  <app-alert *ngIf="this.error" type="error">
    {{error}}
  </app-alert>

  <form [formGroup]="formGroup" (ngSubmit)="submit()">

    <div class="from-group">
      <span class="label">Person *</span>

      <ng-select [items]="this.persons" bindValue="id" bindLabel="nameWithBWFId" formControlName="person"
        placeholder="Select a person">
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="flex">
            <img class="h-5 w-auto rounded-full"
              [src]="item.avatarUrl">
            <span class="ml-2">{{ item.nameWithBWFId }}</span>
          </div>
        </ng-template>
      </ng-select>

      <p *ngIf="person.touched && person.hasError('required')" class="mt-2 text-sm text-red-600" id="person-error">
        The person is required.</p>
    </div>
    <br />

    <div class="from-group">
      <span class="label">Room Booking *</span>

      <ng-select [items]="this.bookings" bindValue="id" formControlName="booking" placeholder="Select a room booking"
        (change)="this.onBookingChange($event)">
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <span *ngIf="item && item.id && item.hotel">
            {{item.roomIdentifier}} - {{ item.hotel.name }} ({{ item.startDate | date }} - {{ item.endDate | date }})
          </span>
        </ng-template>
      </ng-select>

      <p *ngIf="booking.touched && booking.hasError('required')" class="mt-2 text-sm text-red-600" id="person-error">
        The room booking is required.</p>
    </div>
    <br />

    <div class="form-group">
      <span class="label">Check In date *</span>
      <ejs-datepicker class="" [firstDayOfWeek]="1" [openOnFocus]="true" formControlName="startDate" name="startDate" format="dd.MM.yyyy"
        [placeholder]="'dd.MM.yyyy'" floatLabelType='Never' [min]="this.startDateMin" [max]="this.startDateMax">
      </ejs-datepicker>

      <p *ngIf="startDate.touched && startDate.hasError('required')" class="mt-2 text-sm text-red-600"
        id="start-date-error">The start date is required.</p>
    </div>

    <div class="form-group">
      <span class="label">Check Out date *</span>
      <ejs-datepicker class="" [firstDayOfWeek]="1" [openOnFocus]="true" formControlName="endDate" name="endDate" format="dd.MM.yyyy"
        [placeholder]="'dd.MM.yyyy'" floatLabelType='Never' [min]="this.endDateMin" [max]="this.endDateMax">
      </ejs-datepicker>

      <p *ngIf="endDate.touched && endDate.hasError('required')" class="mt-2 text-sm text-red-600" id="end-date-error">
        The end date is required.</p>
    </div>

    <br>

    <div class="form-group">
      <span class="inline-flex rounded-md shadow-sm">
        <button backButton type="button" class="btn btn-white">
          Cancel
        </button>
        <button type="submit" [disabled]="!this.formGroup.valid" class="btn btn-primary-outline ml-2">
          {{ this.hotelRoomAssignmentId ? 'Update' : 'Create' }}
        </button>
      </span>
    </div>
  </form>

</app-container>