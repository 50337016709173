import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessTypeService } from '@badge/services/accessType.service';

@Component({
  selector: 'app-access-type-list',
  templateUrl: './accessType-list.component.html',
  styleUrls: ['./accessType-list.component.scss']
})
export class AccessTypeListComponent implements OnInit {

  data = [];

  constructor(
    private accessTypeService: AccessTypeService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.accessTypeService.get({ join: 'person' }).subscribe((res) => {
      this.data = res;
    });
  }

  onRowSelected(row): void {
    this.router.navigate(['edit', row.data.id], { relativeTo: this.route });
  }

  onRowDeleted(data: any): void {
    this.accessTypeService.delete(data.id)
      .subscribe((res) => {
        this.data = this.data.filter(x => x.id !== data.id);
      });
  }

}
