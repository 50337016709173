import { Directive, HostListener } from '@angular/core';
import { Column, ExcelExportService, GridComponent, PdfExportService } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';

@Directive({
    selector: '[defaultGridSettings]',
    providers: [PdfExportService, ExcelExportService]
})
export class DefaultGridSettingsDirective {

    private hiddenTemplateColumns: Column[] = [];

    constructor(private grid: GridComponent) {
        this.grid.toolbar = [
            'PdfExport',
            'ExcelExport',
            'CsvExport',
            {
                id: 'resetSettings',
                text: 'Reset',
                tooltipText: 'Reset all customizations made to the grid.'
            },
            'ColumnChooser',
            'Search'
        ];

        this.grid.pageSettings = { pageSizes: [12, 25, 50, 100, 'All'], pageSize: 12 };
        this.grid.filterSettings = { type: 'Menu' };

        this.grid.allowPdfExport = true;
        this.grid.allowExcelExport = true;
        this.grid.allowResizing = true;

        this.grid.allowSorting = true;
        this.grid.allowFiltering = true;
        this.grid.allowPaging = true;

        this.grid.showColumnChooser = true;
        this.grid.enablePersistence = true;
    }

    @HostListener('toolbarClick', ['$event'])
    toolbarClick(args: ClickEventArgs): void {
        if (args.item.id === 'resetSettings') {
            const gridId = this.grid.element.id;
            this.grid.enablePersistence = false;
            localStorage.removeItem(`grid${gridId}`);
            localStorage.removeItem(`toolbar${gridId}_toolbarItems`);
            this.grid.destroy();
            window.location.reload();
        }

        if (args.item.text.includes('Export') && !this.grid.element.getAttribute('useCustomExportFunction')) {
            // Do not export template columns (custom columns such as avatars, buttons that cannot be displayed)
            // Set column to invisible to hide it in export
            for (const column of this.grid.columns as Column[]) {
                const isTemplateColumn = !column.headerText || !column.field;
        
                if (isTemplateColumn && column.visible) {
                column.visible = false;
                this.hiddenTemplateColumns.push(column);
                }
            }
        
            switch (args.item.text) {
                case 'PDF Export':
                this.grid.pdfExport();
                break;
                case 'Excel Export':
                this.grid.excelExport();
                break;
                case 'CSV Export':
                this.grid.csvExport();
                break;
            }
        }
    }

    @HostListener('excelExportComplete')
    excelExportComplete(): void {
      this.hiddenTemplateColumns.forEach((column, i) => {
        column.visible = true;
        this.hiddenTemplateColumns.splice(i, 1);
      });
    }
}
