<app-container>
    <app-header-delete [isDeleteButtonHidden]="this.registration?.approvedAt != null" (confirm)="this.onDelete()">
        Registration Details
    </app-header-delete>

    <h2 class="text-lg font-bold leading-7 text-gray-900 sm:text-xl sm:leading-9 sm:truncate mt-5">
        Select existing Person (optional)
    </h2>

    <app-alert *ngIf="this.registration?.approvedAt != null" [type]="'success'" class="mt-3">
        Approved at {{ this.registration.approvedAt | date }}
    </app-alert>

    <app-alert *ngIf="this.registration?.approvedAt == null" [type]="'info'" class="mb-3">
        You can optionally select an existing person entry to be updated. The data will then be overwritten by
        the values you submit. <br>You can alternatively skip this step to create a new person.
    </app-alert>

    <app-alert *ngIf="this.tournamentFields" [type]="'warning'" class="mb-3">
        This person has already been added to the tournament and has data related to it.
        All tournmanet data has been loaded and replaced the submitted values.
    </app-alert>

    <form [formGroup]="formGroup">
        <div class="sm:grid sm:grid-cols-12 gap-4 md:grid-cols-12">
            <div class="sm:col-span-6 mt-3 mb-3">
                <app-person-select #personSelect (change)="this.onPersonChange($event)" [formGroup]="this.formGroup"
                    [placeholder]="'Select a person to update (optional)'"></app-person-select>
            </div>
        </div>

        <h2 class="text-lg font-bold leading-7 text-gray-900 sm:text-xl sm:leading-9 sm:truncate mt-5">
            {{this.selectedPerson ? 'Update ' + this.selectedPerson.name : 'Create Person'}}
        </h2>


        <div class="sm:grid sm:grid-cols-12 gap-4 md:grid-cols-12 mb-5">
            <div class="sm:col-span-6">
                <app-avatar-edit [avatarUrl]="this.newAvatarUrl" (croppedOuput)="this.newImage = $event">
                </app-avatar-edit>
                <p *ngIf="this.selectedPerson" class="mt-2 text-sm font-bold text-indigo-500">New image</p>

                <div *ngIf="this.selectedPerson" class="mt-4 relative flex items-start">
                    <div class="flex items-center h-5">
                        <input id="overwrite-existing-image" type="checkbox" [value]="this.overWriteExistingImage"
                            (change)="this.overwriteExistingImage = !this.overwriteExistingImage"
                            class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out">
                    </div>
                    <div class="ml-3 text-sm leading-5">
                        <label for="overwrite-existing-image" class="font-medium text-gray-700 font-bold">
                            Overwrite existing image with new image
                        </label>
                    </div>
                </div>
            </div>
            <div *ngIf="this.selectedPerson" class="sm:col-span-6">
                <img [src]="this.existingAvatarUrl" *ngIf="this.existingAvatarUrl" class="rounded-full"
                    style="width:200px;height:200px;" />
                <p class="mt-2 text-sm font-bold text-indigo-500">Existing image</p>
            </div>
        </div>

        <br>

        <app-person-edit #personEdit [showAvatar]="false" [showButtons]="false" [goBackOnSubmit]="false" [isTournamentDetailsSectionVisible]="true"></app-person-edit>

        <section [hidden]="!this.showVisaCreateForm">
            <h2 class="text-lg font-bold leading-7 text-gray-900 sm:text-xl sm:leading-9 sm:truncate mt-10">Visa</h2>

            <div class="sm:grid sm:grid-cols-2 sm:gap-4 mt-3 md:grid-cols-2">
                <div class="sm:col-span-1">
                    <span class="text-gray-500 font-bold">Passport number</span>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <input id="visaPassportNumber" class="form-control mt-1 block w-full" type="text"
                            formControlName="visaPassportNumber" name="visaPassportNumber" placeholder="012356789" />
                    </div>

                    <p *ngIf="visaPassportNumber.touched && visaPassportNumber.hasError('required')"
                        class="mt-2 text-sm text-red-600" id="passport-number-error">The passport number is required.
                    </p>
                </div>
                <div class="sm:col-span-1 sm:col-start-1">
                    <span class="text-gray-500 font-bold">Issue date</span>
                    <ejs-datepicker class="" [openOnFocus]="true" formControlName="visaIssueDate" name="visaIssueDate"
                        format="dd.MM.yyyy" [placeholder]="'Issue date'" floatLabelType='Never'></ejs-datepicker>

                    <p *ngIf="visaIssueDate.touched && visaIssueDate.hasError('required')"
                        class="mt-2 text-sm text-red-600" id="issue-date-error">The issue date is required.
                    </p>
                </div>

                <div class="sm:col-span-1">
                    <span class="text-gray-500 font-bold">Expiration date</span>
                    <ejs-datepicker class="" [openOnFocus]="true" formControlName="visaExpirationDate"
                        name="visaExpirationDate" format="dd.MM.yyyy" [placeholder]="'Expiration date'"
                        floatLabelType='Never'>
                    </ejs-datepicker>

                    <p *ngIf="visaExpirationDate.touched && visaExpirationDate.hasError('required')"
                        class="mt-2 text-sm text-red-600" id="expiration-date-error">The expiration date is required.
                    </p>
                </div>
            </div>
        </section>

        <section [hidden]="!this.showTransportCreateForm">
            <h2 class="text-lg font-bold leading-7 text-gray-900 sm:text-xl sm:leading-9 sm:truncate mt-10">
                Transport Booking (Arrival)
            </h2>
            <div class="sm:grid sm:grid-cols-2 sm:gap-4 mt-3 md:grid-cols-2">
                <div class="sm:col-span-1">
                    <span class="text-gray-500 font-bold">Type</span>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <ng-select [items]="this.transportTypes" bindValue="id" formControlName="transportType"
                            placeholder="Select a transportion type">
                            <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                <div class="flex">
                                    <span>{{ item.name }}</span>
                                </div>
                            </ng-template>
                        </ng-select>

                        <p *ngIf="transportType.touched && transportType.hasError('required')"
                            class="mt-2 text-sm text-red-600" id="type-error">
                            The type is required.
                        </p>
                    </div>
                </div>
                <div class="sm:col-span-1">
                    <span class="text-gray-500 font-bold">Connection number</span>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <input id="transportNumberArrival" class="form-control mt-1 block w-full" type="text"
                            formControlName="transportNumberArrival" name="transportNumberArrival"
                            placeholder="LX41" />
                    </div>
                </div>
                <div class="sm:col-span-1">
                    <span class="text-gray-500 font-bold">Origin</span>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <input id="transportOrigin" class="form-control mt-1 block w-full" type="text"
                            formControlName="transportOrigin" name="transportOrigin" placeholder="Basel" />
                    </div>
                </div>
                <div class="sm:col-span-1">
                    <span class="text-gray-500 font-bold">Destination</span>
                    <div class="mt-1 relative rounded-md shadow-sm">
                        <input id="transportDestination" class="form-control mt-1 block w-full" type="text"
                            formControlName="transportDestination" name="transportDestination" placeholder="Zurich" />
                    </div>
                </div>
            </div>
        </section>

        <div class="d-block mt-5" *ngIf="this.registration?.approvedAt == null">
            <span class="inline-flex rounded-md shadow-sm">
                <button backButton type="button" class="btn btn-white mr-2">
                    Cancel
                </button>
                <button type="button" [disabled]="!this.formGroup.valid || !this.personEdit.formGroup.valid"
                    class="btn btn-primary" (click)="this.onSubmit()">
                    {{ this.selectedPerson ? 'Update' : 'Create' }}
                </button>
            </span>
        </div>
    </form>
</app-container>