import { Gender } from '@person/enums/gender.enum';

export class GenderHelper {

    public static get genders(): GenderType[] {
        return [
            { id: Gender.MALE, name: 'Male', abbreviation: 'M'},
            { id: Gender.FEMALE, name: 'Female', abbreviation: 'F' },
            { id: Gender.OTHER, name: 'Other', abbreviation: 'O' }
        ];
    }

    public static getGenderAbbreviation(gender: Gender): string {
        return GenderHelper.genders.filter(x => x.id === gender)[0]?.abbreviation;
    }

    public static getGenderName(gender: Gender): string {
        return GenderHelper.genders.filter(x => x.id === gender)[0]?.name;
    }
}

export interface GenderType {
    id: number;
    name: string;
    abbreviation: string
}
