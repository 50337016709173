import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleIdentifier } from '@person/enums/role.identifier.enum';
import { TransportType } from '@transport/enums/transport-type.enum';
import { TransportAssignmentService } from '@transport/services/transport-assignment.service';

@Component({
  selector: 'app-transport-assignment-list',
  templateUrl: './transport-assignment-list.component.html',
  styleUrls: ['./transport-assignment-list.component.scss']
})
export class TransportAssignmentListComponent implements OnInit {

  tournamentId: string = null;
  TRANSPORT_TYPE = TransportType;
  data = [];
  formatSettings = { type: 'date', format: 'dd.MM.yyyy HH:mm' };

  constructor(
    private transportAssignmentService: TransportAssignmentService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;

    this.transportAssignmentService.get(
      {
        join: ['person', 'transportBooking', 'person.organization', 'person.tournamentRoles'],
        filter: [`transportBooking.tournamentId||$eq||${this.tournamentId}`],
        sort: 'person.lastname,ASC'
      })
      .subscribe((res) => {
        const mapTransportsFunction = transport => {
          transport.person['isUnregistered'] = transport.person.tournamentRoles.some(role => role.roleId === RoleIdentifier.UNREGISTERED);
          transport.transportTypeName = this.getTransportTypeNameForType(transport.transportBooking.transportType);
          transport.transportDate = transport.transportBooking.transportDate ? new Date(transport.transportBooking.transportDate) : null;
          transport.organizationName = transport.person.organization ? `${transport.person.organization.type} - ${transport.person.organization.name}`: '';
          return transport;
        };
        this.data = res.map(mapTransportsFunction);
      });
  }

  onRowSelected(row): void {
    this.router.navigate(['edit', row.data.transportBookingId], { relativeTo: this.route });
  }

  onRowDeleted(data: any): void {
    this.transportAssignmentService.delete(data.id)
      .subscribe((res) => {
        this.data = this.data.filter(x => x.id !== data.id);
      });
  }

  getTransportTypeNameForType(type: TransportType): string {
    switch (type) {
      case TransportType.BUS:
        return 'Bus';
      case TransportType.TRAIN:
        return 'Train';
      case TransportType.PLANE:
        return 'Plane';
      case TransportType.OFFICIAL_CAR:
        return 'Official Car'
      default:
        return '';
    }
  }

}
