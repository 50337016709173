<div *ngFor="let tournament of this.tournaments">
    <div class="grid grid-cols-2 gap-4 mb-3">
        <div>
            <h2 class="text-xl font-semibold text-gray-900 mb-2">{{tournament.name}}</h2>
        </div>
        <div *ngIf="tournament === this.tournaments[0]">
            <a class="btn btn-primary float-right" routerLink="/tournaments/{{tournament.id}}/transport_bookings/create"
                [queryParams]="{ personId: this.personId }">Add Transport</a>
        </div>
    </div>
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul>
            <li *ngFor="let transport of this.getTransportsByTournament(tournament.id)">
                <a routerLink="/tournaments/{{tournament.id}}/transport_bookings/edit/{{transport.id}}"
                    class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                            <div class="text-sm leading-5 font-medium text-indigo-600 truncate">
                                {{transport.origin && transport.destination ? transport.origin + ' to ' + transport.destination : 'No origin or destination defined'}}
                            </div>
                            <div class="ml-2 flex-shrink-0 flex text-gray-500">
                                <ng-container *ngIf="transport.homebound">
                                    <i class="fas fa-exchange-alt mr-2"></i>
                                    Homebound
                                </ng-container>
                                <ng-container *ngIf="!transport.homebound">
                                    <i class="fas fa-long-arrow-alt-right mr-2"></i>
                                    Not Homebound
                                </ng-container>
                            </div>
                        </div>
                        <div class="mt-2 sm:flex sm:justify-between">
                            <div class="sm:flex">
                                <div class="mr-6 flex items-center text-sm leading-5 text-gray-500">
                                    <i class="fas fa-calendar mr-2"></i>
                                    {{ transport.homebound ? 'Departure' : 'Arrival' }} at {{transport.transportDate
                                    | date}}
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
    <p *ngIf="this.getTransportsByTournament(tournament.id).length == 0">
        No transportation entries found for this person.
    </p>
</div>