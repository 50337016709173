import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VisaService } from '@visa/services/visa.service';

@Component({
  selector: 'app-visa-list',
  templateUrl: './visa-list.component.html',
  styleUrls: ['./visa-list.component.scss']
})
export class VisaListComponent implements OnInit {

  data = [];
  formatSettings = { type: 'date', format: 'dd.MM.yyyy' };

  constructor(
    private visaService: VisaService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.visaService.get({ join: 'person', sort: 'person.lastname,ASC' }).subscribe((res) => {
      this.data = res.map(visa => { 
        visa.issueDate = visa.issueDate ? new Date(visa.issueDate) : null;
        visa.expirationDate = visa.expirationDate ? new Date(visa.expirationDate) : null;
        return visa
      });
    });
  }

  onRowSelected(row): void {
    this.router.navigate(['edit', row.data.id], { relativeTo: this.route });
  }

  onRowDeleted(data: any): void {
    this.visaService.delete(data.id)
      .subscribe((res) => {
        this.data = this.data.filter(x => x.id !== data.id);
      });
  }

}
