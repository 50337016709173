<div class="h-screen flex overflow-hidden bg-gray-100">
  <app-sidebar class="flex"></app-sidebar>

  <div class="flex flex-col w-0 flex-1 overflow-hidden">
    <app-navbar [isLogoVisible]="false"></app-navbar>

    <main class="relative overflow-y-auto focus:outline-none pb-6 pt-5" tabindex="0">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>