import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });

  error: string;
  twoFactorNonce: string;

  get email(): AbstractControl {
    return this.loginForm.get('email');
  }

  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  constructor(private fb: UntypedFormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']);
    }
  }

  login(): void {
    this.authService.login(this.email.value, this.password.value).subscribe((response) => {
      this.router.navigate(['/']);
    }, (error) => {
      if (error.error.twoFactor) {
        this.router.navigate(['verify']);
      }
      this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
    });
  }

}
