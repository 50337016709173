<app-container [hasFixedWidth]="false">
  <div class="mt-2 md:flex md:items-center md:justify-between">
    <div class="flex-1 min-w-0">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
        Visas
      </h2>
    </div>
    <div class="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
      <span class="ml-3 rounded-md">
        <a class="btn btn-primary" routerLink="./create">Create new visa</a>
      </span>
    </div>
  </div>
  <br />

  <ng-container *ngIf="this.data">
    <ejs-grid defaultGridSettings [dataSource]='data' (rowSelected)="onRowSelected($event)">
      <e-columns>
        <e-column headerText='Photo' [showInColumnChooser]='false' width="100">
          <ng-template #template let-data>
            <div class="flex items-center">
              <img class="h-10 w-10 rounded-full" [src]="data.person.avatarUrl" alt="Photo">
            </div>
          </ng-template>
        </e-column>
        <e-column field='person.humanFriendlyId' headerText="ID" type="number" width="100"></e-column>
        <e-column field='person.bwfId' headerText="BWF ID" type="string"></e-column>
        <e-column field='person.firstname' headerText="Firstname" type="string"></e-column>
        <e-column field='person.lastname' headerText="Lastname" type="string"></e-column>
        <e-column field='passportNumber' headerText="Passport Number" type="string"></e-column>
        <e-column field='issueDate' headerText="Issue Date" type="date" [format]="formatSettings"></e-column>
        <e-column field='expirationDate' headerText="Expiration Date" type="date" [format]="formatSettings"></e-column>
        <e-column headerText='Delete' [showInColumnChooser]='false' width="100">
          <ng-template #template let-data>
            <app-button-delete (confirm)="this.onRowDeleted(data)"></app-button-delete>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </ng-container>
  <app-container>