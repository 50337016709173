<app-header>Login</app-header>

<app-container>
  <app-alert *ngIf="this.error" type="error">
    {{error}}
  </app-alert>

  <form class="form" [formGroup]="loginForm" (ngSubmit)="login()">
    <div [class.error]="email.touched && (email.hasError('required') || email.hasError('email'))">
      <span class="label">E-Mail</span>
      <input id="email" class="form-control" formControlName="email" placeholder="E-Mail Adresse">

      <p class="error-message" id="email-error">You must enter a valid email address</p>
    </div>

    <br/>

    <div [class.error]="password.touched && (password.hasError('required') || password.hasError('minlength'))">
      <span class="label">Password</span>
      <input id="password" class="form-control" type="password" formControlName="password" name="password" placeholder="Password" required/>

      <p class="error-message" id="password-error">Your password must be longer than 6 characters.</p>
    </div>
    <br/>

    <div class="d-block">
      <button class="btn btn-primary" type="submit" [disabled]="!this.loginForm.valid">
        Login
      </button>
    </div>
  </form>

</app-container>
