import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header-delete',
  templateUrl: './header-delete.component.html',
  styleUrls: ['./header-delete.component.scss']
})
export class HeaderDeleteComponent implements OnInit {

  @Input() isDeleteButtonHidden = false;

  @Output() confirm = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  onConfirm(): void {
    this.confirm.emit();
  }

}
