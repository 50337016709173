import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TournamentService } from '@tournament/services/tournament.service';
import { VisaService } from '@visa/services/visa.service';

@Component({
  selector: 'app-person-visa-list',
  templateUrl: './person-visa-list.component.html',
  styleUrls: ['./person-visa-list.component.scss']
})
export class PersonVisaListComponent implements OnInit {

  personId = null;
  tournaments = [];
  visa = [];

  constructor(
    private route: ActivatedRoute,
    private tournamentService: TournamentService,
    private visaService: VisaService,
  ) {
    this.personId = this.route.snapshot.params.personId;

    this.tournamentService.get({ sort: 'start,DESC' }).subscribe((res => {
      this.tournaments = res;
    }));

    this.visaService.get({ filter: [`personId||$eq||${this.personId}`], sort: 'expirationDate,DESC' }).subscribe((res) => {
      this.visa = res;
    });
  }

  ngOnInit(): void {
  }

  isExpired(expirationDate: Date): boolean {
    return expirationDate > new Date();
  }

}
