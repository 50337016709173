<div class="mt-2 grid grid-cols-2 mb-5">
    <div class="col-span-1">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
        <ng-content></ng-content>
      </h2>
    </div>
    <div class="col-span-1 text-right">
      <app-button-delete (confirm)="this.onConfirm()" [hidden]="!!this.isDeleteButtonHidden">Delete
      </app-button-delete>
    </div>
  </div>