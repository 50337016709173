import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Person } from '@person/models/person.model';
import { PersonService } from '@person/services/person.service';

@Component({
  selector: 'app-person-select',
  templateUrl: './person-select.component.html',
  styleUrls: ['./person-select.component.scss']
})
export class PersonSelectComponent implements OnInit {

  @Input() placeholder = 'Select a person';
  @Input() formControlName = 'person';
  @Input() areAvatarsVisible = false;
  @Input() filter: string[] = [];
  @Input() formGroup: UntypedFormGroup = null;

  @Output() change = new EventEmitter();

  persons = [];
  selectedPersonId = null;
  bwfId = null;

  constructor(private personService: PersonService) { }

  ngOnInit(): void {
    this.personService.get({ filter: this.filter, sort: ['lastname,ASC', 'firstname,ASC'] })
      .subscribe((persons: Person[]) => {
        this.persons = persons;
        this.searchBwfId();
      });
  }

  onChange(event) {
    this.change.emit(event);
  }

  onSearch(term, item: Person): boolean {
    const valuesToCompareAgainst = [item.firstname, item.lastname, item.bwfId, item.humanFriendlyId];
    return valuesToCompareAgainst.some(x => String(x).toLowerCase().includes(term.toLowerCase()));
  }

  private searchBwfId() {
    if (!this.bwfId) {
      return;
    }

    const person = this.persons.find(x => x.bwfId === this.bwfId);
    if (person) {
      this.selectedPersonId = person.id;
    }
    this.change.emit(person);
  }

  selectPersonByBWFId(bwfId: string) {
    this.bwfId = bwfId;
    this.searchBwfId();
  }
}
