import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NotificationType } from '@shared/enums/notification-type.enum';
import { Notification } from '@shared/interfaces/notification.interface';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationSubject = new Subject<Notification>();
  private clearNotificationSubject = new Subject<void>();

  constructor(router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // clear messages on route change
        this.clearNotificationSubject.next();
      }
    });
  }

  getNotifications(): Observable<Notification> {
    return this.notificationSubject.asObservable();
  }

  getClearNotificationsObservable(): Observable<void> {
    return this.clearNotificationSubject.asObservable();
  }

  success(notification: string | Notification): void {
    this.notify(notification, NotificationType.SUCCESS);
  }

  info(notification: string | Notification): void {
    this.notify(notification, NotificationType.INFO);
  }

  warning(notification: string | Notification): void {
    this.notify(notification, NotificationType.WARNING);
  }

  error(notification: string | Notification): void {
    this.notify(notification, NotificationType.DANGER);
  }

  notify(notification: string | Notification, type?: NotificationType): void {
    let n: Notification;
    if (typeof notification === 'string') {
      n = { message: notification, type };
    } else {
      n = notification;
      n.type = type != null ? type : notification.type;
    }
    this.notificationSubject.next(n);
  }
}
