import { HotelRoomType } from '@hotel/enums/hotel-room-type.enum';
import { Organization } from '@organization/models/organization.model';
import { Gender } from '@person/enums/gender.enum';

export class Person {
    id?: string;
    humanFriendlyId?: number;
    createdAt?: Date;
    updatedAt?: Date;
    deletedAt?: Date;
    firstname: string;
    lastname: string;
    gender: Gender;
    birthdate?: Date;
    phone?: string;
    email?: string;
    bwfId?: string;
    countryCode: string;
    image?: File;
    avatarUrl?: string;
    data?: any;
    visas?: any[];
    transportBookings?: any[];
    hotelRoomAssignments?: any[];
    badges?: any[];
    tournamentRoles?: any[];
    teams?: any[];
    organization?: Organization;
    tournamentFields?: PersonTournamentFields[];

    get name(): string {
        return `${this.firstname} ${this.lastname}`;
    }

    get nameWithBWFId(): string {
        if (this.bwfId) {
            return `${this.firstname} ${this.lastname} (${this.bwfId})`;
        }
        return this.name;
    }

    public constructor(init?: Partial<Person>) {
        Object.assign(this, init);
        this.birthdate = this.birthdate ? new Date(init.birthdate) : null;
        this.organization = init.organization ? new Organization(init.organization) : null;
    }
}

export class PersonTournamentFields {
    id?: string;
    personId: string;
    tournamentId: string;
    isBadgeNeeded?: boolean;
    isParkingCardNeeded?: boolean;
    isParkingCardIssued?: boolean;
    isVisaNeeded?: boolean;
    isHotelNeeded?: boolean;
    isTransportNeededOnArrival?: boolean;
    isTransportNeededOnDeparture?: boolean;
    isFreeHotel?: boolean;
    isHotelInvoicePaid?: boolean;
    paidAt?: Date;
    invoiceNumber?: string;
    arrivesAt?: Date;
    departsAt?: Date;
    comment?: string;
    preferredHotelRoomType?: HotelRoomType;
    // isPcrTestReceived?: boolean;
    // isAntigenTestReceived?: boolean;
    // pcrTestReceivedAt?: Date;
    // antigenTestReceivedAt?: Date;
}
