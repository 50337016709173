import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';

import {FilterService, GridModule, GroupService, PageService, SortService, ColumnChooserService, ResizeService} from '@syncfusion/ej2-angular-grids';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgSelectModule } from '@ng-select/ng-select';

import { TeamListComponent } from './components/team-list/team-list.component';
import { TeamEditComponent } from './components/team-edit/team-edit.component';
import { DisciplinePipe } from './pipes/discipline.pipe';

@NgModule({
  declarations: [TeamListComponent, TeamEditComponent, DisciplinePipe],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    GridModule,
    DatePickerModule,
    NgSelectModule
  ],
  providers: [DisciplinePipe, PageService, SortService, FilterService, GroupService, ColumnChooserService, ResizeService],
  exports: [DisciplinePipe]
})
export class TeamModule { }
