import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-avatar-edit',
  templateUrl: './avatar-edit.component.html',
  styleUrls: ['./avatar-edit.component.scss']
})
export class AvatarEditComponent implements OnInit {

  get avatarBackground(): string {
    return 'url(\'' + this.avatarUrl || this.defaultAvatarUrl + '\')';
  }

  get cropperBackground(): string {
    return 'url(\'' + this.croppedImage + '\')';
  }

  constructor(public sanitizer: DomSanitizer) { }

  @Input() defaultAvatarUrl: string;
  @Input() avatarUrl: string;

  @Output() croppedOuput: EventEmitter<File> = new EventEmitter<File>();

  maxFileSize = 1024 * 1024 * 5; // 5 Megabyte
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageLoadFailedMessage = '';

  /*
  File Dropzone
  */

  file: File;

  ngOnInit(): void { }

  /*
  ImageCropper
  */

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  async imageCropped(event: ImageCroppedEvent): Promise<void> {
    this.croppedImage = event.base64;
    this.croppedOuput.emit(await this.getFileByBase64(this.croppedImage));
  }

  loadImageFailed(): void {
    this.imageLoadFailedMessage = 'Error: Could not load selected image.';
  }

  onSelect(event): void {
    const addedFile = event.addedFiles[0] as File;
    if(addedFile.size <= this.maxFileSize) {
      this.file = addedFile;
      this.imageLoadFailedMessage = null;
    } else {
      this.imageLoadFailedMessage = 'File too large. Maximum allowed file-size: 5MB';
    }
  }

  onRemove(event): void {
    // TODO
    console.log(event);
    // this.files.splice(this.files.indexOf(event), 1);
  }

  getFileByBase64(base64: string): Promise<File> {
    const url = base64;
    return fetch(url)
      .then(res => res.blob())
      .then(blob => {
        return new File([blob], 'avatar', { type: 'image/png' });
      });
  }
}
