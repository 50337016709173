import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HotelService } from '@hotel/services/hotel.service';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';

@Component({
  selector: 'app-hotel-edit',
  templateUrl: './hotel-edit.component.html',
  styleUrls: ['./hotel-edit.component.scss']
})
export class HotelEditComponent implements OnInit {

  error = null;
  hotelId = null;
  formGroup = this.fb.group({
    name: ['', [Validators.required]],
    address: ['', [Validators.required]],
    postalcode: ['4000', [Validators.required]],
    city: ['Basel', [Validators.required]],
    countryCode: ['ch', [Validators.required]],
    roomBookingPrefix: ['', [Validators.required]],
  });

  get name(): AbstractControl {
    return this.formGroup.get('name');
  }

  get address(): AbstractControl {
    return this.formGroup.get('address');
  }

  get postalcode(): AbstractControl {
    return this.formGroup.get('postalcode');
  }

  get city(): AbstractControl {
    return this.formGroup.get('city');
  }

  get countryCode(): AbstractControl {
    return this.formGroup.get('countryCode');
  }

  get roomBookingPrefix(): AbstractControl {
    return this.formGroup.get('roomBookingPrefix');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private hotelService: HotelService) {
    this.hotelId = this.route.snapshot.params.hotelId;
    if (this.hotelId) {
      this.hotelService.getOne(this.hotelId)
        .subscribe((res) => {
          this.name.setValue(res.name);
          this.address.setValue(res.address);
          this.postalcode.setValue(res.postalcode);
          this.city.setValue(res.city);
          this.countryCode.setValue(res.countryCode);
          this.roomBookingPrefix.setValue(res.roomBookingPrefix);
        });
    }
  }

  ngOnInit(): void { }

  onDelete(): void {
    this.hotelService.delete(this.hotelId).subscribe(
      () => {
        this.location.back();
      }, (error) => {
        this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
      }
    );
  }

  submit(): void {
    const hotel = {
      name: this.name.value,
      address: this.address.value,
      postalcode: this.postalcode.value,
      city: this.city.value,
      countryCode: this.countryCode.value,
      roomBookingPrefix: this.roomBookingPrefix.value.toString().replace(' ', '_'),
    };

    if (this.hotelId) {
      this.hotelService.update(hotel, this.hotelId)
        .subscribe(
          () => {
            this.location.back();
          }, (error) => {
            this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
          }
        );
    } else {
      this.hotelService.create(hotel)
        .subscribe(
          () => {
            this.location.back();
          }, (error) => {
            this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
          }
        );
    }
  }

}
