import { AfterViewInit, Component, ContentChild, ContentChildren, OnInit, QueryList } from '@angular/core';
import { TabItemComponent } from '../tab-item/tab-item.component';

@Component({
  selector: 'app-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss']
})
export class TabNavigationComponent implements OnInit, AfterViewInit {

  activeTab = null;

  @ContentChildren(TabItemComponent) tabItems: QueryList<TabItemComponent>;
  @ContentChild(TabItemComponent) test: TabItemComponent;

  constructor() { }

  ngAfterViewInit(): void {
    for (const tabItem of this.tabItems) {
      if (!this.activeTab && tabItem.isActive) {
        this.activeTab = tabItem;
      } else if (tabItem.isActive) {
        tabItem.isActive = false;
      }
    }

    if (!this.activeTab) {
      this.activeTab = this.tabItems[0];
    }

    this.tabItems.map(tabItem => {
      tabItem.active.subscribe(() => {
        this.onActiveTabChanged(tabItem);
      });
    });
  }

  ngOnInit(): void { }

  onActiveTabChanged(tabItem: TabItemComponent): void {
    this.activeTab = tabItem;

    for (const tab of this.tabItems) {
      tab.isActive = tab === tabItem;
    }
  }

}
