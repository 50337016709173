<app-container [hasFixedWidth]="false">
  <div class="mt-2 md:flex md:items-center md:justify-between">
    <div class="flex-1 min-w-0">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
        <ng-container *ngIf="!this.data">
          {{this.personRoleId | personRole: 'capitalized':true}}
        </ng-container>
        <ng-container *ngIf="this.data">
          {{this.personRoleId | personRole: 'capitalized':true}} ({{data.length}})
        </ng-container>
      </h2>
    </div>
    <div class="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
      <span class="ml-3 rounded-md">
        <a *ngIf="this.personRoleId == null" class="btn btn-primary" [routerLink]="['create']">Create new
          person</a>
        <a *ngIf="this.personRoleId != null" class="btn btn-primary" [routerLink]="['add']">Add {{this.personRoleId
          |
          personRole :'':true}} from persons</a>
      </span>
    </div>
  </div>

  <br />

  <p *ngIf="this.personRoleId == null && this.tournamentId != null" class="text-muted">
    This list shows all persons that have been given at least one role in the selected tournament.
  </p>

  <br>

  <app-loading-spinner *ngIf="!this.data"></app-loading-spinner>

  <ng-container *ngIf="this.data">
    <ejs-grid id="personGrid" #personGrid defaultGridSettings [dataSource]='data'
      (rowSelected)="onRowSelected($event)">
      <e-columns>
        <e-column headerText='Photo' [showInColumnChooser]='false' width="100">
          <ng-template #template let-data>
            <div class="flex items-center">
              <img class="h-10 w-10 rounded-full" [src]="data.avatarUrl" alt="Photo">
            </div>
          </ng-template>
        </e-column>
        <e-column field='humanFriendlyId' headerText="ID" type="number" width="100"></e-column>
        <e-column field='bwfId' headerText="BWF ID" type="string" width="100"></e-column>
        <e-column field='firstname' headerText="Firstname" type="string"></e-column>
        <e-column field='lastname' headerText="Lastname" type="string"></e-column>
        <e-column field='birthdate' [visible]="false" headerText="Birthdate" type="date" [format]="formatSettings"></e-column>
        <e-column field='genderAbbreviation' headerText="Gender" type="string" width="110"></e-column>
        <e-column field='countryCode' headerText="Country" type="string" width="110"></e-column>
        <e-column field='phone' [visible]="false" headerText="Phone" type="string"></e-column>
        <e-column field='email' [visible]="false" headerText="Email" type="string"></e-column>
        <e-column field='organization.nameWithType' headerText="Organization" type="string"></e-column>
        <e-column field='discipline' [visible]="this.isPlayerPage" headerText="Discipline" type="string"></e-column>
        <e-column field='partner' [visible]="false" headerText="Partner" type="string"></e-column>
        <e-column field='isHotelNeeded' [visible]="false" headerText="Hotel" type="string"></e-column>
        <e-column field='isTransportNeededOnArrival' [visible]="false" headerText="Transport" type="string"></e-column>
        <e-column field='isTransportNeededOnDeparture' [visible]="false" headerText="Transport" type="string"></e-column>
        <e-column field='isBadgeNeeded' [visible]="false" headerText="Badge" type="string"></e-column>
        <!-- <e-column field='isPcrTestReceived' [visible]="false" headerText="PCR Test Received" type="string"></e-column> -->
        <!-- <e-column field='isAntigenTestReceived' [visible]="false" headerText="Antigen Test Received" type="string"></e-column> -->
        <e-column field='roles' [visible]="false" headerText="Roles" type="string"></e-column>
        <e-column field='isUnregistered' [visible]="false" headerText="Unregistered" type="boolean"></e-column>
      </e-columns>
    </ejs-grid>
  </ng-container>
</app-container>