import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dashboard-header-card',
  templateUrl: './dashboard-header-card.component.html',
  styleUrls: ['./dashboard-header-card.component.scss']
})
export class DashboardHeaderCardComponent implements OnInit {

  @Input() isActive = false;
  @Input() title = '';
  @Input() description = '';
  @Input() iconClass = '';
  @Input() colorClass = '';
  @Input() counter = 0;

  @Output() toggle = new EventEmitter<boolean>();

  private static STATE_CACHING_VARIABLE_PREFIX = 'dashboard_item_state_';

  constructor() { }

  ngOnInit(): void {
    this.isActive = localStorage.getItem(DashboardHeaderCardComponent.STATE_CACHING_VARIABLE_PREFIX + this.hashCode(this.title)) === 'true';
    if (this.isActive) {
      this.toggle.emit(true);
    }
  }

  private onToggle(): void {
    this.isActive = !this.isActive
    this.toggle.emit(this.isActive); 
    localStorage.setItem(DashboardHeaderCardComponent.STATE_CACHING_VARIABLE_PREFIX + this.hashCode(this.title), this.isActive.toString());
  }

  private hashCode(stringToHash: string): number {
    return Math.abs(stringToHash.split("").reduce(function(a, b) {
      a = ((a << 5) - a) + b.charCodeAt(0);
      return a & a;
    }, 0));
  }

}
