<app-container [hasFixedWidth]="false">
  <div class="mt-2 md:flex md:items-center md:justify-between">
    <div class="flex-1 min-w-0">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
        Transport bookings
      </h2>
    </div>
    <div class="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
      <span class="ml-3 rounded-md">
        <a class="btn btn-primary" routerLink="./create">Create new transport booking</a>
      </span>
    </div>
  </div>
  <br />

  <ng-container *ngIf="this.data">
    <ejs-grid defaultGridSettings [dataSource]='data' (rowSelected)="onRowSelected($event)">
      <e-columns>
        <e-column headerText='Photo' [showInColumnChooser]='false' width="100">
          <ng-template #template let-data>
            <div class="flex items-center">
              <img class="h-10 w-10 rounded-full" [src]="data.person.avatarUrl" alt="Photo">
            </div>
          </ng-template>
        </e-column>
        <e-column field='person.humanFriendlyId' headerText="ID" type="number" width="100"></e-column>
        <e-column field='person.bwfId' headerText="BWF ID" type="string" width="100"></e-column>
        <e-column field='person.firstname' headerText="Firstname" type="string"></e-column>
        <e-column field='person.lastname' headerText="Lastname" type="string"></e-column>
        <e-column field='person.isUnregistered' [visible]="false" headerText="Unregistered" type="boolean"></e-column>
        <e-column field='organizationName' headerText='Organization' type="string"></e-column>
        <e-column field='transportBooking.origin' headerText='Origin' type="string"></e-column>
        <e-column field='transportBooking.destination' headerText='Destination' type="string"></e-column>
        <e-column field='transportTypeName' headerText='Type' type="string" width="100">
          <ng-template #template let-data>
            <span *ngIf="data.transportBooking.transportType === TRANSPORT_TYPE.BUS"
              class="badge badge-primary">{{this.getTransportTypeNameForType(TRANSPORT_TYPE.BUS)}}</span>
            <span *ngIf="data.transportBooking.transportType === TRANSPORT_TYPE.TRAIN"
              class="badge badge-warning">{{this.getTransportTypeNameForType(TRANSPORT_TYPE.TRAIN)}}</span>
            <span *ngIf="data.transportBooking.transportType === TRANSPORT_TYPE.PLANE"
              class="badge badge-success">{{this.getTransportTypeNameForType(TRANSPORT_TYPE.PLANE)}}</span>
            <span *ngIf="data.transportBooking.transportType === TRANSPORT_TYPE.OFFICIAL_CAR"
              class="badge badge-primary">{{this.getTransportTypeNameForType(TRANSPORT_TYPE.OFFICIAL_CAR)}}</span>
          </ng-template>
        </e-column>
        <e-column field='transportDate' headerText='Date' type="date" [format]="formatSettings"></e-column>
        <e-column field='transportBooking.transportNumber' headerText='Transport number' type="string"></e-column>
        <e-column headerText='Delete' textAlign='Right' [showInColumnChooser]='false' width="110">
          <ng-template #template let-data>
            <app-button-delete (confirm)="this.onRowDeleted(data)"></app-button-delete>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </ng-container>
  <app-container>