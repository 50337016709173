import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoleIdentifier } from '@person/enums/role.identifier.enum';
import { TournamentRoleService } from '@person/services/tournament-role.service';
import { RoleService } from '@tournament/services/role.service';
import { TournamentService } from '@tournament/services/tournament.service';
import { VolunteerTimeService } from 'app/volunteer-time/services/volunteer-time.service';

export enum TimeOfDay {
  MORNING = 10,
  AFTERNOON = 20,
  EVENING = 30
}

@Component({
  selector: 'app-volunteer-time',
  templateUrl: './volunteer-time.component.html',
  styleUrls: ['./volunteer-time.component.scss']
})
export class VolunteerTimeComponent implements OnInit {

  TimeOfDay = TimeOfDay;
  isLoading = false;
  tournamentId = null;

  tournament = null;
  volunteerTimes = null;
  volunteers = null;

  startDate = new Date();
  endDate = new Date();
  selectedDate = new Date();

  roles = [];
  selectedRoleId: any = RoleIdentifier.LINE_JUDGE;

  constructor(
    private tournamentService: TournamentService,
    private volunteerTimeService: VolunteerTimeService,
    private tournamentRoleService: TournamentRoleService,
    private roleService: RoleService,
    private route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
    this.isLoading = true;

    this.roles = await this.roleService.get(
      {
        filter: [`tournamentId||$eq||${this.tournamentId}`],
        or: `tournamentId||$isnull`,
        sort: 'name,ASC'
      })
      .toPromise();
    await this.loadTournament();

    const tournamentStart = new Date(this.tournament.start);
    const startDate = new Date(tournamentStart);
    startDate.setDate(startDate.getDate() - 1);

    const tournamentEnd = new Date(this.tournament.end);
    const endDate = new Date(tournamentEnd);
    endDate.setDate(endDate.getDate() + 1);

    this.selectedDate = startDate;
    this.startDate = startDate;
    this.endDate = endDate;

    await this.loadVolunteers();
    await this.loadVolunteerTimes();

    this.isLoading = false;
  }

  getAmountOfVolunteersByTimeOfDay(timeOfDay: TimeOfDay): number {
    return this.volunteerTimes.filter(x => x.timeOfDay === timeOfDay).length;
  }

  async loadTournament(): Promise<void> {
    this.tournament = await this.tournamentService.getOne(this.tournamentId).toPromise();
  }

  async loadVolunteers(): Promise<void> {
    const filter = [`roleId||$eq||${this.selectedRoleId}`, `tournamentId||$eq||${this.tournamentId}`];
    const join = [`person`];
    const sort = 'person.lastname,ASC';
    this.volunteers = await this.tournamentRoleService.get({ join, filter, sort }).toPromise();
  }

  async loadVolunteerTimes(): Promise<void> {
    const start = (new Date(this.selectedDate)).setHours(0, 0, 0, 0);
    const end = (new Date(this.selectedDate)).setHours(23, 59, 59, 999);
    const filter = [`presentAt||$between||${new Date(start).toISOString()},${new Date(end).toISOString()}`];
    this.volunteerTimes = await this.volunteerTimeService.get({ filter, join: ['tournamentRole'] }).toPromise();
  }

  isVolunteerPresentAt(timeOfDay: TimeOfDay, volunteer): boolean {
    return this.volunteerTimes.some(x => x.timeOfDay === timeOfDay && x.tournamentRole.personId === volunteer.personId);
  }

  async onSelectedRoleChanged(): Promise<void> {
    this.isLoading = true;
    await this.loadVolunteers();
    this.isLoading = false;
  }

  async onSelectedDateChanged(newDate): Promise<void> {
    this.isLoading = true;
    this.selectedDate = newDate;
    await this.loadVolunteerTimes();
    this.isLoading = false;
  }

  async onCheckboxChanged(timeOfDay: TimeOfDay, volunteer): Promise<void> {
    const existingEntry = this.volunteerTimes.filter(x => x.timeOfDay === timeOfDay && x.tournamentRole.personId === volunteer.personId)[0];
    if (existingEntry) {
      this.volunteerTimeService.delete(existingEntry.id).subscribe((res) => {
        this.volunteerTimes.splice(this.volunteerTimes.indexOf(existingEntry), 1);
      });
    }
    else {
      this.volunteerTimeService.create({
        tournamentRoleId: volunteer.id,
        presentAt: this.selectedDate,
        timeOfDay
      }).subscribe(async (res) => {
        const newEntry = await this.volunteerTimeService.getOne(res.id, { join: ['tournamentRole'] }).toPromise();
        this.volunteerTimes.push(newEntry);
      });
    }
  }
}
