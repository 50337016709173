import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { NavigationModule } from './navigation/navigation.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { UserModule } from '@user/user.module';
import { PersonModule } from '@person/person.module';
import { TournamentModule } from '@tournament/tournament.module';
import { HotelModule } from '@hotel/hotel.module';
import { OrganizationModule } from '@organization/organization.module';
import { VisaModule } from '@visa/visa.module';
import { BadgeModule } from '@badge/badge.module';
import { TransportModule } from '@transport/transport.module';
import { TeamModule } from '@team/team.module';
import { VolunteerTimeModule } from '@volunteer-time/volunteer-time.module';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { OnlineRegistrationModule } from './online-registration/online-registration.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AuthModule,
    CoreModule,
    NavigationModule,
    UserModule,
    PersonModule,
    TournamentModule,
    HotelModule,
    OrganizationModule,
    SweetAlert2Module.forRoot(),
    VisaModule,
    BadgeModule,
    TransportModule,
    TeamModule,
    VolunteerTimeModule,
    OnlineRegistrationModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
