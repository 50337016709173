import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelRoomType } from '@hotel/enums/hotel-room-type.enum';
import { PersonEditComponent } from '@person/components/person-edit/person-edit.component';
import { PersonService } from '@person/services/person.service';
import { PersonSelectComponent } from '@shared/components/person-select/person-select.component';
import { I18nHelper } from '@shared/helpers/i18n.helper';
import { TransportType } from '@transport/enums/transport-type.enum';
import { TransportTypeHelper } from '@transport/helpers/transport-type.helper';
import { TransportAssignmentService } from '@transport/services/transport-assignment.service';
import { TransportBookingService } from '@transport/services/transport-booking.service';
import { VisaService } from '@visa/services/visa.service';
import { OnlineRegistrationService } from 'app/online-registration/services/online-registration.service';

import moment from 'moment';

@Component({
  selector: 'app-online-registration-edit',
  templateUrl: './online-registration-edit.component.html',
  styleUrls: ['./online-registration-edit.component.scss']
})
export class OnlineRegistrationEditComponent implements OnInit {

  @ViewChild('personSelect') personSelect: PersonSelectComponent;
  @ViewChild('personEdit') personEdit: PersonEditComponent;

  tournamentId: string;
  tournamentFields: null;
  overwriteExistingImage: boolean = false;
  showVisaCreateForm: boolean = false;
  showTransportCreateForm: boolean = false;
  selectedPerson = null;
  newImage: File = null;
  newAvatarUrl = "";
  existingAvatarUrl = "";
  registrationId: any = null;
  registration: any = null;

  weekDays = moment.weekdays();
  transportTypes = TransportTypeHelper.getTypes();

  formGroup = this.fb.group(
    {
      person: [null, []],
      visaPassportNumber: ['', []],
      visaIssueDate: ['', []],
      visaExpirationDate: ['', []],
      transportType: [null, []],
      transportOrigin: [null, []],
      transportDestination: [null, []],
      transportNumberArrival: [null, []],
    }
  );

  get visaPassportNumber(): AbstractControl {
    return this.formGroup.get('visaPassportNumber');
  }

  get visaIssueDate(): AbstractControl {
    return this.formGroup.get('visaIssueDate');
  }

  get visaExpirationDate(): AbstractControl {
    return this.formGroup.get('visaExpirationDate');
  }

  get transportType(): AbstractControl {
    return this.formGroup.get('transportType');
  }

  get transportOrigin(): AbstractControl {
    return this.formGroup.get('transportOrigin');
  }

  get transportDestination(): AbstractControl {
    return this.formGroup.get('transportDestination');
  }

  get transportNumberArrival(): AbstractControl {
    return this.formGroup.get('transportNumberArrival');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private registrationService: OnlineRegistrationService,
    private visaService: VisaService,
    private transportBookingService: TransportBookingService,
    private transportAssignmentService: TransportAssignmentService,
    private personService: PersonService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
    this.registrationId = this.route.snapshot.params.registrationId;
    this.registration = await this.registrationService.getOne(this.registrationId).toPromise();

    this.registrationService.getImage(this.registration.id)
      .subscribe(imageBlobFromCognitoForms => {
        this.newImage = new File([imageBlobFromCognitoForms], "avatar");
      });
    this.newAvatarUrl = this.registration.imageUrl;

    this.selectPersonByBWFIdIfPresent();
    this.loadPersonData();
    this.loadTournamentFields();
    this.loadComment();
    this.loadInputSubtitles();
    this.loadVisaFields();
    this.loadTransportFields();
  }

  selectPersonByBWFIdIfPresent() {
    this.personSelect.selectPersonByBWFId(this.registration.data.BwfId)
  }

  loadComment(): void {
    let comment = this.objectStringBuilder(this.registration.data)
      .addPropertyToNewLine('Title')
      .addPropertyToNewLine('TShirtSize')
      .addPropertyToNewLine('SpecialSkills')
      .addPropertyToNewLine('ShareRoomWith')
      .addPropertyToNewLine('TeamToApplyFor', 'Team to apply for')
      .addPropertyToNewLine('TransportNumberArrival')
      .addPropertyToNewLine('IsDateOfArrivalUnknown', 'Arrival date unknown')
      .addPropertyToNewLine('TransportNumberDeparture')
      .addPropertyToNewLine('IsDateOfDepartureUnknown', 'Departure date unknown')
      .addPropertyToNewLine(this.registration.data.TransportType === 'Other' ? 'TransportTypeCustom' : 'TransportType', 'Arriving in Basel by')
      .addPropertyToNewLine('TransportOrigin')
      .addPropertyToNewLine('TransportDestination')
      .addPropertyToNewLine('ParkingSpotNeeded')
      .addPropertyToNewLine('LicensePlateNumber')
      .addPropertyToNewLine('DrivingLicenses')
      .addPropertyToNewLine('LineJudgeLevel')
      .addPropertyToNewLine('EmergencyContactName')
      .addPropertyToNewLine('EmergencyContactPhone')
      .addPropertyToNewLine('EmergancyContactRelationship')
      .addPropertyToNewLine('FederationEmergencyContactName')
      .addPropertyToNewLine('FederationEmergencyContactEmail')
      .addPropertyToNewLine('AipsNumber', 'AIPS Number')
      .addPropertyToNewLine('MediaSpectrum')
      .addPropertyToNewLine('MediaSpectrumCustom', 'Other Media Spectrum')
      .addPropertyToNewLine('Function')
      .addPropertyToNewLine('FunctionCustom', 'Other Function')
      .addPropertyToNewLine('Comment')
      .build();

    if (this.registration.data.CompanyAddress) {
      comment += this.objectStringBuilder(this.registration.data.CompanyAddress, false)
        .addPropertyToNewLine('FullAddress', 'Company Address')
        .build();
    }

    if (this.registration.data.TimePlanning) {
      comment += this.objectStringBuilder(this.registration.data.TimePlanning, false)
        .addPropertyToNewLine('Monday')
        .addPropertyToNewLine('Tuesday')
        .addPropertyToNewLine('Wednesday')
        .addPropertyToNewLine('Thursday')
        .addPropertyToNewLine('Friday')
        .addPropertyToNewLine('Saturday')
        .addPropertyToNewLine('Sunday')
        .build();
    }

    this.personEdit.comment.setValue(comment);
  }

  objectStringBuilder(object, multipleLineBreaks: boolean = true) {
    let output = '';
    const lineBreaks = multipleLineBreaks ? '\r\n\r\n' : '\r\n';
    const chainingFunction = {
      addPropertyToNewLine: (propertyName: string, description?: string) => {
        if (object[propertyName]) {
          if (Array.isArray(object[propertyName])) {
            output += (description || propertyName) + ': ' + object[propertyName].join(', ') + lineBreaks;
          } else {
            output += (description || propertyName) + ': ' + object[propertyName] + lineBreaks;
          }
        }
        return chainingFunction;
      },
      build: () => {
        return output;
      }
    };

    return chainingFunction;
  }

  loadPersonData(): void {
    this.personEdit.firstname.setValue(this.registration.data.Firstname);
    this.personEdit.lastname.setValue(this.registration.data.Lastname);
    this.personEdit.gender.setValue(this.registration.data.Gender_Value);
    this.personEdit.country.setValue(I18nHelper.getCountryCodeForCountryName(this.registration.data.Nationality));
    this.personEdit.birthdate.setValue(new Date(this.registration.data.Birthdate));
    this.personEdit.email.setValue(this.registration.data.Email);
    this.personEdit.phone.setValue(this.registration.data.Phone);
    this.personEdit.bwfId.setValue(this.registration.data.BwfId);
  }

  loadTournamentFields(): void {
    this.personEdit.arrivesAt.setValue(new Date(`${this.registration.data.ArrivalDate} ${this.registration.data.ArrivalTime}`));
    this.personEdit.departsAt.setValue(new Date(`${this.registration.data.DepartureDate} ${this.registration.data.DepartureTime}`));
    this.personEdit.isVisaNeeded.setValue(this.registration.data.IsVisaNeeded);
    this.personEdit.isHotelNeeded.setValue(this.registration.data.IsHotelNeeded);
    this.personEdit.preferredHotelRoomType.setValue(Object.values(HotelRoomType)[this.registration.data.PreferredBedroomType_Value]);
    this.personEdit.isParkingCardNeeded.setValue(this.registration.data.IsParkingSpotNeeded);
    this.personEdit.isParkingCardIssued.setValue(false);
    this.personEdit.isBadgeNeeded.setValue(false);
    this.personEdit.isFreeHotel.setValue(false);
    this.personEdit.isHotelInvoicePaid.setValue(false);
    this.personEdit.invoiceNumber.setValue(null);
    this.personEdit.paidAt.setValue(null);
    this.personEdit.isTransportNeededOnArrival.setValue(this.registration.data.IsTransportNeeded);
  }

  loadVisaFields(): void {
    if (this.registration.data.IsVisaNeeded) {
      this.showVisaCreateForm = true;

      this.visaPassportNumber.setValue(this.registration.data.PassportNumber);

      this.visaIssueDate.setValue(moment(this.registration.data.IssueDate).format('DD.MM.yyyy'));
      this.visaExpirationDate.setValue(moment(this.registration.data.ExpirationDate).format('DD.MM.yyyy'));

      this.visaPassportNumber.setValidators([Validators.required]);
      this.visaIssueDate.setValidators([Validators.required]);
      this.visaExpirationDate.setValidators([Validators.required]);
    }
  }

  loadTransportFields(): void {
    if (this.registration.data.TransportType_Value in TransportType) {
      this.showTransportCreateForm = true;

      this.transportType.setValue(this.registration.data.TransportType_Value);
      this.transportOrigin.setValue(this.registration.data.TransportOrigin);
      this.transportDestination.setValue(this.registration.data.TransportDestination);
      this.transportNumberArrival.setValue(this.registration.data.TransportNumberArrival);

      this.transportType.setValidators([Validators.required]);
    }
  }

  loadInputSubtitles(): void {
    this.personEdit.inputSubtitles = this.selectedPerson || {};
    this.personEdit.inputSubtitles.role = this.registration.data.Role === 'Other' ? this.registration.data.RoleCustom : this.registration.data.Role;
    this.personEdit.inputSubtitles.organization = this.registration.data.Federation || this.registration.data.Company;
  }

  async onPersonChange(selectedPerson): Promise<void> {
    if (selectedPerson.id) {
      this.selectedPerson = await this.personService.getOne(selectedPerson.id, {join: ['tournamentRoles']}).toPromise();
    }
    this.existingAvatarUrl = selectedPerson?.avatarUrl;
    this.loadInputSubtitles();

    this.personEdit.personId = selectedPerson?.id || null;
    this.personEdit.roles.setValue(selectedPerson?.tournamentRoles.map(x => x.roleId) || []);

    this.tournamentFields = selectedPerson?.tournamentFields.filter(x => x.tournamentId === this.tournamentId)[0] || null;
    this.personEdit.tournamentFields = this.tournamentFields;

    if (this.tournamentFields) {
      // Overwrite submitted values from registration when the selected person already has tournamentfields for this tournament
      this.personEdit.setTournamentFields(this.tournamentFields);
    } else {
      this.loadTournamentFields();
    }

    this.loadComment();
  }

  async onSubmit(): Promise<void> {
    if (this.overwriteExistingImage || this.selectedPerson == null) {
      this.personEdit.image = this.newImage;
    } else {
      this.personEdit.image = null;
    }

    await this.personEdit.submit();
    if(this.showVisaCreateForm) {
      await this.createVisa(this.personEdit.personId);
    }
    if (this.showTransportCreateForm) {
      await this.createTransportBooking(this.personEdit.personId);
    }

    this.registration.approvedAt = new Date().toISOString();
    await this.registrationService.update(this.registration.id, this.registration).toPromise();

    this.router.navigate(['tournaments', this.tournamentId, 'registrations']);
  }

  async onDelete(): Promise<void> {
    await this.registrationService.delete(this.registration.id).toPromise();
    this.router.navigate(['tournaments', this.tournamentId, 'registrations']);
  }

  async createVisa(personId: string): Promise<void> {
    await this.visaService.create({
      passportNumber: this.visaPassportNumber.value,
      expirationDate: moment(this.visaExpirationDate.value.toString(), 'DD.MM.yyyy').toISOString(),
      issueDate: moment(this.visaIssueDate.value.toString(), 'DD.MM.yyyy').toISOString(),
      personId
    }).toPromise();
  }

  async createTransportBooking(personId): Promise<void> {
    const transportBooking = await this.transportBookingService.create({
      transportType: this.transportType.value,
      origin: this.transportOrigin.value,
      destination: this.transportDestination.value,
      transportNumber: this.transportNumberArrival.value,
      tournamentId: this.tournamentId,
      homebound: false,
    }).toPromise();
    await this.transportAssignmentService.create({
      personId,
      transpotBookingId: transportBooking.id
    });
  }

}
