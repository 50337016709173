<app-container [hasFixedWidth]="false">

  <div class="mt-2 md:flex md:items-center md:justify-between">
    <div class="flex-1 min-w-0">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
        Badges
      </h2>
    </div>
  </div>
  <br />

<ng-container *ngIf="this.data">
  <ejs-grid #grid defaultGridSettings id="badgeGrid" useCustomExportFunction="true" [dataSource]='data' (pdfQueryCellInfo)="onPdfQueryCellInfo($event)"
    (rowSelected)="onRowSelected($event)" (toolbarClick)='onGridToolbarClick($event)'>
    <e-columns>
      <e-column headerText='Photo' [showInColumnChooser]='false' width="100">
        <ng-template #template let-data>
          <div class="flex items-center">
            <img class="h-10 w-10 rounded-full" [src]="data.person.avatarUrl" alt="Photo">
          </div>
        </ng-template>
      </e-column>
      <e-column headerText='Print' [showInColumnChooser]='false' width="200">
        <ng-template #template let-data>
          <a class="btn mr-3" 
          [class.btn-primary-outline]="!this.badgesToExport.includes(data.id)"
          [class.btn-primary]="this.badgesToExport.includes(data.id)"
            (click)="addToExportList($event, data.id);">Select for printing</a>
        </ng-template>
      </e-column>
      <e-column field='person.humanFriendlyId' [visible]="false" headerText="ID" type="number"></e-column>
      <e-column field='person.bwfId' [visible]="false" headerText="BWF ID" type="string"></e-column>
      <e-column field='person.firstname' headerText="Firstname" type="string"></e-column>
      <e-column field='person.lastname' headerText="Lastname" type="string"></e-column>
      <e-column field='person.isUnregistered' [visible]="false" headerText="Unregistered" type="boolean"></e-column>
      <e-column field='createdAt' [visible]="false" headerText="Created at" type="date" [format]="formatSettings"></e-column>
      <e-column field='generatedAt' headerText="PDF generated at" type="date" [format]="formatSettings"></e-column>
      <e-column field='accessTypeNames' headerText='Access types' type="string"></e-column>
      <e-column field='organizationWithType' headerText="Organization" type="string"></e-column>
      <e-column field='person.countryCode' headerText="Country" type="string"></e-column>
      <e-column headerText='Roles' field="person.roles" type="string"></e-column>
      <e-column field='signature' headerText='Signature' [visible]="false" type="string"></e-column>
    </e-columns>
  </ejs-grid>
</ng-container>