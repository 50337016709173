<app-header>Login - Two-Factor authentication</app-header>

<app-container>
  <p class="mb-5">Open your authenticator app and enter your 6-digit token.</p>

  <app-alert *ngIf="this.error" type="error">
    {{error}}
  </app-alert>

  <form [formGroup]="verificationForm" (ngSubmit)="verify()">
    <div [class.error]="!token.untouched && (token.hasError('required') || token.hasError('pattern'))">
      <span class="text-gray-500 font-bold">Two-Factor Token</span>
      <div class="mt-1 relative rounded-md shadow-sm">
        <input id="token" class="form-control block w-full pr-10 text-red-900 sm:text-sm sm:leading-5" formControlName="token" placeholder="123456">
      </div>

      <p class="error-message" id="email-error">You must enter a 6 digit code</p>
    </div>

    <br/>

    <div class="d-block">
      <span class="inline-flex rounded-md shadow-sm">
        <button type="submit" [disabled]="!this.verificationForm.valid" class="{{ this.verificationForm.valid ? 'bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 active:bg-indigo-700' : 'bg-indigo-200' }} inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white focus:outline-none focus:ring-indigo-500 transition ease-in-out duration-150">
          Login
        </button>
      </span>
    </div>
  </form>

</app-container>
