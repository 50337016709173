import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';

import { FilterService, GridModule, GroupService, PageService, SortService, ColumnChooserService, ResizeService  } from '@syncfusion/ej2-angular-grids';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgSelectModule } from '@ng-select/ng-select';

import { TransportAssignmentListComponent } from './components/transport-assignment-list/transport-assignment-list.component';
import { TransportBookingArrivalListComponent } from './components/transport-booking-arrival-list/transport-booking-arrival-list.component';
import { TransportBookingEditComponent } from './components/transport-booking-edit/transport-booking-edit.component';

import { TransportBookingService } from './services/transport-booking.service';
import { TransportAssignmentService } from './services/transport-assignment.service';

@NgModule({
  declarations: [
    TransportAssignmentListComponent,
    TransportBookingEditComponent,
    TransportBookingArrivalListComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    GridModule,
    DatePickerModule,
    DateTimePickerModule,
    NgSelectModule,
  ],
  providers: [
    PageService,
    SortService,
    FilterService,
    ColumnChooserService,
    ResizeService,
    GroupService,
    TransportBookingService,
    TransportAssignmentService,
  ],
})
export class TransportModule { }
