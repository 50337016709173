import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getCurrent(): Observable<any> {
    return this.http.get(`${environment.api}/users/me`);
  }

  enableTwoFactor(): Observable<any> {
    return this.http.post(`${environment.api}/users/me/two_factor`, {}, {responseType: 'text'});
  }

  confirmTwoFactor(token: string): Observable<any> {
    return this.http.post(`${environment.api}/users/me/two_factor/confirmation`, {token});
  }

  changePassword(newPassword: string): Observable<any> { 
    return this.http.put(`${environment.api}/users/me/password`, {password: newPassword});
  }
}
