<app-container [hasFixedWidth]="false">

  <div class="mt-2 md:flex md:items-center md:justify-between">
    <div class="flex-1 min-w-0">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
        Arrivals and Departures
      </h2>
    </div>
  </div>
  <br />

  <div class="w-40 inline-block mr-10">

    <ejs-datepicker (ngModelChange)="fillList($event)" format='dd.MM.yyyy' [firstDayOfWeek]="1" [(ngModel)]=listDate>
    </ejs-datepicker>

  </div>

  <div class="inline-flex" role="group" aria-label="Button group">
    <button (click)="switchList(0);" [style.opacity]="LIST_TYPE === 0 ? '1' : '0.5'"
      class="h-10 px-5 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-l-lg focus:ring hover:bg-indigo-800">Arrival</button>
    <button (click)="switchList(1);" [style.opacity]="LIST_TYPE === 1 ? '1' : '0.5'"
      class="h-10 px-5 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-r-lg focus:ring hover:bg-indigo-800">Departure</button>
  </div>

  <br><br>

  <ng-container *ngIf="this.data">
    <ejs-grid defaultGridSettings [dataSource]='data' (rowSelected)="onRowSelected($event)">
      <e-columns>
        <e-column headerText='Photo' [showInColumnChooser]='false' width="100">
          <ng-template #template let-data>
            <div class="flex items-center">
              <img class="h-10 w-10 rounded-full" [src]="data.person.avatarUrl" alt="Photo">
            </div>
          </ng-template>
        </e-column>
        <e-column field='transportBooking.transportDate' headerText='Time' type="date" [format]="timeFormatSettings" width=130></e-column>
        <e-column field='transportBooking.transportDate' headerText='Date' type="date" [format]="dateFormatSettings" [visible]='false' width=130></e-column>
        <e-column field='transportBooking.transportType' headerText='Type'  type="string" width="100">
          <ng-template #template let-data>
            <span *ngIf="data.transportBooking.transportType === TRANSPORT_TYPE.BUS" class="badge badge-primary">Bus</span>
            <span *ngIf="data.transportBooking.transportType === TRANSPORT_TYPE.TRAIN" class="badge badge-warning">Train</span>
            <span *ngIf="data.transportBooking.transportType === TRANSPORT_TYPE.PLANE" class="badge badge-success">Airplane</span>
            <span *ngIf="data.transportBooking.transportType === TRANSPORT_TYPE.OFFICIAL_CAR" class="badge badge-primary">Official Car</span>
          </ng-template>
        </e-column>
        <e-column field='transportBooking.transportNumber' headerText="Transport number" type="string" width="100"></e-column>
        <e-column field='transportBooking.origin' headerText="Origin" type="string" width="150"></e-column>
        <e-column field='transportBooking.destination' headerText="Destination" type="string" width="150"></e-column>
        <e-column field='person.humanFriendlyId' headerText="ID" type="number" width="100"></e-column>
        <e-column field='person.bwfId' headerText="BWF ID" type="string" width="100"></e-column>
        <e-column field='person.firstname' headerText="Firstname" type="string" width="100"></e-column>
        <e-column field='person.lastname' headerText="Lastname" type="string" width="100"></e-column>
        <e-column field='organizationName' headerText="Organization" type="string" width="100"></e-column>
        <e-column field='person.countryCode' headerText="Country" type="string" width="100"></e-column>
        <e-column field='hotel' headerText="Hotel" width=280 type="string"></e-column>
      </e-columns>
    </ejs-grid>
  </ng-container>
  <app-container>