import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Person } from '@person/models/person.model';
import { PersonService } from '@person/services/person.service';
import { TeamService } from '@team/services/team.service';
import { DisciplineHelper, DisciplineType } from '../helpers/discipline.helper';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';
import { Gender } from '@person/enums/gender.enum';
import { RoleIdentifier } from '@person/enums/role.identifier.enum';
import { Discipline } from '@team/enums/discipline.enum';

@Component({
  selector: 'app-team-edit',
  templateUrl: './team-edit.component.html',
  styleUrls: ['./team-edit.component.scss']
})
export class TeamEditComponent implements OnInit {

  error = null;
  tournamentId: string = null;
  teamId: string = null;
  formGroup = this.fb.group({
    discipline: ['', [Validators.required]],
    player1: ['', [Validators.required]],
    worldRanking: [null]
  });
  disciplines = DisciplineHelper.disciplines;
  players: Person[] = [];
  playersFiltered: Person[] = [];

  get discipline(): AbstractControl {
    return this.formGroup.get('discipline');
  }

  get player1(): AbstractControl {
    return this.formGroup.get('player1');
  }

  get player2(): AbstractControl {
    return this.formGroup.get('player2');
  }

  get worldRanking(): AbstractControl {
    return this.formGroup.get('worldRanking');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private personService: PersonService,
    private teamService: TeamService) {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
    this.teamId = this.route.snapshot.params.teamId;
  }

  async ngOnInit(): Promise<void> {
    this.discipline.setValue(Discipline.MEN_SINGLE);
    await this.loadPlayers();

    const personId = this.route.snapshot.queryParams.personId;
    if (personId) {
      this.player1.setValue(personId);
    }

    if (this.teamId) {
      this.teamService.getOne(this.teamId)
        .subscribe((res) => {
          this.discipline.setValue(res.discipline);
          this.worldRanking.setValue(res.worldRanking);
          this.onDisciplineChanged(this.disciplines.filter(x => x.id === res.discipline)[0]);
          this.player1.setValue(res.persons[0].id);

          if (res.persons.length > 1) {
            this.player2.setValue(res.persons[1].id);
          }
        });
    }
  }

  async loadPlayers(): Promise<void> {
    // Filter for tournament and roles (only choose players)
    const filter = [`tournamentRoles.roleId||$eq||${RoleIdentifier.PLAYER}`, 'tournamentRoles.tournamentId||$eq||' + this.tournamentId];
    const res = await this.personService.get({ filter, sort: ['lastname,ASC', 'firstname,ASC'] }).toPromise();
    this.players = res;
    this.filterPlayers();
    this.filterDisciplines();
  }

  filterPlayers(): void {
    // Filter gender
    if (this.discipline?.value in Discipline) {
      const currentDiscipline = this.disciplines.filter(x => x.id === this.discipline.value)[0];
      if (currentDiscipline.gender == null) {
        // when discipline gender undefined -> MIXED -> add all genders
        this.playersFiltered = this.players;
      }
      else if (currentDiscipline.gender in Gender) {
        this.playersFiltered = this.players.filter(player =>
          this.canPlayerBeAssignedToDiscipline(player, currentDiscipline)
        );
      }
    }

    // Filter same players
    this.playersFiltered = this.playersFiltered.filter(player => player.id !== this.player1?.value && player.id !== this.player2?.value);
  }

  filterDisciplines(): void {
    const player1 = this.player1?.value ? this.players.filter(x => x.id === this.player1.value)[0] : null;
    const player2 = this.player2?.value ? this.players.filter(x => x.id === this.player2.value)[0] : null;
    this.disciplines = this.disciplines.map(d => {
      d.disabled = d.gender != null &&
        ((player1 != null && !this.canPlayerBeAssignedToDiscipline(player1, d))
          || (player2 != null && !this.canPlayerBeAssignedToDiscipline(player2, d)));
      return d;
    });
  }

  canPlayerBeAssignedToDiscipline(player: Person, discipline: DisciplineType): boolean {
    return player.gender === discipline.gender
      || player.gender === Gender.OTHER // add a person with gender "other" to all disciplines
  }

  onDisciplineChanged(newDiscipline): void {
    if (newDiscipline.teamSize === 1) {
      if (this.player2 != null) {
        this.formGroup.removeControl('player2');
      }
    } else if (newDiscipline.teamSize === 2) {
      if (this.player2 == null) {
        this.formGroup.addControl('player2', new UntypedFormControl('', [Validators.required]));
      }
    }

    this.filterPlayers();
  }

  onPlayerChanged(): void {
    this.filterPlayers();
    this.filterDisciplines();
  }

  onDelete(): void {
    this.teamService.delete(this.teamId).subscribe(
      () => {
        this.location.back();
      }, (error) => {
        this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
      }
    );
  }

  submit(): void {
    const players = [{ id: this.player1.value }];
    if (this.player2 != null) {
      players.push({ id: this.player2.value });
    }

    const team = {
      discipline: this.discipline.value,
      worldRanking: this.worldRanking.value,
      tournamentId: this.tournamentId,
      persons: players
    };

    if (this.teamId) {
      this.teamService.update(team, this.teamId).subscribe(
        () => {
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    } else {
      this.teamService.create(team).subscribe(
        () => {
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    }
  }

}
