<app-container>
  <div class="">
    <div>
      <nav class="hidden sm:flex items-center text-sm leading-5 font-medium">
        <a [routerLink]="['/dashboard']" class="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Home</a>
        <svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
        </svg>
        <a href="" class="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Hotels</a>
        <svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
        </svg>
        <a href="" class="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">Conflicts</a>
      </nav>
    </div>

    <div class="mt-4 md:flex md:items-center md:justify-between">
      <div class="flex-1 min-w-0">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
          Hotel Conflicts
        </h2>
      </div>
    </div>
  </div><br/>

  <div class="flex flex-col">
    <h2 class="mb-4 mt-6 text-gray-500 text-xs font-medium uppercase tracking-wide">Amount Conflicts</h2>

    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Hotel
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Booking Amount
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Assigned Reservations
                </th>
                <th class="px-6 py-3 bg-gray-50"></th>
              </tr>
            </thead>

            <tbody class="bg-white divide-y divide-gray-200" x-max="1">
              <tr *ngIf="this.amountConflicts().length === 0" >
                <td class="px-6 py-4 whitespace-nowrap" colspan="6">
                  No amount conflicts
                </td>
              </tr>

              <tr *ngFor="let conflict of this.amountConflicts()" >
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm leading-5 font-medium text-gray-900">
                        <span class="badge badge-danger">Amount conflict</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm leading-5 text-gray-900">{{ conflict.booking.hotel.name }}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm leading-5 text-gray-900">{{ conflict.booking.startDate | date }} - {{ conflict.booking.endDate | date }}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm leading-5 text-gray-900">{{ conflict.booking.amount }}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm leading-5 text-gray-900">{{ conflict.assignments.length }}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium">
                  <a href="#" class="text-indigo-600 hover:text-indigo-900">Details</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col mt-10">
    <h2 class="mb-4 mt-6 text-gray-500 text-xs font-medium uppercase tracking-wide">Date Conflicts</h2>

    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Hotel
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Message
                </th>
                <th class="px-6 py-3 bg-gray-50"></th>
              </tr>
            </thead>

            <tbody class="bg-white divide-y divide-gray-200" x-max="1">

              <tr *ngIf="this.dateConflicts().length === 0" >
                <td class="px-6 py-4 whitespace-nowrap" colspan="5">
                  No date conflicts
                </td>
              </tr>

              <tr *ngFor="let conflict of this.dateConflicts()" >
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm leading-5 font-medium text-gray-900">
                        <span class="badge badge-danger">Date conflict</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm leading-5 text-gray-900">{{ conflict.booking.hotel.name }}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm leading-5 text-gray-900">{{ conflict.booking.startDate | date }} - {{ conflict.booking.endDate | date }}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm leading-5 text-gray-900">{{ conflict.message }}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium">
                  <a href="#" class="text-indigo-600 hover:text-indigo-900">Details</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</app-container>
