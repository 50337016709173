import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { FilterService, GridModule, GroupService, PageService, SearchService, SortService, ToolbarService, ColumnChooserService, ResizeService  } from '@syncfusion/ej2-angular-grids';

import { SharedModule } from '@shared/shared.module';
import { NavigationModule } from '@navigation/navigation.module';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginLayoutComponent } from './components/login-layout/login-layout.component';
import { DefaultLayoutComponent } from './components/default-layout/default-layout.component';
import { NotificationContainerComponent } from './components/notification-container/notification-container.component';
import { NotificationComponent } from './components/notification/notification.component';
import { SideNavLayoutComponent } from './components/side-nav-layout/side-nav-layout.component';
import { APIRequestErrorInterceptor } from './interceptors/api-request-error.interceptor';
import { DashboardService } from './services/dashboard.service';
import { DashboardHeaderCardComponent } from './components/dashboard-header-card/dashboard-header-card.component';

@NgModule({
  declarations: [
    DashboardComponent,
    NotFoundComponent,
    LoginLayoutComponent,
    DefaultLayoutComponent,
    NotificationContainerComponent,
    NotificationComponent,
    SideNavLayoutComponent,
    DashboardHeaderCardComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule,
    RouterModule,
    NavigationModule,
    GridModule,
  ],
  exports: [
    NotificationComponent,
    NotificationContainerComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: APIRequestErrorInterceptor, multi: true },
      PageService,
      SortService,
      FilterService,
      GroupService,
      SearchService,
      ToolbarService,
      ColumnChooserService,
      ResizeService,
      DashboardService
  ]
})
export class CoreModule { }
