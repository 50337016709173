import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';

@Component({
  selector: 'app-two-factor-verification',
  templateUrl: './two-factor-verification.component.html',
  styleUrls: ['./two-factor-verification.component.scss']
})
export class TwoFactorVerificationComponent implements OnInit {

  verificationForm = this.fb.group({
    token: ['', [Validators.required, Validators.pattern(new RegExp('^[0-9]{6}$'))]],
  });

  error: string;

  get token(): AbstractControl {
    return this.verificationForm.get('token');
  }

  constructor(private fb: UntypedFormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/']);
    } else if (!this.authService.twoFactorNonce) {
      this.router.navigate(['/login']);
    }
  }

  verify(): void {
    this.authService.verify(this.token.value).subscribe((response) => {
      this.router.navigate(['/']);
    }, (error) => {
      if (Array.isArray(error?.error?.message)) {
        this.error = error?.error?.message?.join(', ');
      } else {
        this.error = error?.error?.message;
      }
    });
  }

}
