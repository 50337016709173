<app-container [hasFixedWidth]="false">
  <div class="mt-2 md:flex md:items-center md:justify-between">
    <div class="flex-1 min-w-0">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
        Organizations
      </h2>
    </div>
    <div class="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
      <span class="ml-3 rounded-md">
        <a class="btn btn-primary" [routerLink]="['create']">Create new organization</a>
      </span>
    </div>
  </div>
  <br />

  <ng-container *ngIf="this.data">
   <ejs-grid defaultGridSettings [dataSource]='data' (rowSelected)="onRowSelected($event)">
      <e-columns>
        <e-column field='name' headerText='Name'  type="string"></e-column>
        <e-column field='type' headerText='Organization type' type="string"></e-column>
        <e-column field='street' headerText='Street' type="string"></e-column>
        <e-column field='zipAndCity' headerText='Zip, City' type="string"></e-column>
        <e-column field='contactPerson.name' headerText='Contact Person' type="string"></e-column>
        <e-column headerText='Delete' [showInColumnChooser]='false' width="120">
          <ng-template #template let-data width=220>
            <app-button-delete (confirm)="this.onRowDeleted(data)"></app-button-delete>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </ng-container>
  <app-container>
