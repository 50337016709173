<div *ngFor="let tournament of this.tournaments">
    <div class="grid grid-cols-2 gap-4 mb-3">
        <div>
            <h2 class="text-xl font-semibold text-gray-900 mb-2">{{tournament.name}}</h2>
        </div>
        <div *ngIf="tournament === this.tournaments[0]">
            <a class="btn btn-primary float-right"
                routerLink="/tournaments/{{tournament.id}}/hotel_room_bookings"
                [queryParams]="{ personId: this.personId }">Add room Reservation</a>
        </div>
    </div>
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul>
            <li *ngFor="let roomAssignment of this.getBookingsByTournament(tournament.id)">
                <a routerLink="/tournaments/{{tournament.id}}/hotel_room_bookings/edit/{{roomAssignment?.hotelRoomBooking.id}}"
                    class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                    <div class="px-4 py-4 sm:px-6">
                        <div class="flex items-center justify-between">
                            <div class="text-sm leading-5 font-medium text-indigo-600 truncate">
                                {{roomAssignment?.hotelRoomBooking.hotel.name}}
                                ({{getNights(roomAssignment)}} Nights)
                            </div>
                        </div>
                        <div class="mt-2 sm:flex sm:justify-between">
                            <div class="sm:flex">
                                <div class="mr-6 flex items-center text-sm leading-5 text-gray-500">
                                    <i class="fas fa-calendar mr-2"></i>
                                    Booked from {{roomAssignment.startDate | date}} to {{roomAssignment.endDate |
                                    date}}
                                </div>
                            </div>
                            <div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">

                            </div>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
    <p *ngIf="this.getBookingsByTournament(tournament.id).length == 0">
        No hotel reservations found for this person.
    </p>
</div>