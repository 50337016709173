import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExcelExportProperties, GridComponent, PdfExportProperties } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { BadgeService } from '@badge/services/badge.service';
import { NotificationService } from '@shared/services/notification.service';
import { RoleService } from '@tournament/services/role.service';
import { RoleIdentifier } from '@person/enums/role.identifier.enum';

@Component({
  selector: 'app-badge-list',
  templateUrl: './badge-list.component.html',
  styleUrls: ['./badge-list.component.scss']
})
export class BadgeListComponent implements OnInit, AfterViewInit {

  @ViewChild("grid") grid: GridComponent;

  data = [];
  badgesToExport: Array<string> = [];
  tournamentId: string = null;
  formatSettings = { type: 'date', format: 'dd.MM.yyyy HH:mm' };

  constructor(
    private badgeService: BadgeService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private roleService: RoleService) { }

  async ngOnInit(): Promise<void> {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
    const roles = await this.roleService.get().toPromise();

    this.badgeService.get(
      {
        join: ['person', 'person.organization', 'person.tournamentRoles', 'accessTypes'],
        filter: [`tournamentId||$eq||${this.tournamentId}`],
        sort: 'person.lastname,ASC'
      })
      .subscribe((res) => {
        this.data = res.map(badge => {
         badge.person['isUnregistered'] = badge.person.tournamentRoles.some(role => role.roleId === RoleIdentifier.UNREGISTERED);
          badge.person.roles = badge.person?.tournamentRoles
            ?.filter(tournamentRole => tournamentRole.tournamentId === this.tournamentId)
            ?.map(tournamentRole => roles.filter(r => r.id === tournamentRole.roleId)[0]?.name)
            ?.join(', ');
          badge.accessTypeNames = badge.accessTypes
            ?.map(accessType => accessType.name)
            ?.join(', ');

          badge.organizationWithType = badge.person?.organization?.type 
            ? `${badge.person?.organization?.type} - ${badge.person?.organization?.name}` : badge.person?.organization?.name;
          badge.createdAt = new Date(badge.createdAt);
          badge.signature = '';
          badge.generatedAt = badge.generatedAt ? new Date(badge.generatedAt) : null;
          return badge;
        });
      });
  }

  ngAfterViewInit(): void {
    this.grid.toolbar.unshift(
      {
        id: 'exportSelected',
        text: 'Print selected badges to .pdf',
        tooltipText: 'Exports all selected badges to a PDF file',
        prefixIcon: 'e-save'
      },
      {
        id: 'exportVisible',
        text: 'Print visible badges to .pdf',
        tooltipText: 'Exports all visible badges to a PDF file',
        prefixIcon: 'e-save'
      });
  }

  addToExportList(e, badgeId: string): void {
    e.stopPropagation();

    if (e.shiftKey && this.badgesToExport.length > 0) {
      const latestBadgeId = this.badgesToExport[this.badgesToExport.length - 1];
      const data: any[] = this.grid.currentViewData;
      const indexOfLatestBadge = data.findIndex(x => x.id === latestBadgeId);
      const indexOfSelectedBadge = data.findIndex(x => x.id === badgeId);

      // Top down selection
      if (indexOfSelectedBadge > indexOfLatestBadge) {
        for (let i = indexOfLatestBadge + 1; i < indexOfSelectedBadge; i++) {
          const badgeBetweenLatestAndSelected = data[i];
          this.toggleBadgeToExport(badgeBetweenLatestAndSelected.id);
        }
      } else
        // Bottom up selection
        for (let i = indexOfSelectedBadge + 1; i < indexOfLatestBadge; i++) {
          const badgeBetweenSelectedAndLatest = data[i];
          this.toggleBadgeToExport(badgeBetweenSelectedAndLatest.id);
        }
    }

    this.toggleBadgeToExport(badgeId);
  }

  toggleBadgeToExport(badgeId) {
    if (!this.badgesToExport.includes(badgeId)) {
      this.badgesToExport.push(badgeId);
    } else {
      this.badgesToExport.splice(this.badgesToExport.indexOf(badgeId), 1);
    }
  }

  onRowSelected(row): void {
    this.router.navigate(['edit', row.data.id], { relativeTo: this.route });
  }

  onRowDeleted(data: any): void {
    this.badgeService.delete(data.id)
      .subscribe((res) => {
        this.data = this.data.filter(x => x.id !== data.id);
      });
  }

  async onGridToolbarClick(args: ClickEventArgs): Promise<void> {
    if (args.item.id === 'exportVisible') {
      this.badgesToExport = this.grid.currentViewData.map((listRow: any) => listRow.id);
    }

    if (args.item.id === 'exportSelected' || args.item.id === 'exportVisible') {
      if (this.badgesToExport.length > 0) {
        this.grid.showSpinner();
        await this.downloadPDF();
        this.grid.hideSpinner();
      } else {
        this.notificationService.warning('You need to select at least one badge to print.');
      }
    }

    if (args.item.id === 'badgeGrid_pdfexport') {
      const currentDate = new Date();
      const gridCols: any[] = this.grid.columns;
      const columnHeadersToExport = ['ID', 'Lastname', 'Firstname', 'Organization', 'Roles', 'Signature'];
      const columnsToExport = gridCols.filter(x => columnHeadersToExport.includes(x.headerText));
      const pdfExportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        header: {
          fromTop: 0,
          height: 50,
          contents: [
            {
              type: 'Text',
              value: `${currentDate.toLocaleDateString('ch')} ${currentDate.toLocaleTimeString('ch')}`,
              position: { x: 0, y: 0 },
              style: { textBrushColor: '#000000', fontSize: 11 }
            },
          ]
        },
        footer: {
          fromBottom: 0,
          height: 50,
          contents: [
            {
              type: 'PageNumber',
              format: 'Page {$current} of {$total}',
              position: { x: 0, y: 0 },
              style: { textBrushColor: '#000000', fontSize: 11, hAlign: 'Center' }
            }
          ]
        },
        columns: columnsToExport,
        includeHiddenColumn: true,
        allowHorizontalOverflow: false,
        theme: {
          header: {
            fontName: 'Calibri', fontSize: 14,
          },
          record: {
            fontName: 'Calibri', fontSize: 14,
          },
          caption: {
            fontName: 'Calibri', fontSize: 14,
          }
        }
      };
      this.grid.pdfExport(pdfExportProperties);
    }

    if (args.item.id === 'badgeGrid_excelexport') {
      const gridCols: any[] = this.grid.columns;
      const columnHeadersToExport = ['ID', 'Lastname', 'Firstname', 'Organization', 'Roles', 'Signature'];
      const columnsToExport = gridCols.filter(x => columnHeadersToExport.includes(x.headerText));
      const excelExcportProperties: ExcelExportProperties = {
        columns: columnsToExport,
        includeHiddenColumn: true,
      };
      this.grid.excelExport(excelExcportProperties);
    }

    if (args.item.id === 'badgeGrid_csvexport') {
      this.grid.csvExport();
    }
  }

  onPdfQueryCellInfo(args: any): void {
    args.cell.cellHeight = 40;
  }

  async downloadPDF(): Promise<void> {
    const res = await this.badgeService.downloadMultiplePDFs(this.badgesToExport).toPromise();
    const blob = new Blob([res], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

}
