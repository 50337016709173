import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-date-slider',
  templateUrl: './date-slider.component.html',
  styleUrls: ['./date-slider.component.scss']
})
export class DateSliderComponent implements OnInit {

  @Input() amountOfDays = 7;
  @Input() stepSize = 1;
  @Input() disabled = false;
  @Input() set selectedDate(value: Date) {
    if (value < this._startDate || value > this._endDate) {
      this._selectedDate = this._startDate;
    }
    else {
      this._selectedDate = value;
      this._selectedDate.setHours(0, 0, 0, 0);
    }

    if (value < this._days[0] || value > this._days[this._days.length - 1]) {
      this.generateDays();
    }
  }
  @Input() set startDate(value: Date) {
    this._startDate = new Date(value);
    this._startDate.setHours(0, 0, 0, 0);
    this.generateDays();
  }
  @Input() set endDate(value: Date) {
    this._endDate = new Date(value);
    this._endDate.setHours(0, 0, 0, 0);
    this.generateDays();
  }

  @Output() change = new EventEmitter<Date>();

  private _days: Date[] = [];
  get days(): Date[] {
    return this._days;
  }
  private _selectedDate: Date = new Date();
  private _startDate: Date = new Date(0);
  private _endDate: Date = new Date(2999977200000);
  _isBackNavigationEnabled = false;
  _isForwardNavigationEnabled = false;

  constructor() {
    this._selectedDate.setHours(0, 0, 0, 0);
    this.generateDays();
  }

  ngOnInit(): void { }

  generateDays(): void {
    this._days = [];
    for (let i = 0; i < this.amountOfDays; i++) {
      const nextDate: Date = new Date(this._selectedDate);
      nextDate.setDate(nextDate.getDate() + i);
      if (nextDate > this._endDate) {
        break;
      }
      this._days.push(nextDate);
      this._selectedDate = new Date(this._days[0]);
    }

    this.updateNavigationButtonStatus();
  }

  onNavigateBack(): void {
    const selectedDate = this._days.filter(x => x.getTime() === this._selectedDate.getTime())[0];
    const selectedDateIndex = this._days.indexOf(selectedDate);
    const days = this._days;

    for (let i = 0; i < this.stepSize; i++) {
      if (this._startDate >= days[0]) {
        break;
      }

      for (let j = 0; j < days.length; j++) {
        const newDate = new Date(days[j]);
        newDate.setDate(newDate.getDate() - 1);
        days[j] = newDate;
      }
    }

    this._days = days;
    this._selectedDate = days[selectedDateIndex];
    this.updateNavigationButtonStatus();
    this.change.emit(this._selectedDate);
  }

  onNavigateForward(): void {
    const selectedDate = this._days.filter(x => x.getTime() === this._selectedDate.getTime())[0];
    const selectedDateIndex = this._days.indexOf(selectedDate);
    const days = this._days;

    for (let i = 0; i < this.stepSize; i++) {
      if (this._endDate <= days[this._days.length - 1]) {
        break;
      }

      for (let j = 0; j < days.length; j++) {
        const newDate = new Date(days[j]);
        newDate.setDate(newDate.getDate() + 1);
        days[j] = newDate;
      }
    }

    this._days = days;
    this._selectedDate = this._days[selectedDateIndex];
    this.updateNavigationButtonStatus();
    this.change.emit(this._selectedDate);
  }

  onSelectedDateSwitched(item: Date): void {
    this.selectedDate = item;
    this.change.emit(item);
  }

  updateNavigationButtonStatus(): void {
    const firstDate = new Date(this._days[0]);
    const lastDate = new Date(this._days[this._days.length - 1]);
    this._isBackNavigationEnabled = this._startDate.getTime() < firstDate.getTime();
    this._isForwardNavigationEnabled = this._endDate.getTime() > lastDate.getTime();
  }
}
