import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnlineRegistrationService } from 'app/online-registration/services/online-registration.service';

@Component({
  selector: 'app-online-registration-list',
  templateUrl: './online-registration-list.component.html',
  styleUrls: ['./online-registration-list.component.scss']
})
export class OnlineRegistrationListComponent implements OnInit {

  tournamentId = null;
  data = [];
  formatSettings = { type: 'date', format: 'dd.MM.yyyy HH:mm' };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private registrationService: OnlineRegistrationService
  ) { }

  async ngOnInit(): Promise<void> {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
    this.data = await this.registrationService.get(this.tournamentId).toPromise();
  }


  onRowSelected(row): void {
    this.router.navigate([row.data.id], { relativeTo: this.route });
  }

  onRowDataBound(args): void {
    if (args.data.approvedAt) {
      args.row.style.opacity = '0.5';
    }
  }

}
