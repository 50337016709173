import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { HotelRoomAssignmentService } from '@hotel/services/hotel-room-assignment.service';
import { HotelRoomBookingService } from '@hotel/services/hotel-room-booking.service';
import { PersonService } from '@person/services/person.service';
import { ErrorObjectHelper } from '@shared/helpers/error-object.helper';

@Component({
  selector: 'app-hotel-room-assignment-edit',
  templateUrl: './hotel-room-assignment-edit.component.html',
  styleUrls: ['./hotel-room-assignment-edit.component.scss']
})
export class HotelRoomAssignmentEditComponent implements OnInit {

  error = null;
  hotelRoomAssignmentId = null;
  formGroup = this.fb.group({
    startDate: [null, [Validators.required]],
    endDate: [null, [Validators.required]],
    booking: [null, [Validators.required]],
    person: [null, [Validators.required]],
  });
  persons = [];
  bookings = [];
  tournamentId: string;
  startDateMin: Date = null;
  startDateMax: Date = null;
  endDateMin: Date = null;
  endDateMax: Date = null;

  get endDate(): AbstractControl {
    return this.formGroup.get('endDate');
  }

  get startDate(): AbstractControl {
    return this.formGroup.get('startDate');
  }

  get booking(): AbstractControl {
    return this.formGroup.get('booking');
  }

  get person(): AbstractControl {
    return this.formGroup.get('person');
  }

  get bookingId(): AbstractControl {
    return this.formGroup.get('bookingId');
  }

  get personId(): AbstractControl {
    return this.formGroup.get('personId');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private hotelRoomAssignmentService: HotelRoomAssignmentService,
    private hotelRoomBookingService: HotelRoomBookingService,
    private personService: PersonService) {
      this.personService.get().subscribe((res) => {
        this.persons = res;
      });

      this.hotelRoomBookingService.get({ join: ['hotel']}).subscribe((res) => {
        this.bookings = res;
      });

      this.hotelRoomAssignmentId = this.route.snapshot.params.assignmentId;
      if (this.hotelRoomAssignmentId) {
        this.hotelRoomAssignmentService.getOne(this.hotelRoomAssignmentId)
          .subscribe((res) => {
            this.startDate.setValue(res.startDate);
            this.endDate.setValue(res.endDate);
            this.booking.setValue(res.hotelRoomBookingId);
            this.person.setValue(res.personId);
          });
    }

      const personId = this.route.snapshot.queryParams.personId;
      if (personId) {
      this.person.setValue(personId);
    }
  }

  ngOnInit(): void {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
  }

  onDelete(): void {
    this.hotelRoomAssignmentService.delete(this.hotelRoomAssignmentId).subscribe(
      () => {
        this.location.back();
      }, (error) => {
        this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
      }
    );
  }

  onBookingChange(booking): void {
    this.startDateMin = new Date(booking.startDate);
    this.startDateMax = new Date(booking.endDate);
    this.endDateMin = new Date(booking.startDate);
    this.endDateMax = new Date(booking.endDate);
  }

  submit(): void {
    if (this.startDate.value > this.startDateMax || this.startDate.value < this.startDateMin) {
      this.error = 'Check in date must be between start and end date of the room booking';
      return;
    }

    if (this.endDate.value > this.endDateMax || this.endDate.value < this.endDateMin) {
      this.error = 'Check out date must be between start and end date of the room booking';
      return;
    }

    if (this.startDate.value >= this.endDate.value) {
      this.error = 'Check out date must be after Check in date';
      return;
    }

    const hotelRoomAssignment = {
      startDate: this.startDate.value,
      endDate: this.endDate.value,
      hotelRoomBookingId: this.booking.value,
      personId: this.person.value,
      tournamentId: this.tournamentId
    };

    if (this.hotelRoomAssignmentId) {
      this.hotelRoomAssignmentService.update(hotelRoomAssignment, this.hotelRoomAssignmentId).subscribe(
        () => {
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    } else {
      this.hotelRoomAssignmentService.create(hotelRoomAssignment).subscribe(
        () => {
          this.location.back();
        }, (error) => {
          this.error = ErrorObjectHelper.getMessageFromResponseObject(error);
        }
      );
    }
  }

}
