import { Country } from '@shared/interfaces/country.interface';
import { I18nCountry } from '@shared/interfaces/i18nCountry.interface';
import { I18nLanguage } from '@shared/interfaces/i18nLanguage.interface';
import * as countriesList from 'countries-list';
import * as countryListJson from './country-list.json';

export class I18nHelper {

    private static countries: Country[] =
        I18nHelper.convertCountryListJSONToArray(countryListJson);

    private static languages: I18nLanguage[] =
        I18nHelper.convertCountryListObjectToArray(countriesList.languages).sort(I18nHelper.sortByName);

    public static getAllCountries(): Country[] {
        return this.countries;
    }

    public static getAllLanguages(): I18nLanguage[] {
        return this.languages;
    }

    public static getCountryCodeForCountryName(countryName: string): string {
        const country = this.countries.filter(x => x.country === countryName)[0];
        if (country != null) {
            return country.code;
        }
    }

    private static convertCountryListObjectToArray(obj): Array<I18nCountry | I18nLanguage> {
        const arr = [];
        Object.keys(obj).map((key) => {
            obj[key].alpha2 = key; arr.push(obj[key]);
        });
        return arr;
    }

    private static convertCountryListJSONToArray(countryList): Country[] {
        return <Country[]> countryList.default;
    }

    private static sortByName(a, b): number {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
    }

}
