import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from '@auth/components/login/login.component';
import { TwoFactorVerificationComponent } from '@auth/components/two-factor-verification/two-factor-verification.component';
import { AuthGuard } from '@auth/services/auth.guard';
import { DashboardComponent } from '@core/components/dashboard/dashboard.component';
import { DefaultLayoutComponent } from '@core/components/default-layout/default-layout.component';
import { LoginLayoutComponent } from '@core/components/login-layout/login-layout.component';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';
import { UserSettingsComponent } from '@user/components/user-settings/user-settings.component';
import { PersonListComponent } from '@person/components/person-list/person-list.component';
import { PersonEditComponent } from '@person/components/person-edit/person-edit.component';
import { TournamentListComponent } from '@tournament/components/tournament-list/tournament-list.component';
import { TournamentEditComponent } from '@tournament/components/tournament-edit/tournament-edit.component';
import { HotelListComponent } from '@hotel/components/hotel-list/hotel-list.component';
import { HotelEditComponent } from '@hotel/components/hotel-edit/hotel-edit.component';
import { OrganizationListComponent } from '@organization/components/organization-list/organization-list.component';
import { OrganizationEditComponent } from '@organization/components/organization-edit/organization-edit.component';
import { VisaListComponent } from '@visa/components/visa-list/visa-list.component';
import { VisaEditComponent } from '@visa/components/visa-edit/visa-edit.component';
import { BadgeListComponent } from '@badge/components/badge-list/badge-list.component';
import { BadgeEditComponent } from '@badge/components/badge-edit/badge-edit.component';
import { AccessTypeListComponent } from '@badge/components/accessType-list/accessType-list.component';
import { AccessTypeEditComponent } from '@badge/components/accessType-edit/accessType-edit.component';
import { ScannerComponent } from '@badge/components/scanner/scanner.component';
import { HotelRoomAssignmentListComponent } from '@hotel/components/hotel-room-assignment-list/hotel-room-assignment-list.component';
import { HotelRoomAssignmentEditComponent } from '@hotel/components/hotel-room-assignment-edit/hotel-room-assignment-edit.component';
import { HotelRoomBookingListComponent } from '@hotel/components/hotel-room-booking-list/hotel-room-booking-list.component';
import { HotelRoomBookingEditComponent } from '@hotel/components/hotel-room-booking-edit/hotel-room-booking-edit.component';
import { HotelConflictsComponent } from '@hotel/components/hotel-conflicts/hotel-conflicts.component';
import { TransportAssignmentListComponent } from '@transport/components/transport-assignment-list/transport-assignment-list.component';
import { TransportBookingArrivalListComponent } from '@transport/components/transport-booking-arrival-list/transport-booking-arrival-list.component';
import { TransportBookingEditComponent } from '@transport/components/transport-booking-edit/transport-booking-edit.component';
import { SideNavLayoutComponent } from '@core/components/side-nav-layout/side-nav-layout.component';
import { PersonRoleCreateComponent } from '@person/components/person-role-create/person-role-create.component';
import { PersonComponent } from '@person/components/person/person.component';
import { TeamEditComponent } from '@team/components/team-edit/team-edit.component';
import { TeamListComponent } from '@team/components/team-list/team-list.component';
import { VolunteerTimeComponent } from '@volunteer-time/components/volunteer-time/volunteer-time.component';
import { RoleEditComponent } from '@tournament/components/role-edit/role-edit.component';
import { OnlineRegistrationListComponent } from './online-registration/components/online-registration-list/online-registration-list.component';
import { OnlineRegistrationEditComponent } from './online-registration/components/online-registration-edit/online-registration-edit.component';
import { HistoryListComponent } from '@tournament/components/history-list/history-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/tournaments/latest',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'verify', component: TwoFactorVerificationComponent },
    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'account', component: UserSettingsComponent, pathMatch: 'full' },
      { path: 'persons', component: PersonListComponent, pathMatch: 'full' },
      { path: 'persons/create', component: PersonEditComponent, pathMatch: 'full' },
      { path: 'persons/:personId', component: PersonComponent, pathMatch: 'full' },
      { path: 'persons/edit/:personId', component: PersonEditComponent, pathMatch: 'full' },
      { path: 'tournaments', component: TournamentListComponent, pathMatch: 'full' },
      { path: 'tournaments/latest', component: TournamentListComponent, pathMatch: 'full' },
      { path: 'tournaments/create', component: TournamentEditComponent, pathMatch: 'full' },
      { path: 'hotels', component: HotelListComponent, pathMatch: 'full' },
      { path: 'hotels/create', component: HotelEditComponent, pathMatch: 'full' },
      { path: 'hotels/edit/:hotelId', component: HotelEditComponent, pathMatch: 'full' },
      { path: 'organizations', component: OrganizationListComponent, pathMatch: 'full' },
      { path: 'organizations/create', component: OrganizationEditComponent, pathMatch: 'full' },
      { path: 'organizations/edit/:organizationId', component: OrganizationEditComponent, pathMatch: 'full' },
      { path: 'visa', component: VisaListComponent },
      { path: 'visa/create', component: VisaEditComponent },
      { path: 'visa/edit/:visaId', component: VisaEditComponent },
      { path: 'access_types', component: AccessTypeListComponent },
      { path: 'access_types/create', component: AccessTypeEditComponent },
      { path: 'access_types/edit/:accessTypeId', component: AccessTypeEditComponent },
      { path: 'scanner', component: ScannerComponent },
    ]
  },
  {
    path: 'tournaments/:tournamentId',
    component: SideNavLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: TournamentEditComponent, pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'registrations', component: OnlineRegistrationListComponent },
      { path: 'registrations/:registrationId', component: OnlineRegistrationEditComponent },
      { path: 'participants', component: PersonListComponent },
      { path: 'participants/create', component: PersonEditComponent },
      { path: 'participants/:personId', component: PersonComponent },
      { path: 'players', component: PersonListComponent },
      { path: 'players/add', component: PersonRoleCreateComponent },
      { path: 'players/:personId', component: PersonComponent },
      { path: 'players/edit/:personId', component: PersonEditComponent },
      { path: 'coaches', component: PersonListComponent },
      { path: 'coaches/add', component: PersonRoleCreateComponent },
      { path: 'coaches/:personId', component: PersonComponent },
      { path: 'coaches/edit/:personId', component: PersonEditComponent },
      { path: 'volunteers', component: PersonListComponent },
      { path: 'volunteers/add', component: PersonRoleCreateComponent },
      { path: 'volunteers/:personId', component: PersonComponent },
      { path: 'volunteers/edit/:personId', component: PersonEditComponent },
      { path: 'teams', component: TeamListComponent },
      { path: 'teams/create', component: TeamEditComponent },
      { path: 'teams/edit/:teamId', component: TeamEditComponent },
      { path: 'badges', component: BadgeListComponent },
      { path: 'badges/edit/:badgeId', component: BadgeEditComponent },
      { path: 'transport_bookings', component: TransportAssignmentListComponent },
      { path: 'arrival_list', component: TransportBookingArrivalListComponent },
      { path: 'transport_bookings/create', component: TransportBookingEditComponent },
      { path: 'transport_bookings/edit/:bookingId', component: TransportBookingEditComponent },
      { path: 'hotel_room_bookings', component: HotelRoomBookingListComponent },
      { path: 'hotel_room_bookings/create', component: HotelRoomBookingEditComponent },
      { path: 'hotel_room_bookings/edit/:bookingId', component: HotelRoomBookingEditComponent },
      { path: 'hotel_room_assignments', component: HotelRoomAssignmentListComponent },
      { path: 'hotel_room_assignments/create', component: HotelRoomAssignmentEditComponent },
      { path: 'hotel_room_assignments/edit/:assignmentId', component: HotelRoomAssignmentEditComponent },
      { path: 'hotels/conflicts', component: HotelConflictsComponent },
      { path: 'volunteer_time_planning', component: VolunteerTimeComponent },
      { path: 'roles', component: RoleEditComponent },
      { path: 'history', component: HistoryListComponent },
    ]
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
