import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FilterService, GridModule, GroupService, PageService, SearchService, SortService, ToolbarService, ColumnChooserService, ResizeService } from '@syncfusion/ej2-angular-grids';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

import { SharedModule } from '@shared/shared.module';

import { OrganizationListComponent } from './components/organization-list/organization-list.component';
import { OrganizationEditComponent } from './components/organization-edit/organization-edit.component';

@NgModule({
  declarations: [
    OrganizationListComponent,
    OrganizationEditComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    GridModule,
    DatePickerModule,
  ],
  providers: [
    PageService,
    SortService,
    FilterService,
    GroupService,
    SearchService,
    ToolbarService,
    ColumnChooserService,
    ResizeService
  ],
})
export class OrganizationModule { }
