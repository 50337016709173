import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudServiceOptions } from '../interfaces/crud-service-options.interface';
import { CrudService } from '../interfaces/crud.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RestCrudService<T> implements CrudService<T> {

  private _baseUrl = '';
  private _resourceName = '';

  get baseUrl(): string {
    return this._baseUrl;
  }

  constructor(private options: CrudServiceOptions, private httpClient: HttpClient) {
    this._baseUrl = options.baseUrl;
    this._resourceName = options.resourceName;
  }

  get(params?: object): Observable<T[]> {
    const pass = ret => ret;
    const pipeObservable = this.options.getPipe || this.options.globalPipe || pass;

    let query = '';

    if (params) {
      query += this.getParams(params);
    }

    return pipeObservable(this.httpClient.get<T[]>(this._baseUrl + '/' + this._resourceName + query));
  }

  getOne(resourceId: string, params?: object): Observable<T> {
    const pass = ret => ret;

    let query = '';

    if (params) {
      query += this.getParams(params);
    }

    const pipeObservable = this.options.getOnePipe || this.options.globalPipe || pass;
    return pipeObservable(this.httpClient.get<T>(this._baseUrl + '/' + this._resourceName + '/' + resourceId + query));
  }

  create(resource: T): Observable<T> {
    const pass = ret => ret;
    const pipeObservable = this.options.createPipe || this.options.globalPipe || pass;
    return pipeObservable(this.httpClient.post<T>(this._baseUrl + '/' + this._resourceName, resource));
  }

  update(resource: T, resourceId: string): Observable<T> {
    const pass = ret => ret;
    const pipeObservable = this.options.updatePipe || this.options.globalPipe || pass;
    return pipeObservable(this.httpClient.put<T>(this._baseUrl + '/' + this._resourceName + '/' + resourceId, resource));
  }

  delete(resourceId: string): Observable<T> {
    const pass = ret => ret;
    const pipeObservable = this.options.deletePipe || this.options.globalPipe || pass;
    return pipeObservable(this.httpClient.delete<T>(this._baseUrl + '/' + this._resourceName + '/' + resourceId));
  }

  private getParams(params?: object): string {
    for (const param in params) {
      if (params[param] == null) {
        delete params[param];
      }
    }
    return '?' + Object.keys(params).map((key) => {
      const value = params[key];
      if (value instanceof Array) {
        const str = [];

        value.forEach((el) => {
          str.push(`${key}=${el}`);
        });

        return str.join('&');
      } else {
        return `${key}=${value}`;
      }
    }).join('&');
  }

}
