import { HttpErrorResponse } from '@angular/common/http';

export class ErrorObjectHelper {

    public static getMessageFromResponseObject(error: HttpErrorResponse): string {
        if (Array.isArray(error?.error?.message)) {
            return error?.error?.message?.join(', ');
        } else {
            return error?.error?.message;
        }
    }

}
