import { TransportType } from "@transport/enums/transport-type.enum";

export class TransportTypeHelper {
    public static getTypes() {
        return [
            {
              id: TransportType.BUS,
              name: 'Bus',
            },
            {
              id: TransportType.TRAIN,
              name: 'Train',
            },
            {
              id: TransportType.PLANE,
              name: 'Airplane',
            },
            {
              id: TransportType.OFFICIAL_CAR,
              name: 'Official Car',
            },
          ];
    }
}