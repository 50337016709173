import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';

export const AuthGuard: CanActivateFn = (route, state) =>  {
    if (!inject(AuthService).isLoggedIn) {
      inject(Router).navigate(['/login']);
      return false;
    } else {
      return true;
    }
};
