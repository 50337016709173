import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';

import { jwtDecode } from 'jwt-decode';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  get accessToken(): string {
    return localStorage.getItem(environment.localStorageKeys.accessToken);
  }

  get refreshToken(): string {
    return localStorage.getItem(environment.localStorageKeys.refreshToken);
  }

  get accessTokenExpiresAt(): number {
    return Number(localStorage.getItem(environment.localStorageKeys.accessTokenExpiresAt));
  }

  get refreshTokenExpiresAt(): number {
    return Number(localStorage.getItem(environment.localStorageKeys.refreshTokenExpiresAt));
  }

  get twoFactorNonce(): string {
    return localStorage.getItem(environment.localStorageKeys.twoFactorNonce);
  }

  get isLoggedIn(): boolean {
    const now = (new Date()).getTime();
    return this.accessToken && this.refreshToken && (this.refreshTokenExpiresAt > now);
  }

  constructor(private http: HttpClient, private router: Router) { }

  login(email: string, password: string): Observable<any> {
    return this.http.post(`${environment.api}/login`, { email, password }).pipe(
      tap((response) => {
        this.setSession(response);
      }, (error) => {
        if (error.error?.twoFactor) {
          localStorage.setItem(environment.localStorageKeys.twoFactorNonce, error.error.nonce.token);
        } else {
          this.clearSession();
        }
      })
    );
  }

  verify(token: number): Observable<any> {
    return this.http.post(`${environment.api}/login/verify`, { nonce: this.twoFactorNonce, twoFactorToken: token }).pipe(
      tap((response) => {
        this.setSession(response);
      }, (error) => {
          console.log(error);
      })
    );
  }

  logout(): void {
    this.clearSession();
    this.router.navigate(['/login']);
  }

  executeTokenRefresh(): Observable<any> {
    return this.http.post(`${environment.authApi}/refresh`, { token: this.refreshToken })
      .pipe(
        share(), // <========== YOU HAVE TO SHARE THIS OBSERVABLE TO AVOID MULTIPLE REQUEST BEING SENT SIMULTANEOUSLY
        tap((response) => {
          this.setSession(response);
        }, (error) => {
          this.logout();
          console.log(error);
        })
      );
  }

  setSession(data: any): void {
    localStorage.setItem(environment.localStorageKeys.accessToken, data.accessToken ? data.accessToken.token : '');
    localStorage.setItem(environment.localStorageKeys.refreshToken, data.refreshToken ? data.refreshToken.token : '');

    const accessTokenExpiration = Number(this.decodeToken(this.accessToken).exp) * 1000;
    const refreshTokenExpiration = Number(this.decodeToken(this.refreshToken).exp) * 1000;

    localStorage.setItem(environment.localStorageKeys.accessTokenExpiresAt, accessTokenExpiration.toString());
    localStorage.setItem(environment.localStorageKeys.refreshTokenExpiresAt, refreshTokenExpiration.toString());
  }

  clearSession(): void {
    localStorage.removeItem(environment.localStorageKeys.accessToken);
    localStorage.removeItem(environment.localStorageKeys.refreshToken);
    localStorage.removeItem(environment.localStorageKeys.accessTokenExpiresAt);
    localStorage.removeItem(environment.localStorageKeys.refreshTokenExpiresAt);
  }

  decodeToken(token: any): any {
    if (token) {
      return jwtDecode(token);
    } else {
      return {};
    }
  }
}
