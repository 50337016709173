<div class="date-slider">
    <div class="date-slider-navigation">
        <button type="button" class="navigation navigation-item" (click)="onNavigateBack()"
            [disabled]="!_isBackNavigationEnabled">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            </svg>
        </button>

        <button type="button" *ngFor="let d of this.days;" class="navigation-item"
            [class.selected]="d.getTime() === this._selectedDate.getTime()" (click)="onSelectedDateSwitched(d)">
            {{ d | date:'EEEEEE' }}
            <br>
            {{ d | date:'dd.MM' }}
        </button>

        <button type="button" class="navigation navigation-item" (click)="onNavigateForward()"
            [disabled]="!_isForwardNavigationEnabled">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            </svg>
        </button>

    </div>
</div>