import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudServiceOptions } from '@shared/interfaces/crud-service-options.interface';
import { RestCrudService } from '@shared/services/rest-crud.service';
import { Team } from '@team/models/team.model';
import { environment } from 'environments/environment';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeamService extends RestCrudService<any> {

  constructor(httpClient: HttpClient) {
    const options: CrudServiceOptions = {
      baseUrl: environment.api,
      getPipe: pipe(map((teams: Team[]) => {
        return teams.map((team: Team) => new Team(team));
      })),
      getOnePipe: pipe(map((team: Team) => {
        return new Team(team);
      })),
      resourceName: 'teams'
    };
    super(options, httpClient);
  }
}
