import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BadgeService } from '@badge/services/badge.service';
import { AccessTypeService } from '@badge/services/accessType.service';
import { PersonService } from '@person/services/person.service';
import { NotificationService } from '@shared/services/notification.service';

@Component({
  selector: 'app-badge-edit',
  templateUrl: './badge-edit.component.html',
  styleUrls: ['./badge-edit.component.scss']
})
export class BadgeEditComponent implements OnInit {

  error = null;
  badgeId = null;
  tournamentId: string = null;
  accessTypes = [];
  badge = null;
  person = null;

  constructor(
    private route: ActivatedRoute,
    private badgeService: BadgeService,
    private personService: PersonService,
    private accessTypeService: AccessTypeService,
    private notificationService: NotificationService) {
    this.tournamentId = this.route.parent.snapshot.params.tournamentId;
    this.badgeId = this.route.snapshot.params.badgeId;

    this.accessTypeService.get().subscribe((res) => {
      this.accessTypes = res;
    });

    if (this.badgeId) {
      this.badgeService.getOne(this.badgeId, { join: ['accessTypes'] }).subscribe((res) => {
        this.badge = res;

        this.personService.getOne(this.badge.personId).subscribe((res) => {
          this.person = res;
        });
      });
    }
  }

  ngOnInit(): void { }

  isActive(accessType): boolean {
    return (this.badge?.accessTypes || []).filter((a) => {
      return a.id === accessType.id;
    }).length > 0;
  }

  assignAccessType(accessType): void {
    this.badgeService.addAccessType(this.badge.id, accessType.id).subscribe(() => {
      this.badge.accessTypes.push(accessType);
      this.notificationService.success('Access Types updated');
    });
  }
  
  removeAccessType(accessType): void {
    this.badgeService.removeAccessType(this.badge.id, accessType.id).subscribe(() => {
      this.badge.accessTypes = this.badge.accessTypes.filter((a) => a.id !== accessType.id);
      this.notificationService.success('Access Types updated');
    });
  }
}
