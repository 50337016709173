<app-container>
    <div class="mt-2 grid grid-cols-2">
        <div class="col-span-1">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
                Edit roles of tournament
            </h2>
        </div>
    </div>
    <br>

    <div *ngFor="let role of roles;let i = index" class="flex flex-center mt-2 items-center">

        <input [(ngModel)]="role.name" [disabled]="this.isAStandardRole(role.id)"
            class="appearance-none inline-block w-96 px-3 py-2 mt-1 pl-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">

        <button type="button" [hidden]="this.isAStandardRole(role.id)" [swal]="this.swalOptions" (confirm)="removeRole(role, i)"
            class="ml-2 mt-1 text-red-500 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
        </button>
    </div>

    <br>

    <button type="button" class="btn btn-secondary" (click)="this.addRole()">Add Role</button>

    <br>
    <br>
    <button type="button" class="btn btn-primary mt-5" (click)="this.saveRoles()">Save</button>
</app-container>