import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@shared/shared.module';
import { PersonModule } from '@person/person.module';

import { VolunteerTimeComponent } from './components/volunteer-time/volunteer-time.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [VolunteerTimeComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    PersonModule,
    NgSelectModule,
  ],
})
export class VolunteerTimeModule { }
